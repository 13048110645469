import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  FormField,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from 'src/common/EventType';
import { LocalStorageKeys, useLocalStorage } from 'src/hooks/useLocalStorage';
import { useUserAlias } from 'src/hooks/useUserAlias';
import 'src/pages/landing-page/landing-page.scss';
import { PathNames } from 'src/routes';
import {
  BusinessFilterType,
  ISelectedGroupFilters,
  useGetBusinessGroupFilters,
  useGetUpdatedSelectedGroupFilters,
  isSelectedFiltersValid,
  useSupportedBusinessGroups,
} from 'src/utils/planning/supportedBusinessGroups';
import { prefetchGetBatchesList } from 'src/api/query/useBatchesList';
import { BatchStatus } from 'src/utils/planning/planetModel';

const emptyFilters: ISelectedGroupFilters = {
  planType: null,
  businessGroup: null,
  subGroup: null,
  region: null,
};

export const LandingPageFilterManager = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { alias } = useUserAlias();

  const { getBusinessGroupFilters } = useGetBusinessGroupFilters();

  const { getUpdatedSelectedGroupFilters } = useGetUpdatedSelectedGroupFilters();

  const { supportedBusinessGroups } = useSupportedBusinessGroups();

  const [planFilters, setPlanFilters] = useLocalStorage<ISelectedGroupFilters>({
    key: [LocalStorageKeys.LIST_PLAN_FILTERS],
    defaultValue: emptyFilters,
  });

  const [selectedFilters, setSelectedFilters] = useState<ISelectedGroupFilters>(planFilters);

  const { allowedPlanTypes, allowedBusinessGroups, allowedSubGroups, allowedRegions } = useMemo(
    () => getBusinessGroupFilters(selectedFilters),
    [getBusinessGroupFilters, selectedFilters],
  );

  const navigateToListPlansPage = useCallback(() => {
    if (isSelectedFiltersValid(selectedFilters)) {
      navigate({
        pathname: PathNames.LIST_PLANS_PAGE,
        search: createSearchParams({
          [BusinessFilterType.PLAN_TYPE]: selectedFilters.planType?.value ?? '',
          [BusinessFilterType.BUSINESS_GROUP]: selectedFilters.businessGroup?.value ?? '',
          [BusinessFilterType.SUB_GROUP]: selectedFilters.subGroup?.value ?? '',
          [BusinessFilterType.REGION]: selectedFilters.region?.value ?? '',
        }).toString(),
      });
    } else {
      navigate({
        pathname: PathNames.LIST_PLANS_PAGE,
        search: createSearchParams({
          [BusinessFilterType.PLAN_TYPE]: [supportedBusinessGroups[0].planType],
          [BusinessFilterType.BUSINESS_GROUP]: [supportedBusinessGroups[0].businessGroup],
          [BusinessFilterType.SUB_GROUP]: [supportedBusinessGroups[0].subGroup],
          [BusinessFilterType.REGION]: [supportedBusinessGroups[0].region],
        }).toString(),
      });
    }
  }, [navigate, selectedFilters, supportedBusinessGroups]);

  useEffect(() => {
    if (
      planFilters.businessGroup &&
      planFilters.planType &&
      planFilters.region &&
      planFilters.subGroup
    ) {
      prefetchGetBatchesList(queryClient, alias, {
        filterList: {
          costType: [planFilters.planType.value],
          businessGroup: [planFilters.businessGroup.value],
          subGroup: [planFilters.subGroup.value],
          region: [planFilters.region.value],
          batchStatus: [BatchStatus.ALL],
        },
      });
    } else {
      prefetchGetBatchesList(queryClient, alias, {
        filterList: {
          costType: [supportedBusinessGroups[0].planType],
          businessGroup: [supportedBusinessGroups[0].businessGroup],
          subGroup: [supportedBusinessGroups[0].subGroup],
          region: [supportedBusinessGroups[0].region],
          batchStatus: [BatchStatus.ALL],
        },
      });
    }
  }, [queryClient, planFilters, alias, supportedBusinessGroups]);

  const createPlanFilterChangeHandler =
    (businessFilterType: BusinessFilterType) => (e: SelectChangeEvent) => {
      const newSelectedFilters = getUpdatedSelectedGroupFilters(
        selectedFilters,
        businessFilterType,
        {
          value: e.detail.selectedOption.value ?? '',
          label: e.detail.selectedOption.label ?? '',
        },
      );

      setSelectedFilters(newSelectedFilters);

      if (isSelectedFiltersValid(newSelectedFilters)) setPlanFilters(newSelectedFilters);
    };

  return (
    <Container>
      <SpaceBetween size="s">
        <Box variant="h2" padding="n">
          {t('plan_manager_header')}
        </Box>
        <FormField stretch={true} label={t('plan_type_label')}>
          <Select
            options={allowedPlanTypes}
            selectedOption={selectedFilters.planType}
            onChange={createPlanFilterChangeHandler(BusinessFilterType.PLAN_TYPE)}
          />
        </FormField>
        <FormField stretch={true} label={t('business_group_label')}>
          <Select
            options={allowedBusinessGroups}
            selectedOption={selectedFilters.businessGroup}
            onChange={createPlanFilterChangeHandler(BusinessFilterType.BUSINESS_GROUP)}
          />
        </FormField>
        <FormField stretch={true} label={t('sub_group_label')}>
          <Select
            options={allowedSubGroups}
            selectedOption={selectedFilters.subGroup}
            onChange={createPlanFilterChangeHandler(BusinessFilterType.SUB_GROUP)}
          />
        </FormField>
        <FormField stretch={true} label={t('region_label')}>
          <Select
            options={allowedRegions}
            selectedOption={selectedFilters.region}
            onChange={createPlanFilterChangeHandler(BusinessFilterType.REGION)}
          />
        </FormField>
        <Button variant="primary" onClick={navigateToListPlansPage}>
          {t('start_planning_button')}
        </Button>
      </SpaceBetween>
    </Container>
  );
};
