import { Spinner } from '@amzn/awsui-components-react/polaris';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import GlobalErrorModal from 'src/common/GlobalErrorModal';
import { NavBar } from 'src/common/NavBar';
import { useUserAlias } from 'src/hooks/useUserAlias';

const AG_GRID_LICENSE =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-072490}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Amazon.com_Services_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{12}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_December_2025}____[v3]_[01]_MTc2Njc5MzYwMDAwMA==29aabd22ff541efd8b04ca7461c9eede';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

/**
 * Make sure the application is loaded after feature flags are fetched
 */
const FeatureFlagsLoader: FC<PropsWithChildren> = ({ children }) => {
  const { isFetched: isFeatureFlagFetchSuccess } = useFeatureFlags();
  return isFeatureFlagFetchSuccess ? children : null;
};

/**
 * React component to authenticate users
 */
const RootPage: FC = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { alias } = useUserAlias();

  // Dispatch user info
  // Ref: https://code.amazon.com/packages/CathodeVersionsJavaScript/trees/mainline
  useEffect(() => {
    if (route !== 'authenticated') {
      return;
    }
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: alias,
      },
    });
    document.dispatchEvent(authenticatedUserEvent);
  }, [route, alias]);

  if (route !== 'authenticated') return <Spinner />;

  return (
    <FeatureFlagsLoader>
      <ScrollRestoration
        getKey={(location, _matches) => {
          const paths = ['/plans'];
          return paths.includes(location.pathname) ? location.pathname : location.key;
        }}
      />
      <NavBar />
      <div className="main-content">
        <Outlet />
      </div>
      <GlobalErrorModal />
    </FeatureFlagsLoader>
  );
};

export default RootPage;
