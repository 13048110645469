class ExecutionOrderCustomTooltip {
  private eGui: HTMLDivElement | undefined;

  init(_: any) {
    const eGui = (this.eGui = document.createElement('div')) as any;

    eGui.classList.add('custom-tooltip');
    eGui.style['background-color'] = 'white';
    eGui.style['width'] = '400px';
    eGui.style['height'] = '4em';
    eGui.innerHTML = `<div>
          Execution order describes priority of the data in each row. 
          Higher number rows will overwrite duplicate values in lower number rows.
          For instance, an execution order of 3 will overwrite duplicates from 1 and 2.
          </div>
      `;
  }

  getGui() {
    return this.eGui;
  }
}

export { ExecutionOrderCustomTooltip };
