import {
  CellValueChangedEvent,
  EditableCallbackParams,
  IRowNode,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { max, get as lodashGet } from 'lodash';
import { RefObject } from 'react';
import {
  ICustomizedAgGridColumnConfig,
  getCellValue,
} from 'src/common/ag-grid/editable-datagrid/AgGridConfigHelper';
import {
  BaselineColumnIds,
  BaselineColumnNames,
} from 'src/pages/plan-manager-page/plan-baseline-tab/BaselineModel';
import { ExecutionOrderCustomTooltip } from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/ExecutionOrderCustomTooltip';
import { currentModifiedAt } from 'src/utils/time';

export const formatter = (_: any, year: any, month: any) => ({
  Name: `${year}-${month}`,
  Id: `${year}-${month}`,
});

export const getNextExecutionOrder = (gridRef: RefObject<AgGridReact>) => {
  const orderList = [] as any;
  gridRef.current?.api.forEachNode((node: any) => {
    orderList.push(getCellValue(node.data!.executingOrder));
  });
  const maxNum = max(orderList) as any;
  if (!maxNum) {
    return 1;
  }
  return parseInt(maxNum) + 1;
};

export const resetRowNodeValues = (
  node: IRowNode,
  stringColumns: BaselineColumnIds[],
  listColumns: BaselineColumnIds[],
) => {
  stringColumns.forEach((columnId) => node.setDataValue(columnId, ''));
  listColumns.forEach((columnId) => node.setDataValue(columnId, []));
};

export const generateColumnConfigBase = ({
  gridRef,
  alias,
}: {
  gridRef: RefObject<AgGridReact>;
  alias: string;
}) => {
  const columnConfigs: Record<string, ICustomizedAgGridColumnConfig> = {
    [BaselineColumnIds.EXECUTING_ORDER]: {
      required: true,
      headerName: BaselineColumnNames.EXECUTING_ORDER,
      sort: 'asc',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      tooltipComponent: ExecutionOrderCustomTooltip,
      tooltipValueGetter: (_: any) => 'Valid',
      onCellValueChanged: (params: any) => {
        const val = getCellValue(params.node.data[params.colDef.field]);
        if (!getCellValue(params.node.data[params.colDef.field])) {
          params.node.setDataValue(
            BaselineColumnIds.EXECUTING_ORDER,
            getNextExecutionOrder(gridRef),
          );
        }

        if (typeof val === 'string') {
          params.node.setDataValue(BaselineColumnIds.EXECUTING_ORDER, parseInt(val));
          return;
        }

        gridRef.current?.api.forEachNode((node: any) => {
          if (
            parseInt(getCellValue(node.data.executingOrder)) === val &&
            node.data.id !== params.node.data.id
          ) {
            node.setDataValue(
              BaselineColumnIds.EXECUTING_ORDER,
              parseInt(getCellValue(node.data.executingOrder)) + 1,
            );
          }
        });

        params.node.setDataValue(BaselineColumnIds.MODIFIED_BY, alias);
        params.node.setDataValue(BaselineColumnIds.MODIFIED_AT, currentModifiedAt());
      },
      editor: 'AgNumberEditor',
      valueGetter: (params: any) => parseInt(getCellValue(params.node.data[params.colDef.field])),
    },
    [BaselineColumnIds.BATCH_ID]: {
      required: false,
      editable: false,
      hide: true,
    },
    [BaselineColumnIds.MODIFIED_BY]: {
      headerName: BaselineColumnNames.MODIFIED_BY,
      editable: () => false,
    },
    [BaselineColumnIds.MODIFIED_AT]: {
      headerName: BaselineColumnNames.MODIFIED_AT,
      editable: () => false,
    },
  };

  return columnConfigs;
};

export const getCellValueForCol = (
  params: ValueFormatterParams | CellValueChangedEvent | EditableCallbackParams | ValueSetterParams,
  column: BaselineColumnIds = lodashGet(params, 'colDef.field') as BaselineColumnIds,
) => getCellValue(lodashGet(params, ['node', 'data', column]));
