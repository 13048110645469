/**
 * Props for RequiredLabel components
 */
interface RequiredLabelProps {
  text: string;
}

/**
 * This component will return text with required label
 */
export const RequiredLabel = ({ text }: RequiredLabelProps) => (
  <span>
    {text}
    <span style={{ color: '#df3312', padding: '3px' }}>*</span>
  </span>
);
