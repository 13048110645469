import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

/** update this list after adding new feature flags in evidently */
type FeatureFlag =
  | 'feShowStage'
  | 'fePlanJanitorial'
  | 'fePlanOtrFreightCapitalization'
  | 'fePlanOtrSupplyType'
  | 'fePlanTopsDownForecast'
  | 'fePlanFixedCostConsolidation'
  | 'fePlanCfConsolidation'
  /**
   * TODO: when removing feLoadDatasetByAsyncUploadAditsApi flag, also remove below TriggerType since they are no longer used:
   * CAPEX_LOAD_DATASETS, DEPRECIATION_LOAD_DATASETS, REAL_ESTATE_LOAD_DATASETS, JANITORIAL_LOAD_DATASETS, SECURITY_LOAD_DATASETS, OTR_WHT_LOAD_DATASETS, OTR_DIB_LOAD_DATASETS, OTR_FC_LOAD_DATASETS, THREE_P_TRANSPORTATION_LOAD_DATASETS, UTR_PRODUCTIVITY_LOAD_DATASETS, UTR_COST_LOAD_DATASETS, UTR_LARGE_DATA_UPDATE_AND_LOAD
   */
  | 'feLoadDatasetByAsyncUploadAditsApi'
  | 'feProcessFlowTab'
  | 'feUseColumnMappingSchemaConfig';

export const useFeatureFlags = () => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  return useQueryWithNotifications({
    queryKey: [QUERY_KEYS.FEATURE_FLAGS, alias],
    queryFn: async () => {
      const { featureFlagsResult } = await FoxPlanningApi.getFeatureFlags({
        requesterIdentity: alias,
      });

      return featureFlagsResult as Record<FeatureFlag, boolean | undefined> | null | undefined;
    },
    meta: { errorNotificationText: t('api_error_fetch_feature_flags') },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
