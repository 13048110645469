import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

const AgNumberEditorFunc = (props: any, ref: any) => {
  const { node, column } = props;
  const [value, setValue] = useState(props.value ? props.value : null);
  const refInput = useRef(null as any);

  useEffect(() => {
    refInput.current.focus();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value ? String(value) : null;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    isCancelAfterEnd() {
      return false;
    },
  }));

  return (
    <div style={{ height: node.rowHeight + 'px', width: column.actualWidth + 'px' }}>
      <input
        type="number"
        ref={refInput}
        value={value}
        onChange={(event) => setValue(event.target.value as any)}
        style={{ width: '100%', height: '100%' }}
        className="ag-input"
      />
    </div>
  );
};

const AgNumberEditor = forwardRef(AgNumberEditorFunc);

export default AgNumberEditor;
