import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Button,
  FormField,
  Header,
  Input,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  Link,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react/polaris';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { BatchDetails } from '@amzn/fox-den-cost-planning-lambda';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBatchesList } from 'src/api/query/useBatchesList';
import { GlobalNotifications } from 'src/common/GlobalNotifications';
import { Breadcrumbs } from 'src/common/Breadcrumbs';
import {
  TableEmptyState,
  TableNoMatchState,
} from 'src/pages/list-plans-page/components/listPlanErrorTables';
import usePlanFiltersWithQueryParams from 'src/pages/list-plans-page/hooks/usePlanFiltersWithQueryParams';
import 'src/pages/list-plans-page/list-plans-page.scss';
import { PathNames, getPlanDetailsRoute } from 'src/routes';
import { BatchStatus, PLAN_TYPE_LABEL_MAP, PlanTypeId } from 'src/utils/planning/planetModel';
import {
  BusinessFilterType,
  ISelectedGroupFilters,
  isSelectedFiltersValid,
  useGetBusinessGroupFilters,
  useGetUpdatedSelectedGroupFilters,
} from 'src/utils/planning/supportedBusinessGroups';
import PlanActionsButtonDropdown from 'src/pages/commons/plan-actions/PlanActionsButtonDropdown';
import { appLayoutHeaderSelector } from 'src/common/NavBar';
import { dateTimeFromIsoToLocal } from 'src/utils/time';

type ColumnDefinitionWithId = TableProps.ColumnDefinition<BatchDetails> & { id: string };

const ListPlansBody = (): JSX.Element => {
  const [planListData, setPlanListData] = useState([] as any);

  const [selectedPlans, setSelectedPlans] = useState([] as any);

  const navigate = useNavigate();

  const { t } = useTranslation();

  // Last valid combination of planType, businessGroup, subGroup, and region
  const { planFilters, updatePlanFilters } = usePlanFiltersWithQueryParams();

  // Currently selected combination of planType, businessGroup, subGroup, and region
  const [selectedFilters, setSelectedFilters] = useState<ISelectedGroupFilters>(planFilters);

  const { getBusinessGroupFilters } = useGetBusinessGroupFilters();

  const { getUpdatedSelectedGroupFilters } = useGetUpdatedSelectedGroupFilters();

  const { allowedPlanTypes, allowedBusinessGroups, allowedSubGroups, allowedRegions } =
    getBusinessGroupFilters(selectedFilters);

  const { data: batchesListData } = useBatchesList({
    filterList: {
      costType: [planFilters.planType.value],
      businessGroup: [planFilters.businessGroup.value],
      subGroup: [planFilters.subGroup.value],
      region: [planFilters.region.value],
      batchStatus: [BatchStatus.ALL],
    },
  });

  useEffect(() => {
    if (batchesListData) {
      setPlanListData(batchesListData.batches);
      setSelectedPlans([]);
    }
  }, [batchesListData]);

  const columnDefinitions = useMemo<ColumnDefinitionWithId[]>(() => {
    const config: Omit<ColumnDefinitionWithId, 'sortingField'>[] = [
      {
        id: 'batchName',
        header: t('plan_name_header'),
        cell: (item) => (
          <Link
            onFollow={(event) => {
              event.preventDefault();
              navigate(getPlanDetailsRoute(item.batchId!));
            }}
            href={getPlanDetailsRoute(item.batchId!)}
            variant="secondary"
          >
            <b>{item.batchName}</b>
          </Link>
        ),
        width: 450,
      },
      {
        id: 'costType',
        header: t('plan_type_header'),
        cell: (item) => PLAN_TYPE_LABEL_MAP[item.costType as PlanTypeId],
        minWidth: 80,
        width: 125,
      },
      {
        id: 'startYear',
        header: t('start_year_header'),
        cell: (item) => item.startYear,
        minWidth: 80,
        width: 130,
      },
      {
        id: 'planCycle',
        header: t('plan_cycle_header'),
        cell: (item) => item.planCycle,
        minWidth: 80,
        width: 95,
      },
      {
        id: 'tags',
        header: t('tags_label'),
        cell: (item) => item.tags,
        minWidth: 80,
        width: 125,
      },
      {
        id: 'duration',
        header: t('plan_duration_header'),
        cell: (item) => item.duration,
        minWidth: 50,
        width: 120,
      },
      {
        id: 'status',
        header: t('dataset_status_header'),
        cell: (item) => item.status,
        minWidth: 80,
        width: 170,
      },
      {
        id: 'computeStatus',
        header: t('compute_status_header'),
        cell: (item) => item.computeStatus,
        minWidth: 80,
        width: 170,
      },
      {
        id: 'visible',
        header: t('plan_visibility_header'),
        cell: (item) => item.visible,
      },
      {
        id: 'createdBy',
        header: t('created_by_header'),
        cell: (item) => item.createdBy,
        minWidth: 80,
        width: 130,
      },
      {
        id: 'createdOn',
        header: t('created_on_header'),
        cell: (item) => dateTimeFromIsoToLocal(item.createdOn!),
        minWidth: 100,
        width: 215,
      },
      {
        id: 'lastUpdatedBy',
        header: t('last_updated_by_header'),
        cell: (item) => item.lastUpdatedBy,
        minWidth: 80,
        width: 170,
      },
      {
        id: 'lastUpdatedOn',
        header: t('last_updated_on_header'),
        cell: (item) => dateTimeFromIsoToLocal(item.lastUpdatedOn!),
        minWidth: 100,
        width: 215,
      },
    ];

    return config.map((o) => ({ ...o, sortingField: o.id }));
  }, [navigate, t]);

  const navigateToCreatePlan = useCallback(() => {
    navigate(
      { pathname: PathNames.CREATE_PLAN_PAGE },
      {
        state: {
          [BusinessFilterType.PLAN_TYPE]: planFilters.planType.value,
          [BusinessFilterType.BUSINESS_GROUP]: planFilters.businessGroup.value,
          [BusinessFilterType.SUB_GROUP]: planFilters.subGroup.value,
          [BusinessFilterType.REGION]: planFilters.region.value,
        },
      },
    );
  }, [
    navigate,
    planFilters.businessGroup.value,
    planFilters.planType.value,
    planFilters.region.value,
    planFilters.subGroup.value,
  ]);

  const { items, actions, collectionProps, filterProps, paginationProps } = useCollection(
    planListData,
    {
      filtering: {
        empty: <TableEmptyState onClickCreatePlan={navigateToCreatePlan} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
        filteringFunction: (item: any, filteringText) => {
          const filteringTextLowerCase = filteringText.toLowerCase();

          return columnDefinitions
            .map((o) => item[o.id])
            .some(
              (value) =>
                typeof value === 'string' &&
                value.toLowerCase().indexOf(filteringTextLowerCase) > -1,
            );
        },
      },
      pagination: { pageSize: 30 },
      sorting: {
        defaultState: {
          sortingColumn: columnDefinitions.at(-1)!,
          isDescending: true,
        },
      },
      selection: {},
    },
  );

  const selectedPlan = selectedPlans.length !== 0 ? selectedPlans[0] : null;

  const updateSelectedFilters = (
    e: NonCancelableCustomEvent<SelectProps.ChangeDetail>,
    businessFilterType: BusinessFilterType,
  ) => {
    const newSelectedFilters = getUpdatedSelectedGroupFilters(selectedFilters, businessFilterType, {
      value: e.detail.selectedOption.value ?? '',
      label: e.detail.selectedOption.label ?? '',
    });

    setSelectedFilters(newSelectedFilters);

    if (isSelectedFiltersValid(newSelectedFilters)) {
      updatePlanFilters({
        planType: newSelectedFilters.planType?.value ?? '',
        businessGroup: newSelectedFilters.businessGroup?.value ?? '',
        subGroup: newSelectedFilters.subGroup?.value ?? '',
        region: newSelectedFilters.region?.value ?? '',
      });
    }
  };

  const navigateToSelectedPlan = () => {
    navigate(getPlanDetailsRoute(selectedPlan?.batchId));
  };

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      items={items}
      variant="full-page"
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={false}
      stripedRows={true}
      stickyColumns={{ first: 1 }}
      selectedItems={selectedPlans}
      onSelectionChange={(newSelection) => {
        setSelectedPlans(newSelection.detail.selectedItems);
      }}
      selectionType="single"
      header={
        <Header
          variant="awsui-h1-sticky"
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <PlanActionsButtonDropdown batchId={selectedPlan?.batchId ?? undefined} />
              <Button disabled={!selectedPlan} onClick={navigateToSelectedPlan}>
                {t('edit')}
              </Button>
              <Button onClick={navigateToCreatePlan} variant="primary">
                {t('create_plan_button')}
              </Button>
            </SpaceBetween>
          }
        >
          {t('plans')}
        </Header>
      }
      filter={
        <div className="input-container">
          <div className="input-filter">
            <Input
              data-testid="input-filter"
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              placeholder={t('search_plans')}
            />
          </div>
          <div className="select-filter" style={{ maxWidth: 140 }}>
            <FormField label={t('plan_type_label')}>
              <Select
                data-testid="engine-filter"
                options={allowedPlanTypes}
                selectedOption={selectedFilters.planType}
                onChange={(e) => {
                  updateSelectedFilters(e, BusinessFilterType.PLAN_TYPE);
                }}
                expandToViewport={true}
              />
            </FormField>
          </div>
          <div className="select-filter">
            <FormField label={t('business_group_label')}>
              <Select
                data-testid="class-filter"
                options={allowedBusinessGroups}
                selectedOption={selectedFilters.businessGroup}
                onChange={(e) => {
                  updateSelectedFilters(e, BusinessFilterType.BUSINESS_GROUP);
                }}
                expandToViewport={true}
              />
            </FormField>
          </div>
          <div className="select-filter">
            <FormField label={t('sub_group_label')}>
              <Select
                data-testid="engine-filter"
                options={allowedSubGroups}
                selectedOption={selectedFilters.subGroup}
                onChange={(e) => {
                  updateSelectedFilters(e, BusinessFilterType.SUB_GROUP);
                }}
                expandToViewport={true}
              />
            </FormField>
          </div>
          <div className="select-filter" style={{ maxWidth: 100 }}>
            <FormField label={t('region_label')}>
              <Select
                data-testid="class-filter"
                options={allowedRegions}
                selectedOption={selectedFilters.region}
                onChange={(e) => {
                  updateSelectedFilters(e, BusinessFilterType.REGION);
                }}
                expandToViewport={true}
              />
            </FormField>
          </div>
        </div>
      }
      pagination={<Pagination {...paginationProps} />}
    />
  );
};

const ListPlansPage = (): JSX.Element => (
  <AppLayout
    content={<ListPlansBody />}
    toolsHide={true}
    navigationHide={true}
    breadcrumbs={<Breadcrumbs />}
    notifications={<GlobalNotifications />}
    stickyNotifications={true}
    contentType="table"
    tools={<div />}
    disableContentPaddings={false}
    headerSelector={appLayoutHeaderSelector}
  />
);

export default ListPlansPage;
