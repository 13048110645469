import { CreateBatchRequest, CreateBatchResponse } from '@amzn/fox-den-cost-planning-lambda';
import { useQueryClient } from '@tanstack/react-query';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import {
  MutationHookOptions,
  MutationNotificationsMeta,
  QUERY_KEYS,
  useMutationWithNotifications,
} from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

export const useCreatePlanMutation = (
  options: MutationHookOptions<CreateBatchRequest, CreateBatchResponse> & MutationNotificationsMeta,
) => {
  const queryClient = useQueryClient();
  const { alias } = useUserAlias();

  return useMutationWithNotifications({
    ...options,
    mutationFn: (input) =>
      FoxPlanningApi.createBatch({
        ...input,
        requesterIdentity: alias,
      }),
    onSettled(data, error, variables, context) {
      if (!options.onSettled) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BATCH_LIST] });
      }
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
