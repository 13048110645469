import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useUpdateBatchMetadataWithConfirm } from 'src/pages/commons/plan-actions/useUpdateBatchMetadataWithConfirm';
import { BatchStatus } from 'src/utils/planning/planetModel';

interface IDeleteModalParams {
  batchId: string | undefined;
  visible: boolean;
  setShowModal: (value: SetStateAction<boolean>) => void;
}

const DELETE_CONSENT_TEXT = 'confirm';

const DeletePlanModal = ({ batchId, visible, setShowModal }: IDeleteModalParams) => {
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { t } = useTranslation();

  const [deleteInputText, setDeleteInputText] = useState('');

  useEffect(() => {
    setDeleteInputText('');
  }, [visible]);

  const inputMatchesConsentText = deleteInputText.toLowerCase() === DELETE_CONSENT_TEXT;

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const { ConfirmationModal, handleUpdate } = useUpdateBatchMetadataWithConfirm({
    batchId,
    inProgressNotificationText: t('api_in_progress_delete_plan'),
    errorNotificationText: t('api_error_delete_plan'),
    successNotificationText: t('api_success_delete_plan'),
    onFinish: closeModal,
  });

  const handleDelete = () => {
    handleUpdate({ status: BatchStatus.DELETED });
  };

  const handleDeleteSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (inputMatchesConsentText) handleDelete();
  };

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={closeModal}
        header={t('delete_plan_header')}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                {t('cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={handleDeleteSubmit}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                {t('delete_button')}
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size="m">
          <Trans
            t={t}
            i18nKey="delete_plan_message"
            values={{ planName: batchMetadata?.batchName }}
          />

          <Alert type="warning" statusIconAriaLabel="Warning">
            {t('delete_plan_warning')}
          </Alert>

          <Box>{t('delete_consent_description')}</Box>

          <form onSubmit={handleDeleteSubmit}>
            <FormField
              label={t('delete_consent_instructions', { deleteConsentText: DELETE_CONSENT_TEXT })}
            >
              <ColumnLayout columns={2}>
                <Input
                  placeholder={DELETE_CONSENT_TEXT}
                  onChange={(event) => setDeleteInputText(event.detail.value)}
                  value={deleteInputText}
                  ariaRequired={true}
                />
              </ColumnLayout>
            </FormField>
          </form>
        </SpaceBetween>
      </Modal>
      {ConfirmationModal}
    </>
  );
};

export default DeletePlanModal;
