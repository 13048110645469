import {
  AppLayout,
  Box,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { useTranslation } from 'react-i18next';
import { ExternalLinkGroup } from 'src/common/ExternalLinkGroup';
import { GlobalNotifications } from 'src/common/GlobalNotifications';
import { LandingPageFilterManager } from 'src/pages/landing-page/LandingPageFilterManager';
import 'src/pages/landing-page/landing-page.scss';
import { PLANET_URL } from 'src/common/URL';
import { appLayoutHeaderSelector } from 'src/common/NavBar';

// Todo: Simplify and modernize code for readability. This is a placeholder as a replacement
// homepage for the PLANet migration and is subject to change
const LandingPageBody = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box margin={{ bottom: 'l' }} data-testid="landing-page">
      <div className="custom-home__header" color="red">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              {
                colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                offset: { l: 2, xxs: 1 },
              },
            ]}
          >
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category">
                {t('ww_ops_standardization_and_automation')}
              </span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                {t('amazon_planet_header')}
              </Box>
              <Box
                fontWeight="light"
                padding={{ bottom: 's' }}
                fontSize="display-l"
                color="inherit"
              >
                {t('planet_landing_page_tagline')}
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                  {t('planet_landing_page_description')}
                </span>
              </Box>
            </div>
          </Grid>
        </Box>
      </div>

      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            {
              colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
              offset: { s: 0, xxs: 1 },
              push: { xl: 8, l: 7, s: 7, xxs: 0 },
            },
            {
              colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
              offset: { l: 2, xxs: 1 },
              pull: { xl: 2, l: 3, s: 4, xxs: 0 },
            },
          ]}
        >
          <div className="custom-home__sidebar">
            <SpaceBetween size="xxl">
              <LandingPageFilterManager />
              <ExternalLinkGroup
                header={t('getting_started')}
                variant="container"
                items={[
                  {
                    href: PLANET_URL.WIKI_URL,
                    text: t('landing_page_what_is_planet'),
                  },
                  {
                    href: PLANET_URL.PRODUCT_OVERVIEW,
                    text: t('landing_page_feature_overview'),
                  },
                  {
                    href: PLANET_URL.USER_GUIDE,
                    text: t('landing_page_user_guide'),
                  },
                ]}
              />

              <ExternalLinkGroup
                header={t('more_resources')}
                variant="container"
                items={[
                  {
                    href: PLANET_URL.ROADMAP,
                    text: t('planet_roadmap'),
                  },
                  {
                    href: PLANET_URL.SUCCESS_STORIES,
                    text: t('planet_success_stories'),
                  },
                  {
                    href: PLANET_URL.STANDARDIZATION_AND_AUTOMATION_PRODUCTS,
                    text: t('standardization_and_automation_products'),
                  },
                  {
                    href: PLANET_URL.CORP_FP_AND_A_CALENDAR,
                    text: t('corp_fp_and_a_calendar_label'),
                  },
                  {
                    href: PLANET_URL.REFINE_EXCEL_TRANSFORMATION_TOOL,
                    text: t('refine_excel_transformation_tool_label'),
                  },
                ]}
              />
            </SpaceBetween>
          </div>
          <SpaceBetween size="xxl">
            <div>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                {t('benefits_and_features_header')}
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      {t('simple_scenario_planning_header')}
                    </Box>
                    <Box variant="p">{t('simple_scenario_planning_description')}</Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      {t('quicksight_integration_header')}
                    </Box>
                    <Box variant="p">{t('quicksight_integration_description')}</Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      {t('plan_management_header')}
                    </Box>
                    <Box variant="p">{t('plan_management_description')}</Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      {t('inline_editing_header')}
                    </Box>
                    <Box variant="p">{t('inline_editing_description')}</Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>
            <Container header={<Header variant="h2">{t('use_cases')}</Header>}>
              <SpaceBetween size="s">
                <div>
                  <Link external={true} variant="primary" href={PLANET_URL.CAPEX_OVERVIEW}>
                    {t('capex_label')}
                  </Link>
                </div>
                <div>
                  <Link external={true} variant="primary" href={PLANET_URL.REAL_ESTATE_OVERVIEW}>
                    {t('real_estate_label')}
                  </Link>
                </div>
                <div>
                  <Link
                    external={true}
                    variant="primary"
                    href={PLANET_URL.FIXED_DEPRECIATION_OVERVIEW}
                  >
                    {t('fixed_depreciation_label')}
                  </Link>
                </div>
                <div>
                  <Link external={true} variant="primary" href={PLANET_URL.UNDER_THE_ROOF_OVERVIEW}>
                    {t('under_the_roof_label')}
                  </Link>
                </div>
                <div>
                  <Link
                    external={true}
                    variant="primary"
                    href={PLANET_URL.HC_AND_RELATED_OPEX_OVERVIEW}
                  >
                    {t('hc_and_related_opex')}
                  </Link>
                </div>
                <div>
                  <Link external={true} variant="primary" href={PLANET_URL.ON_THE_ROAD_OVERVIEW}>
                    {t('on_the_road')}
                  </Link>
                </div>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </Grid>
      </Box>
    </Box>
  );
};

const LandingPage = () => (
  <AppLayout
    content={<LandingPageBody />}
    toolsHide={true}
    navigationHide={true}
    notifications={<GlobalNotifications />}
    maxContentWidth={Number.MAX_VALUE}
    stickyNotifications={true}
    disableContentPaddings={true}
    headerSelector={appLayoutHeaderSelector}
  />
);
export default LandingPage;
