import {
  Flashbar,
  ProgressBar,
  Box,
  Button,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { SourceLocator } from '@amzn/fox-den-cost-planning-lambda';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { S3_COST_PLANNING_BUCKET_WRITE_ONLY, configStorage, putInS3 } from 'src/utils/s3';
import { FileInput } from 'src/common/FileInput';
import { useNotificationContext } from 'src/hooks/useNotificationContext';

/**
 * Props for UploadFileModal components
 */
interface UploadFileModalProps {
  constraint: string;
  title: string;
  visible: boolean;
  loading: boolean;
  onClose: (flag: boolean) => void;
  submitText: string;
  onSubmit: (sourceLocator: SourceLocator) => void;
  datasetName: string;
  additionalModalContent?: ReactNode;
}

/**
 * Common component for handling file upload
 */
export const UploadFileModal = ({
  constraint,
  title,
  loading,
  visible,
  onClose,
  submitText,
  onSubmit,
  datasetName,
  additionalModalContent,
}: UploadFileModalProps) => {
  // States
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const { addNotificationForApiError } = useNotificationContext();

  /**
   * This function will close the modal
   */
  const handleClose = () => {
    setProgressPercent(0);
    onClose(false);
  };

  /**
   * This function will submit the selected file
   */
  const onClickUpload = async () => {
    if (!selectedFile) {
      return;
    }
    try {
      setIsUploading(true);
      const bucket = S3_COST_PLANNING_BUCKET_WRITE_ONLY;

      const fileParts = selectedFile.name.split('.');
      const fileType = fileParts[fileParts.length - 1];

      const s3Path = '';
      configStorage(s3Path, bucket);

      const fileName = `${datasetName}/${new Date().getTime()}.${fileType}`;

      await putInS3(fileName, selectedFile, selectedFile.type, (progress: number) => {
        setProgressPercent(progress);
      });

      const fileLocator: SourceLocator = {
        bucket,
        key: s3Path + fileName,
      };

      handleClose();
      await onSubmit(fileLocator);
    } catch (error: any) {
      addNotificationForApiError({ content: t('api_error_upload_file_s3'), error });
    } finally {
      setIsUploading(false);
    }
  };

  /**
   * This function will update selected file
   */
  const handleFileChange = (file: File | null) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      size="large"
      header={title}
      onDismiss={handleClose}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleClose} variant="link">
              {t('cancel')}
            </Button>
            <Button
              onClick={onClickUpload}
              disabled={!selectedFile}
              loading={loading || isUploading}
              loadingText={t('api_in_updation')}
              variant="primary"
            >
              {submitText}
            </Button>
          </SpaceBetween>
        </Box>
      }
      visible={visible}
    >
      <SpaceBetween size="xl">
        {Boolean(progressPercent) && (
          <Flashbar
            items={[
              {
                type: 'info',
                content: (
                  <ProgressBar
                    label={t('uploading_file_label')}
                    value={progressPercent}
                    variant="flash"
                  />
                ),
              },
            ]}
          />
        )}
        <FileInput
          form={{
            accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            description: t('choose_csv_xlsx_to_upload'),
            label: t('select_file_label'),
            constraint,
          }}
          file={selectedFile}
          onChange={handleFileChange}
        />
        {additionalModalContent}
      </SpaceBetween>
    </Modal>
  );
};
