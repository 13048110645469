import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { isEmpty } from 'lodash';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useUpdateBatchMetadataWithConfirm } from 'src/pages/commons/plan-actions/useUpdateBatchMetadataWithConfirm';

interface IUpdatePlanDescriptionModalParams {
  visible: boolean;
  setShowModal: (value: SetStateAction<boolean>) => void;
}

const UpdatePlanDescriptionModal = ({
  visible,
  setShowModal,
}: IUpdatePlanDescriptionModalParams) => {
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const [isDataPrefilled, setIsDataPrefilled] = useState(false);
  const [planDescription, setPlanDescription] = useState('');

  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    setShowModal(false);
    setIsDataPrefilled(false);
  }, [setShowModal]);

  const { ConfirmationModal, handleUpdate } = useUpdateBatchMetadataWithConfirm({
    batchId,
    inProgressNotificationText: t('api_in_progress_update_plan_description'),
    errorNotificationText: t('api_error_update_plan_description'),
    successNotificationText: t('api_success_update_plan_description'),
    onFinish: closeModal,
  });

  const handleUpdatePlanDescription = () => handleUpdate({ slotDescription: planDescription });

  const inputValid = !isEmpty(planDescription);

  const handleUpdatePlanDescriptionSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (inputValid) handleUpdatePlanDescription();
  };

  useEffect(() => {
    if (visible && batchMetadata && !isDataPrefilled) {
      setPlanDescription(batchMetadata?.slotDescription ?? '');
      setIsDataPrefilled(true);
    }
  }, [batchMetadata, isDataPrefilled, visible]);

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={closeModal}
        header={t('update_plan_description_title')}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                {t('cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={handleUpdatePlanDescriptionSubmit}
                disabled={!inputValid}
                data-testid="submit"
              >
                {t('update_plan_description_action')}
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size="m">
          <Trans
            t={t}
            i18nKey="edit_plan_description_message"
            values={{ planName: batchMetadata?.batchName }}
          />

          <form onSubmit={handleUpdatePlanDescriptionSubmit}>
            <FormField
              label={t('plan_description_label')}
              description={t('plan_description_description')}
              constraintText={t('plan_description_constraint_text')}
            >
              <ColumnLayout columns={2}>
                <Input
                  onChange={(event) => setPlanDescription(event.detail.value)}
                  value={planDescription}
                  ariaRequired={true}
                />
              </ColumnLayout>
            </FormField>
          </form>
        </SpaceBetween>
      </Modal>
      {ConfirmationModal}
    </>
  );
};

const EditPlanDescriptionButton = () => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Box margin={{ left: 'xs' }} display="inline-block">
        <Button onClick={() => setShowModal(true)} href="#" variant="inline-link">
          {t('edit')}
        </Button>
      </Box>
      <UpdatePlanDescriptionModal visible={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default EditPlanDescriptionButton;
