import { ValueSetterParams } from 'ag-grid-community';
import { isArray, uniq } from 'lodash';
import {
  IAgDuplicateCheckColumnConfig,
  cellValueFormatter,
  getCellValue,
  isCellValueModified,
} from 'src/common/ag-grid/editable-datagrid/AgGridConfigHelper';
import { PlanTypeId } from 'src/utils/planning/planetModel';

export interface ICellData {
  value: any;
  original: any;
  lastValidation: ILastFailedValidation | boolean;
}

export const isICellData = (data: any): data is ICellData =>
  !!data &&
  (data as ICellData).value !== undefined &&
  (data as ICellData).original !== undefined &&
  (data as ICellData).lastValidation !== undefined;

export interface IValidationResult {
  result: boolean;
  message?: string;
}

export interface ILastFailedValidation {
  validationFailedValue: any;
  validationFailedMsg: string;
}

export const requiredValidationFn =
  (_: ValueSetterParams) =>
  (newValue: any): IValidationResult => {
    const result = isArray(newValue) ? newValue.length > 0 : !!newValue;
    return {
      result,
      message: result ? 'Validation Passed' : 'This field cannot be empty',
    };
  };

export const multipleDropdownValidationFn =
  (params: ValueSetterParams) =>
  (newValue: any): IValidationResult => {
    const validateValues =
      typeof newValue === 'string' ? newValue.split(',').map((str) => str.trim()) : newValue;
    params.newValue = validateValues;
    const cellEditorParams =
      typeof params.colDef.cellEditorParams === 'function'
        ? params.colDef.cellEditorParams(params)
        : params.colDef.cellEditorParams;
    const optionList: any[] = cellEditorParams?.treeDataSource;
    const allowedList: any[] = optionList.map((option) => option.id);

    let error = 0;
    validateValues.forEach((value: any) => {
      if (!allowedList.includes(value)) {
        error += 1;
      }
    });
    return {
      result: error === 0,
      message: error === 0 ? 'Validation Passed' : 'This value is not included in dropdown options',
    };
  };

export const dropdownValidationFn =
  (params: ValueSetterParams) =>
  (newValue: any): IValidationResult => {
    const cellEditorParams =
      typeof params.colDef.cellEditorParams === 'function'
        ? params.colDef.cellEditorParams(params)
        : params.colDef.cellEditorParams;
    const optionList: any[] = cellEditorParams?.treeDataSource;
    const allowedList: any[] = optionList.map((option) => option.id);
    const result = newValue === null || newValue === '' || allowedList.includes(newValue);

    // Only used when result is false
    const errorMsg = newValue
      ? 'This value is not included in dropdown options'
      : 'This field cannot be empty';
    return {
      result,
      message: result ? 'Validation Passed' : errorMsg,
    };
  };

export const regexpValidationFnHelper =
  (regExpString: string | RegExp, errorMessage: string) =>
  (_: ValueSetterParams) =>
  (newValue: string): IValidationResult => {
    if (!newValue) {
      return { result: true, message: 'Validation Passed' };
    }

    const regExp = typeof regExpString === 'string' ? new RegExp(regExpString) : regExpString;
    const result = regExp.test(newValue);

    return {
      result,
      message: result ? 'Validation Passed' : errorMessage,
    };
  };

export const agDuplicateCheck = (
  data: any,
  duplicateCheckConfig: any,
  columnConfigs: any,
  gridApi: any,
) => {
  // This will return duplicate value(s) in case two are equal
  const isEqual = (a: any, b: any, parent = false) => {
    const duplicateValue = a;
    if (a && isArray(a) && b && isArray(b)) {
      if (parent) {
        return a.every((val, index) => val === b[index]) ? duplicateValue : false;
      }
      const elements = [] as any;
      a.forEach((element) => {
        if (b.includes(element)) {
          elements.push(element);
        }
      });
      return elements.length > 0 ? elements : false;
    }
    return String(a).toUpperCase() === String(b).toUpperCase() ? String(a).toUpperCase() : false;
  };

  const failCellValue = (cellValue: any, lastValidation: any) => {
    const originalValue = isCellValueModified(cellValue)
      ? (cellValue as ICellData).original
      : cellValue;
    return cellValueFormatter(getCellValue(cellValue), originalValue, lastValidation);
  };

  const passCellValue = (key: any, rowData: any) => {
    const cellValue = rowData[key];
    if (
      isCellValueModified(cellValue) &&
      cellValue.lastValidation !== true &&
      cellValue.lastValidation.validationFailedMsg === 'Values under this column should be unique'
    ) {
      const validationResult: any = conductManualValidationCheck(
        gridApi,
        key,
        columnConfigs,
        rowData,
      );

      if (validationResult.result) {
        return cellValueFormatter(cellValue.value, cellValue.original, true);
      }
      const lastValidation = {
        validationFailedValue: cellValue.value,
        validationFailedMsg: validationResult['message'],
      };
      return cellValueFormatter(cellValue.value, cellValue.original, lastValidation);
    }
    return cellValue;
  };

  const columns = duplicateCheckConfig.columns;
  const errorStrings = [] as any;
  columns.forEach((colConfig: IAgDuplicateCheckColumnConfig) => {
    const { colName, parentColumns, comparsionFn } = colConfig;
    if (data.length === 1) {
      data[0][colName] = passCellValue(colName, data[0]);
    }

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data.length; j++) {
        // Skip the self comparison
        if (i === j) continue;
        // ParentColumns have to be equal for the column to be compared
        if (parentColumns) {
          const parentsNotEqual =
            parentColumns.filter(
              (parentCol) =>
                !isEqual(getCellValue(data[i][parentCol]), getCellValue(data[j][parentCol]), true),
            ).length > 0;
          if (parentsNotEqual) {
            data[i][colName] = passCellValue(colName, data[i]);
            continue;
          }
        }

        const compareableA = getCellValue(data[i][colName]) || '';
        const compareableB = getCellValue(data[j][colName]) || '';
        const duplicateValue = comparsionFn
          ? comparsionFn(compareableA, compareableB)
          : isEqual(compareableA, compareableB);
        if (duplicateValue) {
          data[i][colName] = failCellValue(data[i][colName], {
            validationFailedValue: compareableA,
            validationFailedMsg: 'Values under this column should be unique',
          });
          // set error string here
          if (parentColumns) {
            const parentColumnsString = parentColumns
              .map((col) => getCellValue(data[i][col]))
              .join(', ');
            errorStrings.push(
              `Duplicate Entries for (${parentColumnsString}) in ${columnConfigs[colName].headerName}: ${duplicateValue}`,
            );
          } else {
            errorStrings.push(`Duplicate ${columnConfigs[colName].headerName}: ${duplicateValue}`);
          }
          break;
        } else {
          data[i][colName] = passCellValue(colName, data[i]);
        }
      }
    }
  });
  return uniq(errorStrings);
};

export const duplicateValidationFnHelper =
  (params: ValueSetterParams) =>
  (newValue: string | any): IValidationResult => {
    const column = params.colDef.field as string;
    let result = true;
    const locationFormatter = (location: any) => {
      const locationName = String(location).toUpperCase();
      return locationName.indexOf('PL_') > -1 || locationName.indexOf('CA_') > -1
        ? locationName.substring(3)
        : locationName;
    };

    params.api.forEachNode((node) => {
      if (params?.node?.rowIndex !== node.rowIndex && result) {
        const rowData = getCellValue(node.data[column]);
        // For array values
        if (rowData && isArray(rowData) && newValue && isArray(newValue)) {
          result = !rowData.some((element) => newValue.includes(element));
        }
        // For string values
        else {
          // For values under Location Name columns, we need to remove prefix to do the comparsion
          if (column === 'locationName') {
            const newValueComparable = locationFormatter(newValue);
            const rowDataComparable = locationFormatter(rowData);
            result = rowDataComparable !== newValueComparable;
          } else {
            result = String(rowData).toUpperCase() !== String(newValue).toUpperCase();
          }
        }
      }
    });

    return {
      result,
      message: result ? 'Validation Passed' : 'Values under this column should be unique',
    };
  };

export const launchDateValidationFn =
  (params: ValueSetterParams) =>
  (newValue: string): IValidationResult => {
    const regExpResult = regexpValidationFnHelper(
      '^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$',
      '',
    )(params)(newValue)['result'];

    if (!regExpResult) {
      return {
        result: false,
        message: 'The date is not following the required format(yyyy-mm-dd).',
      };
    }

    const inputDate = new Date(newValue);
    const currentDate = new Date();
    const result = inputDate > currentDate;

    return {
      result: inputDate > currentDate,
      message: result ? 'Validation Passed' : 'The date should be set to a future date.',
    };
  };

export const locationPlaceholerValidationFn = regexpValidationFnHelper(
  '^[a-zA-Z0-9]{4}$',
  'The location code is not following the required format',
);

export const editorToValidationFnMap = (editor: string, planType: PlanTypeId | null = null) => {
  switch (editor) {
    case 'AgTreeSelect':
      return dropdownValidationFn;
    case 'AgTreeMultiSelect':
      return multipleDropdownValidationFn;
    case 'DatePicker':
      // If used for PLANet Snapshot Date, launch date validations do not apply.
      if (PlanTypeId.UTR_COST_HC === planType) {
        return null;
      }
      return launchDateValidationFn;
    default:
      return null;
  }
};

function conductManualValidationCheck(_: any, _1: any, _2: any, _3: any) {
  throw new Error('Function not implemented.');
}
