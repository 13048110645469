import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBatchMetadataMutation } from 'src/api/mutation/useBatchMetadataMutation';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { RequestVariables } from 'src/api/api-utils';
import UserConfirmModal, { ConfirmModalInfo } from 'src/common/UserConfirmModal';
import { IUpdateBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';

interface IUseUpdateBatchMetadataWithConfirmProps {
  batchId: string | undefined;
  inProgressNotificationText: string;
  errorNotificationText: string;
  successNotificationText: string;
  onFinish: () => void;
}

type UpdatedMetadata = Partial<RequestVariables<IUpdateBatchMetadataParsed>>;

export const useUpdateBatchMetadataWithConfirm = ({
  batchId,
  inProgressNotificationText,
  errorNotificationText,
  successNotificationText,
  onFinish,
}: IUseUpdateBatchMetadataWithConfirmProps) => {
  const { refetch: refetchBatchMetadata } = useBatchMetadata({ batchId }, { disabled: !batchId });
  const { mutate } = useBatchMetadataMutation({
    meta: { inProgressNotificationText, errorNotificationText, successNotificationText },
  });
  const { t } = useTranslation();

  const [confirmModalInfo, setConfirmModalInfo] = useState<ConfirmModalInfo>(null);

  const handleUpdateRecursive = async (
    updatedMetadata: UpdatedMetadata,
    userConfirmed: boolean,
  ) => {
    const { data: freshBatchMetadata } = await refetchBatchMetadata();

    mutate(
      { ...freshBatchMetadata, ...updatedMetadata, userConfirmed },
      {
        onError: onFinish,
        onSuccess: (data) => {
          if (data.message) {
            setConfirmModalInfo({
              onProceed: () => {
                handleUpdateRecursive(updatedMetadata, true);
              },
              header: t('user_confirm_modal_header'),
              message: data.message,
            });
          } else {
            onFinish();
          }
        },
      },
    );
  };

  return {
    ConfirmationModal: (
      <UserConfirmModal
        confirmModalInfo={confirmModalInfo}
        setConfirmModalInfo={setConfirmModalInfo}
      />
    ),
    handleUpdate: (updatedMetadata: UpdatedMetadata) =>
      handleUpdateRecursive(updatedMetadata, false),
  };
};
