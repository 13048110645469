import { Box, Button, SpaceBetween, Spinner } from '@amzn/awsui-components-react/polaris';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useBatchDashboard } from 'src/api/query/useBatchDashboard';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';

const PlanAnalysisTab = (): JSX.Element => {
  const [quicksightRemountKey, setQuicksightRemountKey] = useState(1);
  const { batchId } = useParams();
  const { t } = useTranslation();

  const { data: batchMetadata } = useBatchMetadata({
    batchId,
  });

  const { data: dashboardUrl, isError: dashboardUrlError } = useBatchDashboard(
    {
      batchId: batchMetadata?.batchId,
      costType: batchMetadata?.costType,
      businessGroup: batchMetadata?.businessGroup,
      periodType: batchMetadata?.periodType,
      region: batchMetadata?.region,
    },
    {
      disabled: !batchMetadata,
    },
  );

  /*Sometimes quicksight logs in successfully but still fails to load. Refreshing after logging in 
  fixes this issue. Needs better long term solution.*/
  const forceQuicksightRemount = () => setQuicksightRemountKey(quicksightRemountKey + 1);

  return !batchMetadata ? (
    <Spinner />
  ) : dashboardUrlError ? (
    <Box margin={{ vertical: 's' }}>{t('dashboard_not_supported_message')}</Box>
  ) : (
    <>
      <Box float="right" margin={{ vertical: 'xs' }}>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            href={dashboardUrl?.dashboardUrl ?? ''}
            iconName="external"
            iconAlign="right"
            target="_blank"
          >
            {t('dashboard_link_button')}
          </Button>
          <Button variant="normal" onClick={forceQuicksightRemount}>
            {t('refresh')}
          </Button>
        </SpaceBetween>
      </Box>
      <iframe
        key={quicksightRemountKey}
        style={{ width: '100%', height: '150vh' }}
        title="quicksightEmbed"
        src={dashboardUrl?.dashboardUrl ?? ''}
      />
      <Box margin={{ vertical: 'xxxl' }} />
    </>
  );
};

export default PlanAnalysisTab;
