import {
  Container,
  FormField,
  Header,
  Select,
  SpaceBetween,
  DatePicker,
  Input,
  ExpandableSection,
} from '@amzn/awsui-components-react/polaris';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PlanCreateFields,
  PlanCreateFieldIds,
  getPeriodTypeOptions,
  getPlanCycleOptions,
  getCurrencyOptions,
  getPlanYearOptions,
  getStartDateEnabledCriteria,
  getEndDateEnabledCriteria,
  shouldDisableEditingStartDate,
  shouldDisableEditingEndDate,
  shouldHideCurrencyDisplay,
  shouldHideDateDisplay,
} from 'src/pages/create-plan-page/createPlanBusinessLogic';
import { PeriodTypeId } from 'src/utils/planning/planetModel';

interface ICreatePlanDetailsPanelProps {
  formContent: PlanCreateFields;
  onUpdateFormField: (fieldId: PlanCreateFieldIds, value: any) => void;
}

export const CreatePlanDetailsPanel = ({
  formContent,
  onUpdateFormField,
}: ICreatePlanDetailsPanelProps) => {
  const { t } = useTranslation();

  const isPlanGeneralSet = useMemo(
    () =>
      formContent.planType &&
      formContent.businessGroup &&
      formContent.subGroup &&
      formContent.region &&
      formContent.countries.length,
    [
      formContent.businessGroup,
      formContent.countries.length,
      formContent.planType,
      formContent.region,
      formContent.subGroup,
    ],
  );

  const currencyOptions = getCurrencyOptions(formContent);

  const planDetailsFields = [
    {
      identifier: PlanCreateFieldIds.PLAN_CYCLE,
      content: (
        <SpaceBetween size="xxl" direction="horizontal">
          <div style={{ minWidth: 210 }}>
            <FormField label={t('planning_cycle_label')}>
              <Select
                selectedOption={formContent.planCycle}
                options={getPlanCycleOptions(formContent)}
                onChange={(e) =>
                  onUpdateFormField(PlanCreateFieldIds.PLAN_CYCLE, e.detail.selectedOption)
                }
                placeholder={t('planning_cycle_placeholder')}
              />
            </FormField>
          </div>
          <div style={{ minWidth: 210 }}>
            <FormField label={t('plan_start_year_label')}>
              <Select
                selectedOption={formContent.startYear}
                options={getPlanYearOptions()}
                onChange={(e) =>
                  onUpdateFormField(PlanCreateFieldIds.START_YEAR, e.detail.selectedOption)
                }
                placeholder={t('planning_cycle_placeholder')}
              />
            </FormField>
          </div>
        </SpaceBetween>
      ),
      shouldHide: false,
      isAdvancedSetting: false,
    },
    {
      identifier: PlanCreateFieldIds.START_DATE,
      content: (
        <SpaceBetween size="xxl" direction="horizontal">
          <FormField
            label={t(
              formContent.periodType?.value === PeriodTypeId.WEEKLY
                ? 'start_week_label'
                : 'start_month_label',
            )}
            description={t(
              formContent.periodType?.value === PeriodTypeId.WEEKLY
                ? 'start_week_description'
                : 'start_month_description',
            )}
          >
            <DatePicker
              value={formContent.startDate}
              onChange={(e) => onUpdateFormField(PlanCreateFieldIds.START_DATE, e.detail.value)}
              isDateEnabled={getStartDateEnabledCriteria(formContent)}
              disabled={shouldDisableEditingStartDate(formContent)}
              granularity={formContent.periodType?.value === PeriodTypeId.WEEKLY ? 'day' : 'month'}
            />
          </FormField>
          <FormField
            label={t(
              formContent.periodType?.value === PeriodTypeId.WEEKLY
                ? 'end_week_label'
                : 'end_month_label',
            )}
            description={t(
              formContent.periodType?.value === PeriodTypeId.WEEKLY
                ? 'end_week_description'
                : 'end_month_description',
            )}
          >
            <DatePicker
              value={formContent.endDate}
              onChange={(e) => onUpdateFormField(PlanCreateFieldIds.END_DATE, e.detail.value)}
              isDateEnabled={getEndDateEnabledCriteria(formContent)}
              invalid={formContent.startDate > formContent.endDate}
              disabled={shouldDisableEditingEndDate(formContent)}
              granularity={formContent.periodType?.value === PeriodTypeId.WEEKLY ? 'day' : 'month'}
            />
          </FormField>
        </SpaceBetween>
      ),
      shouldHide: shouldHideDateDisplay(formContent),
      isAdvancedSetting: true,
    },
    {
      identifier: PlanCreateFieldIds.PERIOD_TYPE,
      content: (
        <FormField label={t('period_type_label')}>
          <Select
            selectedOption={formContent.periodType}
            options={getPeriodTypeOptions(formContent)}
            onChange={(e) =>
              onUpdateFormField(PlanCreateFieldIds.PERIOD_TYPE, e.detail.selectedOption)
            }
            placeholder={t('period_type_placeholder')}
            disabled={getPeriodTypeOptions(formContent).length < 2}
          />
        </FormField>
      ),
      shouldHide: true,
      isAdvancedSetting: true,
    },
    {
      identifier: PlanCreateFieldIds.SLOT_DESCRIPTION,
      content: (
        <SpaceBetween size="m" direction="vertical">
          <FormField
            label={t('plan_description_label')}
            description={t('plan_description_description')}
            constraintText={t('plan_description_constraint_text')}
          >
            <Input
              value={formContent.slotDescription}
              placeholder={t('plan_description_placeholder')}
              onChange={(e) =>
                onUpdateFormField(PlanCreateFieldIds.SLOT_DESCRIPTION, e.detail.value)
              }
            />
          </FormField>
        </SpaceBetween>
      ),
      shouldHide: false,
      isAdvancedSetting: false,
    },
    {
      identifier: PlanCreateFieldIds.CURRENCY,
      content: (
        <FormField label={t('default_currency_label')}>
          <Select
            selectedOption={formContent.currency}
            options={currencyOptions}
            onChange={(e) =>
              onUpdateFormField(PlanCreateFieldIds.CURRENCY, e.detail.selectedOption)
            }
            disabled={currencyOptions.length <= 1}
          />
        </FormField>
      ),
      shouldHide: shouldHideCurrencyDisplay(formContent),
      isAdvancedSetting: true,
    },
  ];

  const mainPlanDetailFields = planDetailsFields.filter(
    (fieldInfo) => !(fieldInfo.shouldHide || fieldInfo.isAdvancedSetting),
  );

  const additionalPlanDetailFields = planDetailsFields.filter(
    (fieldInfo) => !(fieldInfo.shouldHide || !fieldInfo.isAdvancedSetting),
  );

  return isPlanGeneralSet ? (
    <Container
      header={<Header>{t('plan_details_header')}</Header>}
      footer={
        additionalPlanDetailFields.length !== 0 ? (
          <ExpandableSection variant="footer" headerText={t('additional_settings_header')}>
            <SpaceBetween size="l">
              {additionalPlanDetailFields.map((formField) => (
                <div key={formField.identifier}>{formField.content}</div>
              ))}
            </SpaceBetween>
          </ExpandableSection>
        ) : null
      }
    >
      <SpaceBetween size="l">
        {mainPlanDetailFields.map((formField) => (
          <div key={formField.identifier}>{formField.content}</div>
        ))}
      </SpaceBetween>
    </Container>
  ) : (
    <></>
  );
};
