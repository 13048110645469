import { useEffect, useMemo } from 'react';
import { LocalStorageKeys, useLocalStorage } from 'src/hooks/useLocalStorage';
import { useQueryParams } from 'src/hooks/useQueryParams';
import {
  BUSINESS_GROUPS,
  IPlanTypeOption,
  REGIONS,
  SUB_GROUPS,
  useSupportedPlanTypes,
} from 'src/utils/planning/planetModel';
import {
  BusinessFilterType,
  ISelectedGroupFiltersNonNull,
  ISupportedGroup,
  useSupportedBusinessGroups,
} from 'src/utils/planning/supportedBusinessGroups';

const isQueryParamsValid = (args: {
  supportedBusinessGroups: ISupportedGroup[];
  queryParams: Record<BusinessFilterType, string>;
}) =>
  args.supportedBusinessGroups.some(
    (item) =>
      item.planType === args.queryParams.planType &&
      item.businessGroup === args.queryParams.businessGroup &&
      item.subGroup === args.queryParams.subGroup &&
      item.region === args.queryParams.region,
  );

const findPlanFilters = (args: {
  supportedPlanTypes: IPlanTypeOption[];
  planType: string;
  businessGroup: string;
  subGroup: string;
  region: string;
}) => ({
  planType: args.supportedPlanTypes.find((item) => item.value === args.planType)!,
  businessGroup: BUSINESS_GROUPS.find((item) => item.value === args.businessGroup)!,
  subGroup: SUB_GROUPS.find((item) => item.value === args.subGroup)!,
  region: REGIONS.find((item) => item.value === args.region)!,
});

/**
 * Initializes planFilters with from query parameters, localStorage, or default value with decreasing priority.
 * @returns planFilters and updatePlanFilters which will update both the planFilters state and the localStorage
 */
const usePlanFiltersWithQueryParams = () => {
  const { queryParams, setQueryParams } = useQueryParams<Record<BusinessFilterType, string>>();
  const { supportedPlanTypes } = useSupportedPlanTypes();
  const { supportedBusinessGroups } = useSupportedBusinessGroups();

  const defaultPlanFilters = useMemo(
    () => findPlanFilters({ supportedPlanTypes, ...supportedBusinessGroups[0] }),
    [supportedBusinessGroups, supportedPlanTypes],
  );

  const validPlanFiltersFromQueryParams = useMemo(() => {
    const isValid = isQueryParamsValid({ supportedBusinessGroups, queryParams });
    return isValid ? findPlanFilters({ supportedPlanTypes, ...queryParams }) : null;
  }, [queryParams, supportedBusinessGroups, supportedPlanTypes]);

  /**
   * initializes planFilters with from query parameters, localStorage, or default value
   */
  const [planFilters, setPlanFilters] = useLocalStorage<ISelectedGroupFiltersNonNull>({
    key: [LocalStorageKeys.LIST_PLAN_FILTERS],
    defaultValue: defaultPlanFilters,
    overrideInitialValue: validPlanFiltersFromQueryParams,
  });

  /**
   * if query parameters are valid, sync query parameters to localStorage
   * otherwise, revert query parameters to the last valid values
   */
  useEffect(() => {
    if (validPlanFiltersFromQueryParams) {
      setPlanFilters(validPlanFiltersFromQueryParams);
    } else {
      setQueryParams({
        planType: planFilters.planType.value,
        businessGroup: planFilters.businessGroup.value,
        subGroup: planFilters.subGroup.value,
        region: planFilters.region.value,
      });
    }
  }, [
    planFilters.businessGroup.value,
    planFilters.planType.value,
    planFilters.region.value,
    planFilters.subGroup.value,
    setPlanFilters,
    setQueryParams,
    validPlanFiltersFromQueryParams,
  ]);

  return { planFilters, updatePlanFilters: setQueryParams };
};

export default usePlanFiltersWithQueryParams;
