import { useCallback, useState } from 'react';

/**
 * Define local storage key constants
 */
export enum LocalStorageKeys {
  LIST_PLAN_FILTERS = 'PLANet-list-plan-filters',
  DARK_MODE_PREFERENCE = 'PLANet-dark-mode-preference',
  AG_GRID_PIVOT_CONFIG = 'PLANet-ag-grid-pivot-config',
}

type UseLocalStorage = <T>(props: {
  key: string[];
  defaultValue: T;
  overrideInitialValue?: T | null;
}) => [T, (v: T) => void];

const save = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const load = (key: string) => {
  const value = localStorage.getItem(key);
  try {
    return value && JSON.parse(value);
  } catch (_) {
    console.warn(
      `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`,
    );
    return undefined;
  }
};

/**
 * Local storage manager hook that syncs a value to localstorage and has different
 * initialization behavior based on the arguments passed and the whether the user
 * has data associated with the key in localstorage.
 *
 * If a non-nullish overrideInitialValue value is passed, it will always take precedence.
 * Otherwise, localStorage is checked for the value and returned if found.
 * If both overrideInitialValue value and localStorage value are nullish, the passed default value will be used.
 *
 * @returns value and a handleValueChange function which syncs the value to localstorage
 */
export const useLocalStorage: UseLocalStorage = ({ key, defaultValue, overrideInitialValue }) => {
  const storageKey = key.join('-');

  const [value, setValue] = useState(
    () => overrideInitialValue ?? (load(storageKey) as typeof defaultValue) ?? defaultValue,
  );

  const handleValueChange = useCallback(
    (newValue: typeof defaultValue) => {
      setValue(newValue);
      save(storageKey, newValue);
    },
    [storageKey],
  );

  return [value, handleValueChange] as const;
};
