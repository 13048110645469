import { cloneDeep, find } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  PlanTypeId,
  RegionId,
  SubGroupId,
  BusinessGroupId,
  BUSINESS_GROUPS,
  SUB_GROUPS,
  IPlanTypeOption,
  ISubGroupOption,
  IRegionOption,
  IBusinessGroupOption,
  useSupportedPlanTypes,
} from './planetModel';

export interface ISupportedGroup {
  businessGroup: BusinessGroupId;
  subGroup: SubGroupId;
  region: RegionId;
  planType: PlanTypeId;
}

const ALL_BUSINESS_GROUPS: ISupportedGroup[] = [
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AccessPoint,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AccessPoint,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AccessPoint,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AccessPoint,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AccessPoint,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.PhysicalStores,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.PhysicalStores,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.PhysicalStores,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.PhysicalStores,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.PhysicalStores,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.DistributionCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.DistributionCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.DistributionCenter,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.DistributionCenter,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.GSF,
    subGroup: SubGroupId.DistributionCenter,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWRE,
    subGroup: SubGroupId.GSP,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWRE,
    subGroup: SubGroupId.GSP,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWRE,
    subGroup: SubGroupId.GSP,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWRE,
    subGroup: SubGroupId.GSP,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWRE,
    subGroup: SubGroupId.GSP,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWOPS,
    subGroup: SubGroupId.Robotics,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWOPS,
    subGroup: SubGroupId.Robotics,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWOPS,
    subGroup: SubGroupId.Robotics,
    region: RegionId.APAC,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWOPS,
    subGroup: SubGroupId.Robotics,
    region: RegionId.LATAM,
  },
  {
    planType: PlanTypeId.CAPEX,
    businessGroup: BusinessGroupId.WWOPS,
    subGroup: SubGroupId.Robotics,
    region: RegionId.MENA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.DEPRECIATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.REAL_ESTATE,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.JANITORIAL,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.JANITORIAL,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.JANITORIAL,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.JANITORIAL,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.JANITORIAL,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.JANITORIAL,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.SECURITY,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.SECURITY,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.SECURITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.SECURITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.SECURITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.SECURITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.THREE_P_TRANSPORTATION,
    businessGroup: BusinessGroupId.GTS,
    subGroup: SubGroupId.ThreePCarriers,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.THREE_P_TRANSPORTATION,
    businessGroup: BusinessGroupId.GTS,
    subGroup: SubGroupId.ThreePCarriers,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.OTR_WAREHOUSE_TRANSFER,
    businessGroup: BusinessGroupId.InboundTransportation,
    subGroup: SubGroupId.Transportation,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.OTR_WAREHOUSE_TRANSFER,
    businessGroup: BusinessGroupId.InboundTransportation,
    subGroup: SubGroupId.Transportation,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.OTR_DOMESTIC_INBOUND,
    businessGroup: BusinessGroupId.InboundTransportation,
    subGroup: SubGroupId.Transportation,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.OTR_DOMESTIC_INBOUND,
    businessGroup: BusinessGroupId.InboundTransportation,
    subGroup: SubGroupId.Transportation,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.OTR_FREIGHT_CAPITALIZATION,
    businessGroup: BusinessGroupId.InboundTransportation,
    subGroup: SubGroupId.Transportation,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.OTR_FREIGHT_CAPITALIZATION,
    businessGroup: BusinessGroupId.InboundTransportation,
    subGroup: SubGroupId.Transportation,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.TOPS_DOWN_FORECAST,
    businessGroup: BusinessGroupId.All,
    subGroup: SubGroupId.All,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.TOPS_DOWN_FORECAST,
    businessGroup: BusinessGroupId.All,
    subGroup: SubGroupId.All,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_PRODUCTIVITY,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.UTR_COST_HC,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.Fleet,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.OTR_SUPPLY_TYPE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.OTR_SUPPLY_TYPE,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.FulfillmentCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.SortCenter,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Linehaul,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.ATS,
    subGroup: SubGroupId.Air,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMZL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.AMXL,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.RSR,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.NA,
  },
  {
    planType: PlanTypeId.FIXED_COST_CONSOLIDATION,
    businessGroup: BusinessGroupId.GDS,
    subGroup: SubGroupId.SSD,
    region: RegionId.EU,
  },
  {
    planType: PlanTypeId.CF_CONSOLIDATION,
    businessGroup: BusinessGroupId.GCF,
    subGroup: SubGroupId.All,
    region: RegionId.NA,
  },
];

export const useSupportedBusinessGroups = () => {
  const { supportedPlanTypeSet } = useSupportedPlanTypes();

  const supportedBusinessGroups = useMemo(
    () => ALL_BUSINESS_GROUPS.filter(({ planType }) => supportedPlanTypeSet.has(planType)),
    [supportedPlanTypeSet],
  );

  return { supportedBusinessGroups };
};

export interface ISelectedGroupFilters {
  businessGroup: IBusinessGroupOption | null;
  subGroup: ISubGroupOption | null;
  region: IRegionOption | null;
  planType: IPlanTypeOption | null;
}

export interface ISelectedGroupFiltersNonNull {
  businessGroup: IBusinessGroupOption;
  subGroup: ISubGroupOption;
  region: IRegionOption;
  planType: IPlanTypeOption;
}

export const isSelectedFiltersValid = (selectedFilters: ISelectedGroupFilters) =>
  !Object.values(selectedFilters).some((val) => val === null);

/**
 *
 * @param selectedGroupFilters List of currently selected filters
 * @returns List of allowed filters based on currently selected filters and
 * supported business groups {@link useSupportedBusinessGroups}
 */
export const useGetBusinessGroupFilters = () => {
  const { supportedPlanTypes } = useSupportedPlanTypes();
  const { supportedBusinessGroups } = useSupportedBusinessGroups();

  const getBusinessGroupFilters = useCallback(
    (selectedGroupFilters: ISelectedGroupFilters) => {
      const allowedPlanTypes = supportedPlanTypes;
      const allowedBusinessGroups = Object.values(BUSINESS_GROUPS).filter((businessGroupOption) =>
        supportedBusinessGroups.some((supportedGroup) => {
          const planTypeMatches =
            !selectedGroupFilters.planType ||
            selectedGroupFilters.planType.value === supportedGroup.planType;
          const subGroupMatches = businessGroupOption.value === supportedGroup.businessGroup;

          return planTypeMatches && subGroupMatches;
        }),
      );

      const allowedSubGroups = Object.values(SUB_GROUPS).filter((subGroupItem) =>
        supportedBusinessGroups.some((supportedGroup) => {
          const planTypeMatches =
            !selectedGroupFilters.planType ||
            selectedGroupFilters.planType.value === supportedGroup.planType;
          const businessGroupMatches =
            !selectedGroupFilters.businessGroup ||
            selectedGroupFilters.businessGroup.value === supportedGroup.businessGroup;
          const subGroupMatches = subGroupItem.value === supportedGroup.subGroup;

          return planTypeMatches && businessGroupMatches && subGroupMatches;
        }),
      );

      const allowedRegions = Object.values(RegionId)
        .map((region) => ({
          label: region,
          value: region,
        }))
        .filter((regionOption) =>
          supportedBusinessGroups.some((supportedGroup) => {
            const planTypeMatches =
              !selectedGroupFilters.planType ||
              selectedGroupFilters.planType.value === supportedGroup.planType;
            const businessGroupMatches =
              !selectedGroupFilters.businessGroup ||
              selectedGroupFilters.businessGroup.value === supportedGroup.businessGroup;
            const subGroupMatches =
              !selectedGroupFilters.subGroup ||
              selectedGroupFilters.subGroup.value === supportedGroup.subGroup;
            const regionMatches = regionOption.value === supportedGroup.region;

            return planTypeMatches && businessGroupMatches && subGroupMatches && regionMatches;
          }),
        );

      return {
        allowedPlanTypes,
        allowedBusinessGroups,
        allowedSubGroups,
        allowedRegions,
      };
    },
    [supportedBusinessGroups, supportedPlanTypes],
  );

  return { getBusinessGroupFilters };
};

export enum BusinessFilterType {
  PLAN_TYPE = 'planType',
  BUSINESS_GROUP = 'businessGroup',
  SUB_GROUP = 'subGroup',
  REGION = 'region',
}

export interface ISelectedOption {
  label: string;
  value: string;
}

/**
 * Invalidates listPlans filter values hierarchically for when user changes a value, giving
 * highest priority to planType, then businessGroup, then subGroup, then region.
 * Also automatically sets filters if there is only one option (due to the user selection)
 * @param selectedGroupFilters Current selected plan filters
 * @param filterType Name of updated filter
 * @param filterValue New value of updated filter
 * @returns New selected filters object that contains no invalid combinations of
 * playType, businessGroup, subGroup, and region, according to {@link useSupportedBusinessGroups}
 */
export const useGetUpdatedSelectedGroupFilters = () => {
  const { getBusinessGroupFilters } = useGetBusinessGroupFilters();

  const getUpdatedSelectedGroupFilters = useCallback(
    (
      selectedGroupFilters: ISelectedGroupFilters,
      filterType: BusinessFilterType,
      filterValue: ISelectedOption,
    ) => {
      const newSelectedGroupFilters: ISelectedGroupFilters = {
        planType: cloneDeep(selectedGroupFilters.planType),
        businessGroup: null,
        subGroup: null,
        region: null,
      };

      switch (filterType) {
        case BusinessFilterType.PLAN_TYPE:
          newSelectedGroupFilters.planType = filterValue as IPlanTypeOption;
          break;
        case BusinessFilterType.BUSINESS_GROUP:
          newSelectedGroupFilters.businessGroup = filterValue as IBusinessGroupOption;
          break;
        case BusinessFilterType.SUB_GROUP:
          newSelectedGroupFilters.subGroup = filterValue as ISubGroupOption;
          break;
        case BusinessFilterType.REGION:
          newSelectedGroupFilters.region = filterValue as IRegionOption;
          break;
      }

      // Check valid businessGroup selection options with prior filters
      const { allowedBusinessGroups } = getBusinessGroupFilters(newSelectedGroupFilters);

      // Assign business group to prior selected business group, if no current filter selected
      // If only one valid option, automatically set to that option
      if (!newSelectedGroupFilters.businessGroup) {
        if (
          selectedGroupFilters.businessGroup &&
          find(allowedBusinessGroups, selectedGroupFilters.businessGroup)
        ) {
          newSelectedGroupFilters.businessGroup = selectedGroupFilters.businessGroup;
        } else if (allowedBusinessGroups.length === 1) {
          newSelectedGroupFilters.businessGroup = allowedBusinessGroups[0];
        }
      }

      // Do the same for subgroup
      const { allowedSubGroups } = getBusinessGroupFilters(newSelectedGroupFilters);

      if (!newSelectedGroupFilters.subGroup) {
        if (
          selectedGroupFilters.subGroup &&
          find(allowedSubGroups, selectedGroupFilters.subGroup)
        ) {
          newSelectedGroupFilters.subGroup = selectedGroupFilters.subGroup;
        } else if (allowedSubGroups.length === 1) {
          newSelectedGroupFilters.subGroup = allowedSubGroups[0];
        }
      }

      // Do the same for region
      const { allowedRegions } = getBusinessGroupFilters(newSelectedGroupFilters);

      if (!newSelectedGroupFilters.region) {
        if (selectedGroupFilters.region && find(allowedRegions, selectedGroupFilters.region)) {
          newSelectedGroupFilters.region = selectedGroupFilters.region;
        } else if (allowedRegions.length === 1) {
          newSelectedGroupFilters.region = allowedRegions[0];
        }
      }

      return newSelectedGroupFilters;
    },
    [getBusinessGroupFilters],
  );

  return { getUpdatedSelectedGroupFilters };
};
