import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  FormField,
  Modal,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { PlanTagEnumEnum } from '@amzn/fox-den-cost-planning-lambda';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useUpdateBatchMetadataWithConfirm } from 'src/pages/commons/plan-actions/useUpdateBatchMetadataWithConfirm';
import { PLAN_TAG_OPTIONS, PlanTypeId } from 'src/utils/planning/planetModel';

interface IUpdateTagsModalParams {
  visible: boolean;
  setShowModal: (value: SetStateAction<boolean>) => void;
}

const UpdateTagsModal = ({ visible, setShowModal }: IUpdateTagsModalParams) => {
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const [isDataPrefilled, setIsDataPrefilled] = useState(false);

  const defaultTagOption = PLAN_TAG_OPTIONS.find((o) => o.value === PlanTagEnumEnum._)!;

  const [planTagInput, setPlanTagInput] = useState<SelectProps.Option>(defaultTagOption);

  const [finalTagInput, setFinalTagInput] = useState(false);

  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    setShowModal(false);
    setIsDataPrefilled(false);
  }, [setShowModal]);

  const { ConfirmationModal, handleUpdate } = useUpdateBatchMetadataWithConfirm({
    batchId,
    inProgressNotificationText: t('api_in_progress_update_plan'),
    errorNotificationText: t('api_error_update_plan'),
    successNotificationText: t('api_success_update_plan'),
    onFinish: closeModal,
  });

  const handleUpdateTag = () =>
    handleUpdate({
      planTag: planTagInput?.value as PlanTagEnumEnum,
      finalTag: finalTagInput,
      updateFields: ['general'],
    } as any); // TODO: Need to fix type definition

  const batchMetadataPlanTagValue = batchMetadata?.planTag || '';

  const inputValid =
    batchMetadataPlanTagValue !== planTagInput?.value || batchMetadata?.finalTag !== finalTagInput;

  const handleUpdateTagSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (inputValid) handleUpdateTag();
  };

  useEffect(() => {
    if (visible && batchMetadata && !isDataPrefilled) {
      const selectedTagOption = PLAN_TAG_OPTIONS.find((o) => o.value === batchMetadataPlanTagValue);
      setPlanTagInput(selectedTagOption || defaultTagOption);
      setFinalTagInput(batchMetadata.finalTag!);
      setIsDataPrefilled(true);
    }
  }, [batchMetadata, batchMetadataPlanTagValue, defaultTagOption, isDataPrefilled, visible]);

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={closeModal}
        header={t('update_tags_title')}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                {t('cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={handleUpdateTagSubmit}
                disabled={!inputValid}
                data-testid="submit"
              >
                {t('update_tags_action')}
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size="m">
          <form onSubmit={handleUpdateTagSubmit}>
            <SpaceBetween size="s">
              <FormField label={t('plan_tag_label')}>
                <ColumnLayout columns={2}>
                  <Select
                    selectedOption={planTagInput}
                    options={PLAN_TAG_OPTIONS}
                    onChange={(e) => {
                      setPlanTagInput(e.detail.selectedOption);
                    }}
                  />
                </ColumnLayout>
              </FormField>
              {batchMetadata?.costType !== PlanTypeId.CF_CONSOLIDATION ? (
                <FormField label={t('final_tag_label')}>
                  <ColumnLayout columns={2}>
                    <Checkbox
                      onChange={(e) => setFinalTagInput(e.detail.checked)}
                      checked={finalTagInput}
                    >
                      {t('final_tag_checkbox')}
                    </Checkbox>
                  </ColumnLayout>
                </FormField>
              ) : (
                <></>
              )}
            </SpaceBetween>
          </form>
        </SpaceBetween>
      </Modal>
      {ConfirmationModal}
    </>
  );
};

const EditTagsButton = () => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Box margin={{ left: 'xs' }} display="inline-block">
        <Button onClick={() => setShowModal(true)} href="#" variant="inline-link">
          {t('edit')}
        </Button>
      </Box>
      <UpdateTagsModal visible={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default EditTagsButton;
