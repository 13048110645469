import { ICellEditorParams } from 'ag-grid-enterprise';
import { uniqBy } from 'lodash';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import MultiTreeSelect from 'src/common/ag-grid/editors/ag-tree-select/MultiTreeSelect';
import { ISingleTreeDataSource } from 'src/common/ag-grid/editors/ag-tree-select/SingleTreeSelect';

const MULTISELECT_DELIMITER = ',';

const AgMultiTreeSelectFunc = (props: ICellEditorParams | any, ref: any) => {
  const { value, disabled, treeDataSource, node, column, dropDownOptions } = props;

  const getInitValues = (value: any) => {
    if (typeof value !== 'string') return value || [];

    return value.split(MULTISELECT_DELIMITER);
  };

  const uniqueTreeDataSource: ISingleTreeDataSource[] = useMemo(
    () => uniqBy(treeDataSource, 'id') as any,
    [treeDataSource],
  );
  const [selectedOptions, setSelectedOptions] = useState(getInitValues(value)); //Make transitions from single select to multi-select backward compatible.
  useImperativeHandle(ref, () => ({
    getValue: () => selectedOptions,
    isCancelAfterEnd: () => !selectedOptions,
  }));

  return (
    <div
      id="DropdownContainer"
      style={{ height: node.rowHeight + 'px', width: column.actualWidth + 'px' }}
    >
      <MultiTreeSelect
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        disabled={disabled}
        treeDataSource={uniqueTreeDataSource}
        dropDownOptions={{ container: '#DropdownContainer', ...dropDownOptions }}
        selectAllUnselectsAll={props.selectAllUnselectsAll}
      />
    </div>
  );
};

export default forwardRef(AgMultiTreeSelectFunc);
