import { Spinner } from '@amzn/awsui-components-react/polaris';

const GridLoadingSpinner = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner size="large" />
  </div>
);

export default GridLoadingSpinner;
