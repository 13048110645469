import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect } from 'react';

export const useAgGridResizeColumns = (
  gridRef: RefObject<AgGridReact>,
  containerRef: RefObject<HTMLDivElement>,
  gridRowData: any,
) => {
  const resizeColumns = useCallback(() => {
    const gridColumnApi = gridRef.current?.columnApi;

    if (!gridColumnApi) return;

    gridColumnApi.autoSizeAllColumns();

    // Following code is to make last column use up remaining column space if
    // autoSizeAllColumns leaves extra space to the right of the last column
    const gridWidth = containerRef.current?.clientWidth;

    if (!gridWidth) return;

    const columns = gridColumnApi?.getAllGridColumns();
    const lastColumn = columns[columns.length - 1];

    if (!lastColumn) return;

    const lastColumnLeft = lastColumn.getLeft();
    const lastColumnWidth = lastColumn.getActualWidth();

    const gridSpaceLeftToFill = Math.max(0, gridWidth - (lastColumnLeft! + lastColumnWidth + 20));

    if (gridSpaceLeftToFill === 0) return;

    const columnState = gridColumnApi.getColumnState();

    columnState[columnState.length - 1].width =
      columnState[columnState.length - 1].width! + gridSpaceLeftToFill;

    gridColumnApi.applyColumnState({ state: columnState });
  }, [containerRef, gridRef]);

  /** re-run when dataset changes and grid column rerenders */
  useEffect(() => {
    setTimeout(resizeColumns, 1);
  }, [resizeColumns, gridRowData]);

  return { resizeColumns };
};
