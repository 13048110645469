import { FC, PropsWithChildren, createContext, useContext } from 'react';
import initialMetricsPublisher from 'src/metrics';

interface MetricsContextType {
  publishCounter(metricName: string, counterName: string, value: number): void;
  publishTime(metricName: string, timer: number): void;
}

const MetricsContext = createContext<MetricsContextType>({
  publishCounter(): void {},
  publishTime(): void {},
});

export const MetricsProvider: FC<PropsWithChildren> = (props) => {
  const publishCounter = (metricName: string, counterName: string, value: number) => {
    initialMetricsPublisher
      .newChildActionPublisherForMethod(metricName)
      .publishCounterMonitor(counterName, value);
  };

  const publishTime = (metricName: string, timer: number) => {
    initialMetricsPublisher
      .newChildActionPublisherForMethod(metricName)
      .publishTimerMonitor('latency', timer);
  };

  return (
    <MetricsContext.Provider value={{ publishCounter, publishTime }}>
      {props.children}
    </MetricsContext.Provider>
  );
};

/**
 * React hook to use metrics context
 */
export const useMetrics = () => useContext(MetricsContext);
