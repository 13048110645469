import { useState } from 'react';
import { ButtonDropdown } from '@amzn/awsui-components-react/polaris';
import { ButtonDropdownProps } from '@amzn/awsui-components-react/polaris/button-dropdown';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useBatchMetadataMutation } from 'src/api/mutation/useBatchMetadataMutation';
import { BatchStatus, PlanningCycleId, PlanTypeId } from 'src/utils/planning/planetModel';
import DeletePlanModal from 'src/pages/commons/plan-actions/DeletePlanModal';
import ClonePlanModal from 'src/pages/commons/plan-actions/ClonePlanModal';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useIsPlanPolling } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';

interface PlanActionsButtonDropdownProps {
  batchId: string | undefined;
}

const PlanActionsButtonDropdown = ({ batchId }: PlanActionsButtonDropdownProps) => {
  const { data: batchMetadata, isLoading: batchMetadataIsLoading } = useBatchMetadata({ batchId });

  const { t } = useTranslation();

  const { isPlanPolling } = useIsPlanPolling(batchMetadata);

  const { mutate: lockPlanMutation } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_lock_plan'),
      errorNotificationText: t('api_error_lock_plan'),
      successNotificationText: t('api_success_lock_plan'),
    },
  });

  const { mutate: unlockPlanMutation } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_unlock_plan'),
      errorNotificationText: t('api_error_unlock_plan'),
      successNotificationText: t('api_success_unlock_plan'),
    },
  });

  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  enum PlanActionsDropDownItems {
    CLONE = 'clone_action_item',
    UNLOCK_FOR_EDITING = 'unlock_for_editing_action_item',
    LOCK_FOR_EDITING = 'lock_for_editing_action_item',
    DELETE = 'delete_action_item',
  }

  const createPlanActionDropdownItems: (
    batchMetadata: IBatchMetadataParsed,
  ) => ButtonDropdownProps.Items = (batchMetadata: IBatchMetadataParsed) => [
    {
      text: t(PlanActionsDropDownItems.CLONE),
      id: PlanActionsDropDownItems.CLONE,
      disabled:
        batchMetadata.planCycle === PlanningCycleId.Cycle3YF &&
        batchMetadata.costType === PlanTypeId.REAL_ESTATE,
    },
    {
      text: t(PlanActionsDropDownItems.LOCK_FOR_EDITING),
      id: PlanActionsDropDownItems.LOCK_FOR_EDITING,
      disabled: batchMetadataIsLoading || batchMetadata.status !== BatchStatus.OPEN,
    },
    {
      text: t(PlanActionsDropDownItems.UNLOCK_FOR_EDITING),
      id: PlanActionsDropDownItems.UNLOCK_FOR_EDITING,
      disabled: batchMetadataIsLoading || batchMetadata.status !== BatchStatus.CLOSED,
    },
    {
      text: t(PlanActionsDropDownItems.DELETE),
      id: PlanActionsDropDownItems.DELETE,
    },
  ];

  const handleDropDownMenuSelect = (
    value: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
    batchMetadata: IBatchMetadataParsed | undefined,
  ) => {
    if (!batchMetadata) {
      return;
    }
    const selectedId = value.detail.id;

    switch (selectedId) {
      case PlanActionsDropDownItems.LOCK_FOR_EDITING: {
        const newBatchMetadata = cloneDeep(batchMetadata);
        newBatchMetadata.status = BatchStatus.CLOSED;
        lockPlanMutation(newBatchMetadata);
        break;
      }
      case PlanActionsDropDownItems.UNLOCK_FOR_EDITING: {
        const newBatchMetadata = cloneDeep(batchMetadata);
        newBatchMetadata.status = BatchStatus.OPEN;
        unlockPlanMutation(newBatchMetadata);
        break;
      }
      case PlanActionsDropDownItems.DELETE: {
        setShowDeleteModal(true);
        break;
      }
      case PlanActionsDropDownItems.CLONE: {
        setShowCloneModal(true);
        break;
      }
    }
  };

  const disabled = !batchMetadata || isPlanPolling;

  return (
    <div>
      <ButtonDropdown
        loading={!!(!batchMetadata && batchId)}
        disabled={disabled}
        items={batchMetadata ? createPlanActionDropdownItems(batchMetadata) : []}
        onItemClick={(event) => {
          handleDropDownMenuSelect(event, batchMetadata);
        }}
      >
        {t('plan_actions_button')}
      </ButtonDropdown>
      <DeletePlanModal
        batchId={batchId}
        visible={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
      <ClonePlanModal
        batchMetadata={batchMetadata}
        visible={showCloneModal}
        setShowModal={setShowCloneModal}
      />
    </div>
  );
};

export default PlanActionsButtonDropdown;
