import { Flashbar } from '@amzn/awsui-components-react/polaris';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { useIsFetching } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from 'src/hooks/useNotificationContext';

interface GlobalNotificationsProps {
  additionalNotifications?: FlashbarProps.MessageDefinition[];
}

/**
 * Common react component for Notifications
 */
export const GlobalNotifications = ({ additionalNotifications = [] }: GlobalNotificationsProps) => {
  const { t } = useTranslation();
  const isFetching = useIsFetching();
  const { notifications } = useNotificationContext();

  const globalNotifications = useMemo<FlashbarProps.MessageDefinition[]>(() => {
    const isFetchingNotification: FlashbarProps.MessageDefinition = {
      type: 'in-progress',
      dismissible: false,
      loading: true,
      content: t('api_in_progress_load_data'),
    };

    const newNotifications = [
      ...additionalNotifications,
      ...(isFetching ? [isFetchingNotification] : []),
      ...notifications,
    ];

    const dismissibleNotifications = newNotifications.filter((n) => n.dismissible);
    const nonDismissibleNotifications = newNotifications.filter((n) => !n.dismissible);

    return [...nonDismissibleNotifications, ...dismissibleNotifications];
  }, [additionalNotifications, isFetching, notifications, t]);

  return (
    <div style={{ position: 'absolute', top: 5, zIndex: 1005, width: '100%' }}>
      <Flashbar items={globalNotifications} />
    </div>
  );
};
