import DatasetTabsViewer from 'src/pages/commons/plan-views/DatasetTabsViewer';
import PlanInputRowValidationGrid from 'src/pages/plan-manager-page/plan-input-tab/PlanInputRowValidationGrid';
import {
  getInputDatasetOptions,
  getInputSubTabs,
} from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';

const PlanInputTab = () => (
  <DatasetTabsViewer
    getSubTabs={getInputSubTabs}
    getDatasetOptions={getInputDatasetOptions}
    renderGridComponent={(o) => <PlanInputRowValidationGrid {...o} key={o.currentDataset} />}
  />
);

export default PlanInputTab;
