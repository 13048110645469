import { ListBatchesResponse } from '@amzn/fox-den-cost-planning-lambda';
import { QueryClient, UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import {
  QUERY_KEYS,
  RequestVariables,
  useQueriesWithNotifications,
  useQueryWithNotifications,
  UseQueryWithNotificationsOptions,
} from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { ListPlanFilterType } from 'src/utils/planning/planetModel';

export interface ListBatchesOptions {
  requesterIdentity: string;
  filterList: {
    costType: string[];
    businessGroup: string[];
    subGroup: string[];
    region: string[];
    batchStatus?: string[];
  };
  filterType?: ListPlanFilterType;
  editingPlanId?: string;
}

export interface ListBatchesOptionsKeyFilters {
  costType: string[];
  businessGroup: string[];
  subGroup: string[];
  region: string[];
}

interface IUseBatchesListSettings {
  disabled?: boolean;
  refetchInterval?: number;
  staleTime?: number;
}

export const useListBatchesQueries = (
  inputsForParallelQueries: RequestVariables<ListBatchesOptions>[],
  settings: IUseBatchesListSettings = {},
  alias: string,
): UseQueryWithNotificationsOptions<ListBatchesResponse>[] => {
  const { t } = useTranslation();

  return useMemo<UseQueryWithNotificationsOptions<ListBatchesResponse>[]>(
    () =>
      inputsForParallelQueries.map((input) => {
        const keyFilters: ListBatchesOptionsKeyFilters = {
          costType: input.filterList.costType,
          businessGroup: input.filterList.businessGroup,
          subGroup: input.filterList.subGroup,
          region: input.filterList.region,
        };

        return {
          queryKey: [QUERY_KEYS.BATCH_LIST, keyFilters, alias, input],
          queryFn: () =>
            FoxPlanningApi.listBatches({
              ...input,
              requesterIdentity: alias,
            }),
          meta: { errorNotificationText: t('api_error_fetch_plan_list') },
          enabled: !settings.disabled,
          refetchInterval: settings.refetchInterval ?? false,
          ...(settings?.staleTime !== undefined ? { staleTime: settings.staleTime } : {}),
        };
      }),
    [
      inputsForParallelQueries,
      alias,
      t,
      settings.disabled,
      settings.refetchInterval,
      settings.staleTime,
    ],
  );
};

export const useBatchesList = (
  input: RequestVariables<ListBatchesOptions>,
  settings: IUseBatchesListSettings = {},
): UseQueryResult<ListBatchesResponse, unknown> => {
  const { alias } = useUserAlias();
  const [query] = useListBatchesQueries([input], settings, alias);
  return useQueryWithNotifications({ ...query });
};

export const useBatchesListParallel = (
  inputs: RequestVariables<ListBatchesOptions>[],
  settings: IUseBatchesListSettings = {},
) => {
  const { alias } = useUserAlias();
  const queries = useListBatchesQueries(inputs, settings, alias);
  return useQueriesWithNotifications({ queries }) as UseQueryResult<ListBatchesResponse, unknown>[];
};

export const prefetchGetBatchesList = (
  queryClient: QueryClient,
  alias: string,
  input: RequestVariables<ListBatchesOptions>,
) => {
  const keyFilters: ListBatchesOptionsKeyFilters = {
    costType: input.filterList.costType,
    businessGroup: input.filterList.businessGroup,
    subGroup: input.filterList.subGroup,
    region: input.filterList.region,
  };
  // The results of this query will be cached like a normal query
  queryClient.prefetchQuery({
    queryKey: [QUERY_KEYS.BATCH_LIST, keyFilters, alias, input],
    queryFn: () =>
      FoxPlanningApi.listBatches({
        ...input,
        requesterIdentity: alias,
      }),
  });
};
