import { ColDef } from 'ag-grid-enterprise';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import {
  IMappingSchemaColumnList,
  ITableDataSchemaColumnList,
} from 'src/utils/planning/batchMetadataModel';

// Headers that have a hardcoded replacement
const specialHeaders: Record<string, string> = {
  useful_life: 'Useful Life in Months',
  lease_term: 'Lease Term in Months',
  su_useful_life: 'SU Useful Life in Months',
  re_useful_life: 'RE Useful Life in Months',
};

// Header words that have a hardcoded capitalization style
const specialTokens: Record<string, string> = {
  id: 'ID',
  isd: 'ISD',
  su: 'SU',
  re: 'RE',
  car: 'CAR',
  rou: 'ROU',
  aro: 'ARO',
  gl: 'GL',
  sqft: 'sqft',
  capex: 'CapEx',
  ctl: 'CTL',
  npv: 'NPV',
  coa: 'COA',
  tco: 'TCO',
  or: 'or',
  uid: 'UID',
  lsa: 'LSA',
  oem: 'OEM',
  usd: 'USD',
};

const convertFieldToHeader = (field: string) => {
  if (field in specialHeaders) {
    return specialHeaders[field];
  }

  return field
    .toLowerCase()
    .split('_')
    .map((token) => {
      if (token in specialTokens) {
        return specialTokens[token];
      }
      return capitalize(token);
    })
    .join(' ');
};

export const useColumnDefs = (
  columnList: ITableDataSchemaColumnList | undefined,
  mappingColumnList: IMappingSchemaColumnList | undefined,
) => {
  const { data: featureFlags } = useFeatureFlags();

  const columnDefs = useMemo<ColDef[] | null>(() => {
    if (!columnList) return null;

    const tableConfig: ColDef[] = columnList
      .map((item, index) => {
        const mappingItem = mappingColumnList?.find((m) => m.originalName === item.dimensionName);

        const field = item.dimensionName;

        const headerName = featureFlags?.feUseColumnMappingSchemaConfig
          ? convertFieldToHeader(mappingItem?.displayName || item.dimensionName)
          : convertFieldToHeader(item.dimensionName);

        const hide = featureFlags?.feUseColumnMappingSchemaConfig ? mappingItem?.hide : false;

        const displayOrder = featureFlags?.feUseColumnMappingSchemaConfig
          ? mappingItem?.displayOrder ?? index
          : index;

        return { field, headerName, hide, displayOrder };
      })
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(({ field, headerName, hide }, index) => ({
        field,
        headerName,
        hide,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        ...(index === 0 ? { checkboxSelection: true, headerCheckboxSelection: true } : {}),
      }));

    return tableConfig;
  }, [columnList, featureFlags?.feUseColumnMappingSchemaConfig, mappingColumnList]);

  return { columnDefs };
};
