import { Box, Button, Modal, SpaceBetween, Table } from '@amzn/awsui-components-react/polaris';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { downloadFile } from 'src/utils/errorMessage';

/**
 * This component render error modal
 */
const GlobalErrorModal: FC = () => {
  const { t } = useTranslation();
  const { globalErrorModal, clearGlobalErrorModal } = useNotificationContext();

  if (!globalErrorModal) return null;

  return (
    <Modal
      size="large"
      header={t('error_modal_title')}
      onDismiss={clearGlobalErrorModal}
      footer={
        globalErrorModal.downloadLink ? (
          <Box float="right">
            <Button onClick={() => downloadFile(globalErrorModal.downloadLink!)} variant="normal">
              {t('download')}
            </Button>
          </Box>
        ) : null
      }
      visible={!!globalErrorModal}
    >
      <SpaceBetween direction="vertical" size="s">
        <span>{globalErrorModal.message}</span>
        {globalErrorModal.table ? (
          <Table
            items={globalErrorModal.table.items}
            columnDefinitions={globalErrorModal.table.columnDefinitions}
            wrapLines
          />
        ) : null}
      </SpaceBetween>
    </Modal>
  );
};

export default GlobalErrorModal;
