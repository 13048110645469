import styles from './styles.module.scss';

interface SeparatedListProps {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  items: React.ReactNode[];
}

export function SeparatedList({ ariaLabel, ariaLabelledBy, items }: SeparatedListProps) {
  return (
    <ul aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} className={styles.root}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}
