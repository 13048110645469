import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

export const useQueryParams = <T extends Record<string, string>>() => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search).entries()) as T,
    [search],
  );

  const setQueryParams = useCallback(
    (newQueryParams: T) => {
      navigate({ search: createSearchParams(newQueryParams).toString() }, { replace: true });
    },
    [navigate],
  );

  return { queryParams, setQueryParams };
};
