import {
  ComputeBatchDataRequest,
  ComputeBatchDataResponse,
} from '@amzn/fox-den-cost-planning-lambda';
import { MutateOptions, useQueryClient } from '@tanstack/react-query';
import { isArray, isObject, isString } from 'lodash';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import {
  MutationHookOptions,
  MutationNotificationsMeta,
  QUERY_KEYS,
  RequestVariables,
  useMutationWithNotifications,
} from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

export type TriggerComputeMutationVariables = RequestVariables<ComputeBatchDataRequest>;

export type TriggerComputeMutationHookOptions = MutationHookOptions<
  ComputeBatchDataRequest,
  ComputeBatchDataResponse
>;

export type TriggerComputeMutationOptions = MutateOptions<
  ComputeBatchDataResponse,
  unknown,
  TriggerComputeMutationVariables,
  unknown
>;

export const useTriggerComputeMutation = (
  options: TriggerComputeMutationHookOptions & MutationNotificationsMeta,
) => {
  const queryClient = useQueryClient();
  const { alias } = useUserAlias();

  return useMutationWithNotifications({
    ...options,
    mutationFn: (input) =>
      FoxPlanningApi.computeBatchData({
        ...input,
        batchVersion: '0',
        requesterIdentity: alias,
      }),
    onSettled(data, error, variables, context) {
      if (!options.onSettled) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BATCH_METADATA, variables.batchId] });
      }
      options?.onSettled?.(data, error, variables, context);
    },
  });
};

export const buildComputeParams = (rawParams: Record<any, unknown> | ArrayLike<unknown>) => {
  const computeParams: Record<string, any[]> = {};

  for (const [key, value] of Object.entries(rawParams)) {
    if (isArray(value)) {
      computeParams[key] = (value as any[]).map((elem) => {
        if (isString(elem)) return elem;
        return JSON.stringify(elem);
      });
    } else if (isString(value)) {
      computeParams[key] = [value];
    } else if (isObject(value)) {
      computeParams[key] = [JSON.stringify(value)];
    }
  }

  return computeParams;
};
