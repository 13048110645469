import { throttle } from 'lodash';
import { RefObject, useCallback, useEffect, useState } from 'react';

export const useAgGridCalculatedHeight = (
  containerRef: RefObject<HTMLDivElement>,
  gridBottomHeight: number,
  isShowingFilterComponent: boolean,
) => {
  const [gridHeight, setGridHeight] = useState('0px');

  const updateGridHeight = useCallback(() => {
    if (!containerRef?.current) return;
    const gridContainerTop = containerRef.current.getBoundingClientRect().top;
    const windowInnerHeight = window.innerHeight;
    setGridHeight(`${windowInnerHeight - gridContainerTop - gridBottomHeight}px`);
  }, [containerRef, gridBottomHeight]);

  /** update grid height when hook initializes or isShowingFilterComponent changes */
  useEffect(() => {
    if ([true, false].includes(isShowingFilterComponent)) updateGridHeight();
  }, [isShowingFilterComponent, updateGridHeight]);

  /** update grid height when window size changes */
  useEffect(() => {
    const throttledUpdateHeight = throttle(updateGridHeight, 500);
    window.addEventListener('resize', throttledUpdateHeight);
    return () => {
      window.removeEventListener('resize', throttledUpdateHeight);
    };
  }, [updateGridHeight]);

  return { gridHeight };
};
