import { TreeView } from 'devextreme-react/tree-view';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { useEffect, useRef, useState } from 'react';
import './treeSelect.css';
import { ISingleTreeDataSource } from 'src/common/ag-grid/editors/ag-tree-select/SingleTreeSelect';

export interface MultiTreeSelectProps {
  disabled: any;
  treeDataSource: ISingleTreeDataSource[] | any;
  selectedOptions: any[];
  dropDownOptions: Record<any, any>;
  setSelectedOptions: (newSelectedOptions: any[]) => void;
  searchEnabled?: boolean;
  selectAll?: boolean;
  selectAllUnselectsAll?: boolean;
}

const MultiTreeSelect = (props: MultiTreeSelectProps) => {
  const {
    disabled,
    treeDataSource,
    dropDownOptions = {},
    selectedOptions,
    setSelectedOptions,
    searchEnabled = false,
    selectAll = true,
    selectAllUnselectsAll = false,
  } = props;

  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(true);
  const dropdownBoxRef = useRef(null as any);
  const treeViewRef = useRef(null as any);

  const onTreeBoxOpened = (event: any) => {
    if (event.name === 'opened') {
      setIsTreeBoxOpened(event.value);
    }
  };

  const onItemSelectionChanged = (event: any) => {
    // When search is enabled, getSelectedNodeKeys() wouldn't return the correct values, so we use treeDataSource as an alternative source
    if (searchEnabled) {
      setSelectedOptions(
        treeDataSource.filter((tree: any) => tree.selected).map((tree: any) => tree.id),
      );
    } else {
      setSelectedOptions(event.component.getSelectedNodeKeys());
    }
  };

  const syncTreeViewSelection = (event: any) => {
    const treeView =
      (event.component.selectItem && event.component) || treeViewRef?.current?.instance;

    if (treeView) {
      if (event.value === null) {
        treeView.unselectAll();
      } else {
        const values = event.value || selectedOptions;

        values?.forEach((value: any) => {
          treeView.selectItem(value);
        });
      }
    }

    if (event.value !== undefined) {
      setSelectedOptions(event.value);
    }
  };

  const multipleTreeViewRender = () => {
    const onSelectAllValueChanged = (event: any) => {
      if (event.value === true) {
        if (selectAllUnselectsAll) {
          setSelectedOptions([]);
        } else {
          setSelectedOptions(event.component.getNodes().map((item: any) => item.key));
        }
      } else if (event.value === false) {
        setSelectedOptions([]);
      }
    };

    return (
      <TreeView
        ref={treeViewRef}
        dataSource={treeDataSource}
        dataStructure="plain"
        keyExpr="id"
        parentIdExpr="parentId"
        selectionMode="multiple"
        displayExpr="name"
        selectNodesRecursive={false}
        selectByClick={true}
        onItemSelectionChanged={onItemSelectionChanged}
        onContentReady={syncTreeViewSelection}
        searchEnabled={searchEnabled}
        showCheckBoxesMode={selectAll ? 'selectAll' : 'normal'}
        onSelectAllValueChanged={selectAll ? onSelectAllValueChanged : undefined}
      />
    );
  };

  useEffect(() => {
    if (disabled === true) {
      setSelectedOptions([]);
    }
  }, [disabled, setSelectedOptions]);

  return (
    <DropDownBox
      ref={dropdownBoxRef}
      disabled={disabled}
      opened={isTreeBoxOpened}
      value={selectedOptions}
      valueExpr="id"
      displayExpr="name"
      showClearButton={false}
      dataSource={treeDataSource}
      onOptionChanged={onTreeBoxOpened}
      onValueChanged={syncTreeViewSelection}
      contentRender={multipleTreeViewRender}
      height="100%"
      dropDownOptions={{ minWidth: '250px', ...dropDownOptions }}
    />
  );
};

export default MultiTreeSelect;
