import { TopNavigation } from '@amzn/awsui-components-react/polaris';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PlanetLogo from '../../public/amazon-planet-logo.png';
import DarkModeDark from '../../public/darkmodeDark.svg';
import DarkModeLight from '../../public/darkmodeLight.svg';
import HelpImage from '../../public/help.png';
import RiverImage from '../../public/river.png';
import { useDarkModeContext } from 'src/hooks/useDarkModeContext';
import { PLANET_URL } from 'src/common/URL';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { PathNames } from 'src/routes';
import FinergyFloatingMenu from 'src/common/FinergyFloatingMenu';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

const topNavigationDataId = 'top-navigation';
export const appLayoutHeaderSelector = `#${topNavigationDataId}`;

/**
 * React component to render common top navigation
 */
export const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alias } = useUserAlias();
  const { isDarkMode, toggleDarkMode } = useDarkModeContext();

  const handleClickLogo = (event: any) => {
    event?.preventDefault();
    navigate(PathNames.LANDING_PAGE);
  };

  const { data: featureFlags } = useFeatureFlags();

  return (
    <>
      <div style={{ position: 'sticky', top: 0, zIndex: 1003 }}>
        <FinergyFloatingMenu iconTopMargin={15} iconLeftMargin={15} />
      </div>

      {/* Style following this guideline: https://cloudscape.aws.dev/components/top-navigation/?tabId=api#development-guidelines */}
      <div id={topNavigationDataId} style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
        <TopNavigation
          identity={{
            onFollow: handleClickLogo,
            href: PathNames.LANDING_PAGE,
            logo: { src: PlanetLogo, alt: 'logo' },
            title: featureFlags?.feShowStage ? 'beta'.toUpperCase() : '',
          }}
          utilities={[
            {
              type: 'button',
              onClick: () => {
                toggleDarkMode();
              },
              iconUrl: isDarkMode ? DarkModeLight : DarkModeDark,
            },
            {
              type: 'button',
              iconName: 'folder',
              iconUrl: RiverImage,
              href: PLANET_URL.RIVER_URL,
              target: '_blank',
            },
            {
              type: 'menu-dropdown',
              iconUrl: HelpImage,
              items: [
                {
                  id: 'contact-us',
                  text: `${t('contact_us')}`,
                  href: PLANET_URL.CONTACT_URL,
                  external: true,
                },
                {
                  id: 'view-our-wiki',
                  text: `${t('view_wiki')}`,
                  href: PLANET_URL.WIKI_URL,
                  external: true,
                },
              ],
            },
            {
              type: 'button',
              text: alias,
              iconName: 'user-profile',
              href: `${PLANET_URL.PHONE_TOOL_URL}/${alias}`,
              external: true,
            },
          ]}
          i18nStrings={{
            searchIconAriaLabel: 'Search',
            searchDismissIconAriaLabel: 'Close search',
            overflowMenuTriggerText: 'More',
            overflowMenuTitleText: 'All',
            overflowMenuBackIconAriaLabel: 'Back',
            overflowMenuDismissIconAriaLabel: 'Close menu',
          }}
        />
      </div>
    </>
  );
};
