import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

/**
 * Define query key constants
 */
export const QUERY_KEYS = {
  BATCH_METADATA: 'planet-batch-metadata',
  BATCH_LIST: 'planet-batch-list',
  PLAN_DATASET: 'planet-plan-dataset',
  BATCH_DASHBOARD: 'planet-batch-dashboard',
  DIMENSION_BUSINESS_RULES: 'planet-dimension-business-rules',
  DIMENSION_UPSERT_DATASETS: 'planet-dimension-upsert-datasets',
  DIMENSION_STATIC_FILTER_LIST: 'planet-dimension-static-filter-list',
  DIMENSION_PORTFOLIO_IDS: 'planet-dimension-portfolio-ids',
  DIMENSION_SCENARIOS: 'planet-dimension-scenarios',
  FEATURE_FLAGS: 'planet-feature-flags',
};

export type RequestVariables<TVariables> = Omit<TVariables, 'requesterIdentity' | 'batchVersion'>;

export type MutationHookOptions<TVariables, TData> = Omit<
  UseMutationOptions<TData, unknown, RequestVariables<TVariables>, unknown>,
  'mutationFn' | 'meta'
>;

export type QueryNotifications = {
  /** required keys */
  [key in 'errorNotificationText']: string;
};

interface QueryNotificationsMeta {
  meta: QueryNotifications;
}

export type UseQueryWithNotificationsOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & QueryNotificationsMeta;

export const useQueryWithNotifications = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryWithNotificationsOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> => useQuery({ ...options });

export const useQueriesWithNotifications = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(params: {
  queries: UseQueryWithNotificationsOptions<TQueryFnData, TError, TData, TQueryKey>[];
  context?: UseQueryOptions['context'];
}) => useQueries({ queries: params.queries, context: params.context });

export type MutationNotifications = {
  /** required keys */
  [key in 'inProgressNotificationText' | 'errorNotificationText']: string;
} & {
  /** optional keys */
  successNotificationText?: string;
};

export interface MutationNotificationsMeta {
  meta: MutationNotifications;
}

export type UseMutationWithNotificationsOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext> & MutationNotificationsMeta;

export const useMutationWithNotifications = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationWithNotificationsOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> => useMutation({ ...options });
