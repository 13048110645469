import {
  Container,
  FormField,
  Header,
  Multiselect,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { useTranslation } from 'react-i18next';
import {
  getAllowedCountries,
  PlanCreateFieldIds,
  PlanCreateFields,
} from 'src/pages/create-plan-page/createPlanBusinessLogic';
import { PlanTypeId } from 'src/utils/planning/planetModel';
import { useGetBusinessGroupFilters } from 'src/utils/planning/supportedBusinessGroups';

interface ICreatePlanGeneralPanelProps {
  formContent: PlanCreateFields;
  onUpdateFormField: (fieldId: PlanCreateFieldIds, value: any) => void;
}

export const CreatePlanGeneralPanel = ({
  formContent,
  onUpdateFormField,
}: ICreatePlanGeneralPanelProps) => {
  const { t } = useTranslation();

  const { getBusinessGroupFilters } = useGetBusinessGroupFilters();

  const { allowedPlanTypes, allowedBusinessGroups, allowedSubGroups, allowedRegions } =
    getBusinessGroupFilters({
      planType: formContent.planType,
      businessGroup: formContent.businessGroup,
      subGroup: formContent.subGroup,
      region: formContent.region,
    });

  const allowedCountries = getAllowedCountries(formContent);

  const planGeneralFields = [
    {
      identifier: PlanCreateFieldIds.PLAN_TYPE,
      content: (
        <FormField label={t('plan_type_label')}>
          <Select
            selectedOption={formContent.planType}
            options={allowedPlanTypes}
            onChange={(e) => {
              onUpdateFormField(PlanCreateFieldIds.PLAN_TYPE, e.detail.selectedOption);
            }}
            placeholder={t('plan_type_placeholder')}
          />
        </FormField>
      ),
    },
    {
      identifier: PlanCreateFieldIds.BUSINESS_GROUP,
      content: (
        <FormField label={t('business_group_label')}>
          <Select
            selectedOption={formContent.businessGroup}
            options={allowedBusinessGroups}
            onChange={(e) => {
              onUpdateFormField(PlanCreateFieldIds.BUSINESS_GROUP, e.detail.selectedOption);
            }}
            disabled={formContent.planType?.value === PlanTypeId.CF_CONSOLIDATION}
            placeholder={t('business_group_placeholder')}
          />
        </FormField>
      ),
    },
    {
      identifier: PlanCreateFieldIds.SUB_GROUP,
      content: (
        <FormField label={t('sub_group_label')}>
          <Select
            selectedOption={formContent.subGroup}
            options={allowedSubGroups}
            onChange={(e) => {
              onUpdateFormField(PlanCreateFieldIds.SUB_GROUP, e.detail.selectedOption);
            }}
            disabled={formContent.planType?.value === PlanTypeId.CF_CONSOLIDATION}
            placeholder={t('sub_group_placeholder')}
          />
        </FormField>
      ),
    },
    {
      identifier: PlanCreateFieldIds.REGION,
      content: (
        <FormField label={t('region_label')}>
          <Select
            selectedOption={formContent.region}
            options={allowedRegions}
            onChange={(e) => {
              onUpdateFormField(PlanCreateFieldIds.REGION, e.detail.selectedOption);
            }}
            disabled={formContent.planType?.value === PlanTypeId.CF_CONSOLIDATION}
            placeholder={t('region_placeholder')}
          />
        </FormField>
      ),
    },
    {
      identifier: PlanCreateFieldIds.COUNTRIES,
      content: (
        <FormField label={t('countries_label')}>
          {formContent.planType?.value === PlanTypeId.TOPS_DOWN_FORECAST ? (
            <Select
              selectedOption={formContent.countries[0]}
              options={allowedCountries}
              disabled={allowedCountries.length === 0}
              onChange={(e) => {
                onUpdateFormField(PlanCreateFieldIds.COUNTRIES, [e.detail.selectedOption]);
              }}
              placeholder={t('countries_placeholder')}
            />
          ) : (
            <Multiselect
              selectedOptions={formContent.countries}
              options={allowedCountries}
              disabled={
                allowedCountries.length === 0 ||
                formContent.planType?.value === PlanTypeId.CF_CONSOLIDATION
              }
              onChange={(e) => {
                onUpdateFormField(PlanCreateFieldIds.COUNTRIES, e.detail.selectedOptions);
              }}
              placeholder={t('countries_placeholder')}
            />
          )}
        </FormField>
      ),
    },
  ];

  return (
    <Container header={<Header>{t('general_plan_settings_header')}</Header>}>
      <SpaceBetween size="l">
        {planGeneralFields.map((formField) => (
          <div key={formField.identifier}>{formField.content}</div>
        ))}
      </SpaceBetween>
    </Container>
  );
};
