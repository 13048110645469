import { Spinner } from '@amzn/awsui-components-react/polaris';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import { FC, PropsWithChildren, Suspense, useEffect } from 'react';
import { envConfig } from '../../configuration/aws-exports';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const configure = async () => {
      try {
        Amplify.configure(envConfig);
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.error(error);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
      }
    };
    configure();
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Authenticator.Provider>{children}</Authenticator.Provider>
    </Suspense>
  );
};
