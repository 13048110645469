import { API } from 'aws-amplify';

export class Api {
  apiName: string;
  constructor(apiName: string) {
    this.apiName = apiName;
  }

  /**
   * This method will create data.
   */
  post(path: string, payload: any) {
    return API.post(this.apiName, path, {
      body: payload,
    })
      .then((response) => response)
      .catch((error) => {
        console.dir(error);
        throw new Error(error.response?.data?.message || error.response?.message);
      });
  }

  /**
   * This method will update data.
   */
  put(path: string, payload: any) {
    return API.put(this.apiName, path, {
      body: payload,
    });
  }
}
