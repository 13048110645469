import { Navigate, RouteObject } from 'react-router-dom';
import RootPage from './pages/root-page/RootPage';
import LandingPage from 'src/pages/landing-page/LandingPage';
import ListPlansPage from 'src/pages/list-plans-page/ListPlansPage';
import PlanManagerPage from 'src/pages/plan-manager-page/PlanManagerPage';
import PlanDetailsTab from 'src/pages/plan-manager-page/plan-details-tab/PlanDetailsTab';
import PlanInputTab from 'src/pages/plan-manager-page/plan-input-tab/PlanInputTab';
import CreatePlanPage from 'src/pages/create-plan-page/CreatePlanPage';
import PlanOutputTab from 'src/pages/plan-manager-page/plan-output-tab/PlanOutputTab';
import PlanAnalysisTab from 'src/pages/plan-manager-page/plan-analysis-tab/PlanAnalysisTab';
import PlanBaselineTab from 'src/pages/plan-manager-page/plan-baseline-tab/PlanBaselineTab';
import ProcessWorkflow from 'src/pages/plan-manager-page/plan-process-flow-tab/ProcessFlow';

export enum PathNames {
  LANDING_PAGE = '/',
  LIST_PLANS_PAGE = '/plans',
  CREATE_PLAN_PAGE = '/plans/create',
  PLAN_MANAGER_PAGE = '/plans/manager/:batchId',
  PLAN_MANAGER_DETAILS_TAB = '/plans/manager/:batchId/details',
  PLAN_MANAGER_PROCESS_FLOW_TAB = '/plans/manager/:batchId/process-flow',
  PLAN_MANAGER_BASELINE_TAB = '/plans/manager/:batchId/baseline',
  PLAN_MANAGER_INPUT_TAB = '/plans/manager/:batchId/input',
  PLAN_MANAGER_OUTPUT_TAB = '/plans/manager/:batchId/output',
  PLAN_MANAGER_ANALYSIS_TAB = '/plans/manager/:batchId/analysis',
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootPage />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'plans',
        element: <ListPlansPage />,
      },
      {
        path: 'plans/*',
        element: <Navigate to="/plans" replace />,
      },
      {
        path: 'plans/create',
        element: <CreatePlanPage />,
      },
      {
        path: 'plans/manager/:batchId',
        element: <PlanManagerPage />,
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: '*',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <PlanDetailsTab />,
          },
          {
            path: 'process-flow',
            element: <ProcessWorkflow />,
          },
          {
            path: 'baseline',
            element: <PlanBaselineTab />,
          },
          {
            path: 'input',
            element: <PlanInputTab />,
          },
          {
            path: 'output',
            element: <PlanOutputTab />,
          },
          {
            path: 'analysis',
            element: <PlanAnalysisTab />,
          },
        ],
      },
    ],
  },
];

export const getPlanManagerPageRoute = (batchId: string) =>
  PathNames.PLAN_MANAGER_PAGE.replace(':batchId', batchId);

export const getPlanDetailsRoute = (batchId: string) =>
  PathNames.PLAN_MANAGER_DETAILS_TAB.replace(':batchId', batchId);
