import { useEffect } from 'react';

export const useOnBeforeUnload = (blockUnload: boolean) => {
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };

    if (blockUnload) window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [blockUnload]);
};
