import { isEmpty } from 'lodash';

/**
 * Parse the payload to a Javascript Object, check the first parameter for potential errors.
 * This method gurantees an object if succeeded, for example:
 * safeJsonParse(null) -> [null, {}]
 */
export const safeJsonParse = (payload: any) => {
  if (typeof payload === 'string') {
    try {
      return [null, JSON.parse(payload)];
    } catch (err) {
      return [err, {}];
    }
  } else if (typeof payload === 'object') {
    return [null, payload || {}];
  } else {
    return [`Unsupported type ${typeof payload}`, {}];
  }
};

/**
 * Parse the payload to a Javascript Object, and logs any parsing errors
 * This method gurantees an object, even if the parse failed
 */
export const safeJsonParseObjectOnly = (payload: any): any => {
  const [parseError, parsedData] = safeJsonParse(payload);
  if (parseError) console.error(parseError.message);
  return parsedData;
};

export const jsonStringifyNullIfEmpty = (payload: any) => {
  if (isEmpty(payload)) {
    return null;
  }
  return JSON.stringify(payload);
};
