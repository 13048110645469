import { GetDashboardUrlRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, RequestVariables, useQueryWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

interface IUseBatchDashboardSettings {
  disabled?: boolean;
}

export const useBatchDashboard = (
  input: RequestVariables<GetDashboardUrlRequest>,
  settings: IUseBatchDashboardSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.BATCH_DASHBOARD, input, alias],
    queryFn: () =>
      FoxPlanningApi.getDashboardUrl({
        ...input,
        requesterIdentity: alias,
      }),
    meta: { errorNotificationText: t('api_error_fetch_plan_dashboard') },
    enabled: !settings.disabled,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return query;
};
