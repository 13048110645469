/* eslint-disable @typescript-eslint/no-use-before-define */
import { Container, Header } from '@amzn/awsui-components-react/polaris';
import {
  CellEditingStoppedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  SuppressKeyboardEventParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { isArray, isEmpty, isEqual, get as lodashGet } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiImport, BiRefresh } from 'react-icons/bi';
import { BsFillArchiveFill } from 'react-icons/bs';
import { FaRegClone, FaRegSave } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import AgCustomTooltip from 'src/common/ag-grid/editable-datagrid/AgCustomTooltip';
import {
  ICustomizedAgGridColumnConfig,
  agCellValueFormatter,
  agEditsCloning,
  agEditsDeleting,
  agEditsUpdating,
  cellValueFormatter,
  getCellValue,
  normalizeAgDataSource,
  setCellValue,
} from 'src/common/ag-grid/editable-datagrid/AgGridConfigHelper';
import {
  editorToValidationFnMap,
  requiredValidationFn,
} from 'src/common/ag-grid/editable-datagrid/AgGridValidators';
import {
  BlockingTabEditors,
  CostPlanningExportParams_Ag,
  LastModifiedEnum,
  SelectAllMessage,
  getColumns,
  rowNodeDataValidationCheck,
  sizeToFit,
  syncValidator,
  useDelayedEffect,
} from 'src/common/ag-grid/editable-datagrid/AgHelper';
import AgNumberEditor from 'src/common/ag-grid/editors/AgNumberEditor';
import AgMultiTreeSelect from 'src/common/ag-grid/editors/ag-tree-select/AgMultiTreeSelect';
import AgSingleTreeSelect from 'src/common/ag-grid/editors/ag-tree-select/AgSingleTreeSelect';
import { useUserAlias } from 'src/hooks/useUserAlias';
import 'src/common/ag-grid/editable-datagrid/AgEditableDataGridStyles.css';
import { currentModifiedAt } from 'src/utils/time';
import AgDatePicker from 'src/common/ag-grid/editors/AgDatePicker';

// duplicate of IGridState
interface IAgGridState {
  gridName: string;
  visualType: string;
  gridLevelEditable?: boolean;
  buttonConfigs?: any;
}

interface IAgEditableGrid {
  planMetaData?: any;
  selectedGrid: string;
  visibleColumns: string[];
  gridConfig: any;
  dataSource: any;
  setDataSource?: any;
  datasetSelectId?: any;
  gridState: IAgGridState;
  gridRef?: any;
  columnConfigs?: any;
  stateStoring?: any;
  isMultiSelect?: boolean;
  onRowInserted?: (row: any) => void;
  onRowInserting?: (row: any) => void;
  onRowUpdating?: (row: any) => void;
  onRowUpdated?: (row: any) => void;
  getPopoverContent?: (row: any, key: any) => any;
  noDataText?: string;
  onContentReady?: any;
  onEditorPreparing?: any;
  inlineEditEnabled?: boolean;
  inlineEditHeaders?: string[];
  providedActionStrips?: any;
  inlineEdits?: any;
  setInlineEdits?: any;
  uniqueID?: any;
  onClickSave?: any;
  onClickRefresh?: any;
  onClickImport?: any;
  containerTitle?: string;
  containerDescription?: any;
  shouldSizeToFit?: boolean;
  shouldSuppressPivotMode?: boolean;
  sortingComparator?: any;
  onRowSelected?: any;
  domLayout?: any;
  autoSizeColumns?: boolean;
  addRowTransformer?: any;
  onClickClone?: any;
  colorUneditable?: boolean;
  showSaveWithoutEdits?: boolean;
  enableSummary?: boolean;
  grandTotal?: string;
  isDataLoading?: boolean;
  isFullScreenParam?: any;
  setFullScreenParam?: any;
  disableSelectAllMessage?: any;
  excelExportParams?: any;
  csvExportParams?: any;
}

const AgEditableDataGrid = (props: IAgEditableGrid) => {
  const {
    planMetaData,
    visibleColumns,
    gridConfig,
    dataSource = [],
    setDataSource,
    gridState,
    gridRef,
    columnConfigs,
    datasetSelectId,
    // for editable
    providedActionStrips,
    inlineEdits,
    setInlineEdits,
    uniqueID,
    onClickSave,
    onClickRefresh,
    containerTitle,
    containerDescription,
    inlineEditHeaders,
    onClickImport = null,
    shouldSizeToFit,
    sortingComparator = null,
    onRowSelected = null,
    domLayout = 'normal',
    autoSizeColumns = false,
    addRowTransformer = () => {},
    onClickClone = null,
    colorUneditable = false,
    showSaveWithoutEdits = false,
    enableSummary,
    isDataLoading = false,
    disableSelectAllMessage = false,
    excelExportParams,
    csvExportParams,
  } = props;
  const { alias } = useUserAlias();
  const { gridLevelEditable = false, buttonConfigs } = gridState;
  const [invalidRows, setInvalidRows] = useState<any>([]);
  const filteringDisabledColumns = lodashGet(gridConfig, 'DataGrid.filteringDisabledColumns');

  // For AgGrid Editing
  const [allRowCount, setAllRowCount] = useState((dataSource as any[]).length);
  const [rowsSelected, setRowsSelected] = useState<any>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [isFullScreen, setFullScreen] = useState(false);

  const [components] = useState({
    // AgInput: AgDefaultInputEditor,
    DatePicker: AgDatePicker,
    // agSingleSelect: AgLookUp,
    AgTreeSelect: AgSingleTreeSelect,
    AgTreeMultiSelect: AgMultiTreeSelect,
    customTooltip: AgCustomTooltip,
    AgNumberEditor,
    // AgLookUp: AgLookUp,
  });

  const headers = inlineEditHeaders ?? getColumns(planMetaData, datasetSelectId);

  const syncValidateValueSetter = (validateFns: any) => (params: ValueSetterParams) => {
    const _onSuccess = (params: ValueSetterParams) => () => {
      const data = params.data;
      const field = params.colDef.field as string;

      data[field] = setCellValue(params, true);

      agEditsUpdating(data, headers, setInlineEdits);
      params.api.applyTransaction({ update: [data] });
    };
    const _onFail = (params: ValueSetterParams) => (validationFailedMsg: any) => {
      const data = params.data;
      const field = params.colDef.field as string;

      data[field] = setCellValue(params, {
        validationFailedValue: params.newValue,
        validationFailedMsg,
      });
      agEditsUpdating(data, headers, setInlineEdits);
      params.api.applyTransaction({ update: [data] });
    };

    // Side Effect that handles validation and updating the grid
    const transformedValidateFns = validateFns.map((fn: any) => fn(params));
    const updateStatus = syncValidator(
      params,
      transformedValidateFns,
      _onSuccess(params),
      _onFail(params),
    );

    return updateStatus;
  };

  const buildColumns = () =>
    isArray(visibleColumns) && visibleColumns.length > 0
      ? visibleColumns.map((columnName) => {
          const config: ICustomizedAgGridColumnConfig = columnConfigs[columnName] || {};
          const {
            headerName,
            required = false,
            validationFns = [],
            editor = null,
            editorParams = null,
            minWidth = null,
            editable = true,
            onCellValueChanged = null,
            headerCheckboxSelection = null,
            checkboxSelection = null,
            valueFormatter = null,
            hide = false,
            comparator = sortingComparator,
            headerComponent = null,
            headerComponentParams = null,
            sort = [],
            tooltipComponent = null,
            tooltipValueGetter = null,
          } = config;
          const fullValidationFns = [
            ...(required ? [requiredValidationFn] : []),
            ...(editor && editorToValidationFnMap(editor, planMetaData?.costType)
              ? [editorToValidationFnMap(editor, planMetaData?.costType)]
              : []),
            ...validationFns,
          ];

          const singleColumnConfig = {
            headerName: headerName
              ? required
                ? `${headerName} *`
                : headerName
              : required
              ? `${columnName} *`
              : columnName,
            field: columnName,
            ...(editable && { cellClassRules }),
            editable: gridLevelEditable && editable,
            cellEditor: editor || 'AgInput',
            cellEditorParams: editorParams,
            ...(editor &&
              BlockingTabEditors.includes(editor) && {
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams) =>
                  params.event.key === 'Tab' && params.editing,
              }),
            cellEditorPopup: true,
            minWidth,
            checkboxSelection: gridLevelEditable && checkboxSelection,
            headerCheckboxSelection: gridLevelEditable && headerCheckboxSelection,
            ...(headerCheckboxSelection && { headerCheckboxSelectionFilteredOnly: true }),
            onCellValueChanged,
            hide,
            sort,
            valueGetter: (params: any) => getCellValue(params.data[columnName]),
            valueSetter:
              fullValidationFns.length > 0
                ? syncValidateValueSetter(fullValidationFns)
                : (params: ValueSetterParams) => {
                    const data = params.data;
                    const field = params.colDef.field as string;
                    data[field] = setCellValue(params, true);
                    params.api.applyTransaction({ update: [data] });
                    const headers = inlineEditHeaders ?? getColumns(planMetaData, datasetSelectId);
                    agEditsUpdating(data, headers, setInlineEdits);
                    return true;
                  },
            valueFormatter,
            filter: isArray(filteringDisabledColumns)
              ? !filteringDisabledColumns.includes(columnName)
              : true,
            sortable: true,
            comparator,
            headerComponent,
            headerComponentParams,
            ...(tooltipComponent && { tooltipComponent }),
            ...(tooltipValueGetter && { tooltipValueGetter }),
          };
          return singleColumnConfig;
        })
      : [];

  const [columns, setColumns] = useState(buildColumns() as ColDef[]);

  useDelayedEffect(() => {
    gridApi && setAllRowCount(gridApi.getDisplayedRowCount());
    const tempRows: any[] = [];
    !isEmpty(inlineEdits) &&
      Object.entries(inlineEdits).forEach(([key, value]: [any, any]) => {
        if (value && isEmpty(value['current'])) {
          // if curr is empty, i.e. is deletion, don't need to validate
          return;
        }
        const rowNodeData = gridApi?.getRowNode(key)!.data;
        if (!rowNodeDataValidationCheck(rowNodeData)) {
          tempRows.push(rowNodeData);
        }
      });
    setInvalidRows(tempRows);
  }, [inlineEdits]);

  useDelayedEffect(() => {
    setColumns(buildColumns());

    gridColumnApi && autoSizeColumns && gridColumnApi.autoSizeAllColumns();
  }, [visibleColumns, columnConfigs]);

  useDelayedEffect(() => {
    gridApi?.setRowData(dataSource as any[]);
    gridApi && shouldSizeToFit && sizeToFit(gridApi);
    gridApi && setAllRowCount(gridApi.getDisplayedRowCount());
    if (isDataLoading) {
      gridRef?.current?.api?.showLoadingOverlay?.();
    } else {
      if (gridApi?.getDisplayedRowCount() === 0) {
        gridRef.current?.api?.showNoRowsOverlay();
      } else {
        gridRef.current?.api?.hideOverlay();
      }
    }

    updateStatusBar(gridApi);
  }, [gridApi, dataSource, isDataLoading]);

  // ------------------------------------------------------------------------------------------------
  // For DataGrid
  // ------------------------------------------------------------------------------------------------
  const onGridReady = (gridParams: any) => {
    setGridApi(gridParams.api);
    setGridColumnApi(gridParams.columnApi);
  };

  const updateStatusBar = (gridApi: any) => {
    if (enableSummary && gridApi) {
      const statusBarComponent: any = gridApi.getStatusPanel('summary');
      statusBarComponent.updateStatusBar();
    }
  };

  const onSelectionChanged = useCallback(
    (params: any) => {
      // Invoke summary status bar
      updateStatusBar(params.api);

      if (gridApi?.getSelectedRows) {
        setRowsSelected(gridApi.getSelectedRows());
        onRowSelected?.(normalizeAgDataSource(gridApi.getSelectedRows()));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridApi, onRowSelected],
  );

  const isModified = (params: any) => {
    const data = params.data[params.colDef.field];
    if (typeof data !== 'object' || isArray(data)) {
      return false;
    }

    return !isEqual(data['original'], data['value']) && !['Site ID'].includes(params.colDef.field);
  };

  const isError = (params: any) => {
    const data = params.data[params.colDef.field];
    if (typeof data !== 'object' || isArray(data)) {
      return false;
    }
    return data['lastValidation'] !== true;
  };

  const cellClassRules = {
    modified: isModified,
    error: isError,
    ...(colorUneditable && {
      uneditable: (params: any) => {
        if (isError(params) || isModified(params)) {
          return false;
        }

        if (typeof params.colDef.editable === 'function') {
          return !params.colDef.editable(params);
        }
        return !params.colDef.editable;
      },
    }),
  };

  // const refreshPage = () => {
  //   gridApi?.refreshServerSide({ route: [], purge: true });
  //   gridApi?.deselectAll();
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNextDataID = () => uniqueID.current++;

  const getRowId = useMemo(
    () => (params: any) => params.data.id ?? getNextDataID(),
    [getNextDataID],
  );

  const defaultColDef = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      // cellRenderer: 'agAnimateShowChangeCellRenderer',
      tooltipComponent: 'customTooltip',
      // this can be done by single column level
      tooltipValueGetter: (params: any) => {
        const data = params.data[params.colDef.field];
        const isFieldValid =
          typeof data === 'object' && !isArray(data) ? data.lastValidation === true : true;
        if (isFieldValid) return '';
        return {
          field: params.colDef.field,
          lastValidation: data.lastValidation.validationFailedValue,
          validationFailedMsg: data.lastValidation.validationFailedMsg || 'This value is not valid',
        };
      },
    }),
    [],
  );

  const buttonDiv = (icon: any, text: any, onClick: any, disabled = false) => {
    const disabledStyle = disabled ? { color: 'grey', cursor: 'not-allowed' } : {};
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        id={`button_${text}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: '2.4rem',
          lineHeight: '12px',
          ...disabledStyle,
        }}
        onClick={!disabled ? onClick : undefined}
      >
        {icon}
        <span style={{ fontSize: '9px', fontWeight: 'normal' }}>{text}</span>
      </div>
    );
  };

  // For Grid Level Editable Data Grid
  const renderActionStripe = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '25px',
      }}
    >
      {providedActionStrips && (
        <div
          style={{
            minWidth: '170px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          {providedActionStrips()}
        </div>
      )}
      {/*Section for buttons*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px',
          borderLeft: 'thin solid lightGrey',
          paddingLeft: '15px',
        }}
      >
        {buttonConfigs['Save']?.['Enabled'] &&
          buttonDiv(
            <FaRegSave />,
            'Save',
            onClickSave || handleClickSave,
            (!showSaveWithoutEdits && Object.keys(inlineEdits).length === 0) ||
              invalidRows.length > 0 ||
              isDataLoading,
          )}
        {buttonConfigs['Import']?.['Enabled'] &&
          buttonDiv(<BiImport />, 'Import', onClickImport, !datasetSelectId)}
        {buttonConfigs['Add']?.['Enabled'] && buttonDiv(<MdAdd />, 'Add', addRow, isDataLoading)}
        {buttonConfigs['Clone']?.['Enabled'] &&
          buttonDiv(<FaRegClone />, 'Clone', onClickClone || cloneRows, rowsSelected.length === 0)}
        {buttonConfigs['Delete']?.['Enabled'] &&
          buttonDiv(<BsFillArchiveFill />, 'Delete', deleteRow, rowsSelected.length === 0)}
        {buttonConfigs['Refresh']?.['Enabled'] &&
          buttonDiv(<BiRefresh />, 'Refresh', onClickRefresh, isDataLoading)}
        {buttonConfigs['Expand']?.['Enabled'] &&
          buttonDiv(
            <AiOutlineFullscreen />,
            'Expand',
            () => {
              setFullScreen(true);
            },
            isDataLoading,
          )}
      </div>
    </div>
  );

  const handleClickSave = (e: any) => {
    const onClickSave = buttonConfigs['Save']['onClick'];
    onClickSave?.(e);
  };

  const addRow = () => {
    const id = getNextDataID();
    const modifiedBy = alias;
    const newRow = { id } as any;
    columns.forEach((column: ColDef) => {
      const field = column.field as string;
      newRow[field] = cellValueFormatter(
        null,
        null,
        column.editable && column?.headerName?.includes('*')
          ? { validationFailedValue: null, validationFailedMsg: 'This field cannot be empty' }
          : true,
      );
    });
    if (visibleColumns.includes(LastModifiedEnum.MODIFIED_BY)) {
      newRow[LastModifiedEnum.MODIFIED_AT] = agCellValueFormatter(currentModifiedAt());
      newRow[LastModifiedEnum.MODIFIED_BY] = agCellValueFormatter(modifiedBy);
    }
    addRowTransformer(newRow);

    agEditsUpdating(newRow as any, headers, setInlineEdits);

    const tx = { addIndex: 0, add: [newRow] };
    gridApi?.applyTransaction(tx);
  };

  const deleteRow = () => {
    const selectedRows = [] as any;
    const unselectedRows = [] as any;
    gridApi?.forEachNode((rowNode) => {
      if (rowNode.isSelected()) {
        selectedRows.push(rowNode.data);
      } else {
        unselectedRows.push(rowNode.data);
      }
    });
    if (selectedRows.length > 50) {
      // const linesToBeRemoved = revertDataSourceToOriginal(selectedRows);
      setInlineEdits((inlineEdits: any) => ({
        ...inlineEdits,
        linesToBeRemoved: [
          ...(inlineEdits.linesToBeRemoved ? inlineEdits.linesToBeRemoved : []),
          ...selectedRows,
        ],
      }));
      setDataSource?.(unselectedRows);
    } else {
      selectedRows.forEach((row: any) => {
        const headers = inlineEditHeaders ?? getColumns(planMetaData, datasetSelectId);
        agEditsDeleting(row, headers, setInlineEdits);
      });
      gridApi?.applyTransaction({ remove: selectedRows });
    }
  };

  const cloneRows = () => {
    const modifiedBy = alias;
    const selectedRows = gridApi?.getSelectedRows();
    const rowsToAdd = selectedRows?.map((row) => {
      const newRow = { id: getNextDataID() } as any;
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === 'object') {
          newRow[key] = {
            ...row[key],
            original: null,
          };
        } else if (key !== 'id') {
          newRow[key] = cellValueFormatter(row[key], null, true);
        }
      });

      const resultRow = {
        ...newRow,
        ...(visibleColumns.includes(LastModifiedEnum.MODIFIED_BY) && {
          [LastModifiedEnum.MODIFIED_AT]: agCellValueFormatter(''),
          [LastModifiedEnum.MODIFIED_BY]: agCellValueFormatter(modifiedBy),
        }),
      };
      const headers = inlineEditHeaders ?? getColumns(planMetaData, datasetSelectId);
      agEditsCloning(resultRow, headers, setInlineEdits);
      return resultRow;
    });

    const tx = { addIndex: 0, add: rowsToAdd };
    gridApi?.applyTransaction(tx);
    gridApi?.deselectAll();
  };

  const popupParent = useMemo<HTMLElement>(() => document.querySelector('body')!, []);

  return (
    <div>
      {buttonConfigs && (
        <Container
          header={
            <Header actions={renderActionStripe()} description={containerDescription}>
              {containerTitle}
            </Header>
          }
        >
          {!disableSelectAllMessage && (
            <SelectAllMessage
              gridApi={gridApi}
              rowsSelected={rowsSelected}
              allRowCount={allRowCount}
            />
          )}
          <AgGridBaseWrapper
            setGridApi={setGridApi}
            gridRef={gridRef}
            domLayout={domLayout}
            defaultColDef={defaultColDef}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={15}
            onGridReady={onGridReady}
            isFullScreen={isFullScreen}
            setFullScreen={setFullScreen}
            columnDefs={columns}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            components={components}
            rowBuffer={30}
            popupParent={popupParent}
            enableRangeSelection={true}
            rowData={dataSource}
            onSelectionChanged={onSelectionChanged}
            // animateRows={true}
            // rowModelType={'serverSide'}
            pagination={true}
            paginationPageSize={20}
            // cacheBlockSize={60}
            suppressColumnVirtualisation={true}
            // shouldSuppressPivotMode={shouldSuppressPivotMode}
            getRowId={getRowId}
            stopEditingWhenCellsLoseFocus={true}
            onCellEditingStopped={(event: CellEditingStoppedEvent) => {
              event.api.clearRangeSelection();
            }}
            defaultExcelExportParams={
              excelExportParams ? excelExportParams : CostPlanningExportParams_Ag
            }
            defaultCsvExportParams={csvExportParams ?? {}}
          />
        </Container>
      )}
    </div>
  );
};

export default AgEditableDataGrid;
