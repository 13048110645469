import {
  ExpandableSection,
  Table,
  Box,
  ColumnLayout,
  Container,
  Header,
  StatusIndicator,
} from '@amzn/awsui-components-react/polaris';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import {
  AsyncUploadEditsDetail,
  AsyncUploadEditsStatusEnumEnum,
} from '@amzn/fox-den-cost-planning-lambda';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { DATASET_TYPE_LABEL_MAP, SupportedDatasetType } from 'src/utils/planning/planetModel';
import { dateTimeFromIsoToLocal } from 'src/utils/time';

interface DatasetUploadStatusProps {
  batchMetadata: IBatchMetadataParsed | undefined;
}

interface DetailedDatasetStatusTableItem extends AsyncUploadEditsDetail {
  datasetName: string;
}

const DatasetUploadStatusFooter: FC<DatasetUploadStatusProps> = ({ batchMetadata }) => {
  const { t } = useTranslation();

  if (!batchMetadata?.asyncUploadEditsStatus?.detailedStatus) return null;

  const tableItems = Object.entries(batchMetadata.asyncUploadEditsStatus.detailedStatus).reduce(
    (acc, [key, value]) => [...acc, { datasetName: key, ...value }],
    [] as DetailedDatasetStatusTableItem[],
  );

  const tableColumnDefinitions: TableProps.ColumnDefinition<DetailedDatasetStatusTableItem>[] = [
    {
      id: 'datasetName',
      header: t('dataset_name_header'),
      cell: (item) => DATASET_TYPE_LABEL_MAP[item.datasetName as SupportedDatasetType],
    },
    { id: 'status', header: t('status_header'), cell: (item) => item.status },
    {
      id: 'completeDatetime',
      header: t('complete_time_header'),
      cell: (item) => (item.completeDatetime ? dateTimeFromIsoToLocal(item.completeDatetime) : '-'),
    },
    {
      id: 'triggerDatetime',
      header: t('triggered_at_header'),
      cell: (item) => (item.triggerDatetime ? dateTimeFromIsoToLocal(item.triggerDatetime) : '-'),
    },
    { id: 'triggerBy', header: t('triggered_by_header'), cell: (item) => item.triggerBy },
    { id: 'detail', header: t('details_header'), cell: (item) => item.detail },
  ];

  return (
    <ExpandableSection variant="footer" headerText={t('detailed_status_header')}>
      <Table variant="embedded" columnDefinitions={tableColumnDefinitions} items={tableItems} />
    </ExpandableSection>
  );
};

const DatasetUploadStatus: FC<DatasetUploadStatusProps> = ({ batchMetadata }) => {
  const { t } = useTranslation();

  if (!batchMetadata?.asyncUploadEditsStatus?.status) return null;

  const statusIndicatorMapping: Record<AsyncUploadEditsStatusEnumEnum, StatusIndicatorProps.Type> =
    {
      [AsyncUploadEditsStatusEnumEnum.Success]: 'success',
      [AsyncUploadEditsStatusEnumEnum.Cancelled]: 'stopped',
      [AsyncUploadEditsStatusEnumEnum.Queued]: 'pending',
      [AsyncUploadEditsStatusEnumEnum.InProgress]: 'pending',
      [AsyncUploadEditsStatusEnumEnum.Failure]: 'error',
      [AsyncUploadEditsStatusEnumEnum.Timeout]: 'error',
    };

  const statusIndicatorType = statusIndicatorMapping[batchMetadata.asyncUploadEditsStatus.status];

  return (
    <Container
      header={<Header variant="h2">{t('dataset_upload_status')}</Header>}
      footer={<DatasetUploadStatusFooter batchMetadata={batchMetadata} />}
    >
      <ColumnLayout columns={4} variant="text-grid">
        <div>
          <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
            {t('overall_status')}
          </Box>
          <StatusIndicator type={statusIndicatorType}>
            {batchMetadata.asyncUploadEditsStatus.status ?? '-'}
          </StatusIndicator>
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default DatasetUploadStatus;
