import { ValueSetterParams } from 'ag-grid-community';
import { find, get, isArray, isEqual, uniqueId } from 'lodash';
import {
  ICellData,
  ILastFailedValidation,
  IValidationResult,
  isICellData,
} from 'src/common/ag-grid/editable-datagrid/AgGridValidators';

export interface ICustomizedAgGridColumnConfig {
  required?: any;
  validationFns?: any[];
  headerName?: string;
  editor?: string;
  editorParams?: any;
  minWidth?: number;
  hide?: boolean;
  editable?: boolean | any;
  onCellValueChanged?: any;
  headerCheckboxSelection?: boolean;
  checkboxSelection?: boolean;
  valueFormatter?: any;
  valueGetter?: any;
  comparator?: any;
  cellRenderer?: any;
  onCellClicked?: any;
  headerComponent?: any;
  headerComponentParams?: any;
  sort?: any;
  tooltipComponent?: any;
  tooltipValueGetter?: any;
}

export interface IAgDuplicateCheckColumnConfig {
  colName: string;
  parentColumns?: string[];
  comparsionFn?: any;
}

export interface IAgDuplicateCheckConfig {
  columns: IAgDuplicateCheckColumnConfig[];
}

export const agCellValueFormatter = (value: any): ICellData => ({
  value,
  original: null,
  lastValidation: true,
});

export const cellValueFormatter = (value: any, original: any, lastValidation: any) => ({
  value,
  original,
  lastValidation,
});

export const isCellValueModified = (cellValue: ICellData | string | string[]): any =>
  isICellData(cellValue);

export const areAllValuesSameAsOriginal = (rows: Record<string, ICellData | string>[]) => {
  const filteredDataSource = rows.filter((row) => {
    const filteredKeys = Object.keys(row).filter((key) => {
      const data = row[key];
      if (!data || typeof data !== 'object' || isArray(data)) {
        return false;
      }
      return !isEqual(data['original'], data['value']);
    });
    return filteredKeys.length > 0;
  });
  return filteredDataSource.length === 0;
};

export const isDataSourceModified = (rows: Record<string, ICellData | string>[]) => {
  const filteredDataSource = rows.filter((row) => {
    const filteredKeys = Object.keys(row).filter((key) => isCellValueModified(row[key]));
    return filteredKeys.length > 0;
  });
  return filteredDataSource.length > 0;
};

export const setCellValue = (
  params: ValueSetterParams,
  lastValidation: boolean | ILastFailedValidation,
): any => {
  const data = params.data;
  const field = params.colDef.field as string;

  const originalValue = isCellValueModified(data[field])
    ? (data[field] as ICellData).original
    : data[field];
  const newValue = params.newValue;

  return cellValueFormatter(newValue, originalValue, lastValidation);
};

export const getCellValue = (cellValue: ICellData | string | string[]): any => {
  if (isCellValueModified(cellValue)) {
    return (cellValue as ICellData).value;
  }
  return cellValue;
};

export const normalizeAgRow = (row: Record<string, ICellData | string>) => {
  const newData = {} as any;
  Object.keys(row).forEach((key) => {
    newData[key] = getCellValue(row[key]);
  });
  return newData;
};

export const normalizeAgDataSource = (dataSource: Record<string, ICellData | string>[]) => {
  const newDataSource = [] as any;
  dataSource.forEach((data) => {
    newDataSource.push(normalizeAgRow(data));
  });
  return newDataSource;
};

// export const isEveryCellEmpty = (row: { [key: string]: ICellData | string }): boolean => {
//   let numberOfNonEmptyCells = Object.keys(row).filter((key) => {
//     if (!!!getCellValue(row[key])) {
//       return false;
//     } else if (typeof getCellValue(row[key]) === 'object' && getCellValue(row[key]).length === 0) {
//       return false;
//     } else {
//       return true;
//     }
//   }).length;

//   return numberOfNonEmptyCells === 0;
// };

// export const revertDataSourceToOriginal = (dataSource: { [key: string]: ICellData | string }[]) => {
//   const newDataSource = [];
//   dataSource.forEach((data) => {
//     newDataSource.push(revertRowToOriginal(data));
//   });
//   return newDataSource;
// };

// export const revertRowToOriginal = (row: { [key: string]: ICellData | string }) => {
//   const newData = {};
//   Object.keys(row).forEach((key) => {
//     newData[key] = getCellOriginal(row[key]);
//   });
//   return newData;
// };

// export const getCellOriginal = (cellValue: ICellData | string | string[]): any => {
//   if (isCellValueModified(cellValue)) {
//     return (cellValue as ICellData).original;
//   }
//   return cellValue;
// };

export const agEditsUpdating = (
  rowData: Record<string, ICellData | string>,
  headers: any,
  setInlineEdits: any,
) => {
  const id = rowData.id == null ? uniqueId() : rowData.id;
  const prevValues: string[] = [];
  const currValues: string[] = [];
  headers.forEach((header: any) => {
    if (typeof rowData[header] === 'object' && !isArray(rowData[header])) {
      prevValues.push((rowData[header] as ICellData).original);
      currValues.push((rowData[header] as ICellData).value);
    } else {
      prevValues.push(rowData[header] as string);
      currValues.push(rowData[header] as string);
    }
  });

  setInlineEdits((inlineEdits: any) => ({
    ...inlineEdits,
    [id.toString()]: {
      previous: prevValues,
      current: currValues,
    },
  }));
};

export const agEditsDeleting = (
  rowData: Record<string, ICellData | string>,
  headers: any,
  setInlineEdits: any,
) => {
  const key = rowData.id as string;
  const prevValues: string[] = [];
  const currValues: string[] = [];
  headers.forEach((header: any) => {
    if (typeof rowData[header] === 'object' && !isArray(rowData[header])) {
      prevValues.push((rowData[header] as ICellData).original);
    } else {
      prevValues.push(rowData[header] as string);
    }
  });

  setInlineEdits((inlineEdits: any) => ({
    ...inlineEdits,
    [key]: {
      previous: prevValues,
      current: currValues,
    },
  }));
};

export const agEditsCloning = (
  rowData: Record<string, ICellData | string | number>,
  headers: any[],
  setInlineEdits: (arg0: (inlineEdits: any) => any) => void,
) => {
  const key = rowData.id as string;
  const prevValues: string[] = [];
  const currValues: string[] = [];
  headers.forEach((header) => {
    if (typeof rowData[header] === 'object' && !isArray(rowData[header])) {
      currValues.push((rowData[header] as ICellData).value);
    } else {
      currValues.push(rowData[header] as string);
    }
  });

  setInlineEdits((inlineEdits) => ({
    ...inlineEdits,
    [key]: {
      previous: prevValues,
      current: currValues,
    },
  }));
};

export const getColumns = (batchMetadata: any, datasetName: any) => {
  try {
    const { datasetLocations, datasetSchemaConfig } = batchMetadata;
    // retrieve the schemaName
    const datasetLocation = find(datasetLocations, { datasetName });
    const schemaName = get(datasetLocation, 'schemaName');
    // retrieve columns from dataset schema config
    let schemaConfig = null;
    if (schemaName === 'Compute') {
      schemaConfig = get(datasetSchemaConfig, 'computeDataSchema')
        ? get(datasetSchemaConfig, 'computeDataSchema')
        : find(get(datasetSchemaConfig, 'inputDataSchema'), { schemaName });
    } else {
      schemaConfig = find(get(datasetSchemaConfig, 'inputDataSchema'), { schemaName });
    }

    return schemaConfig?.columnList
      ? schemaConfig.columnList.map((columnObject: any) => columnObject.dimensionName)
      : [];
  } catch (_) {
    return [];
  }
};

export const syncValidator = (
  params: any,
  transformedValidateFns: any[],
  onSuccess: any,
  onFail: any,
) => {
  let result = true;
  let message = null;
  transformedValidateFns.forEach((fn) => {
    if (!result) return;
    const validationResult: IValidationResult = fn(params.newValue);
    result = validationResult.result;
    message = validationResult.message;
  });

  if (result) {
    onSuccess();
  } else {
    onFail(message);
  }
  return true;
};
