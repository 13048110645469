import {
  ExpandableSection,
  Table,
  Box,
  ColumnLayout,
  Container,
  Header,
  StatusIndicator,
} from '@amzn/awsui-components-react/polaris';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import { ComputeStatusEnumEnum } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { IBatchMetadataParsed, IDetailedComputeStep } from 'src/utils/planning/batchMetadataModel';
import { dateTimeFromIsoToLocal } from 'src/utils/time';

interface ILastComputeDetails {
  batchMetadata: IBatchMetadataParsed | undefined;
}

const LastComputeDetailsFooter = ({ batchMetadata }: ILastComputeDetails): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ExpandableSection variant="footer" headerText={t('detailed_steps_header')}>
      <Table
        variant="embedded"
        columnDefinitions={[
          { id: 'stepName', header: t('step_name_header'), cell: (item: any) => item.stepName },
          { id: 'status', header: t('status_header'), cell: (item: any) => item.status },
          {
            id: 'completeDatetime',
            header: t('complete_time_header'),
            cell: (item: IDetailedComputeStep) =>
              item.completeDatetime ? dateTimeFromIsoToLocal(item.completeDatetime) : '-',
          },
          { id: 'detail', header: t('details_header'), cell: (item: any) => item.detail },
        ]}
        items={batchMetadata?.computeStatus?.detailedSteps ?? []}
      />
    </ExpandableSection>
  );
};

const LastComputeDetails = ({ batchMetadata }: ILastComputeDetails): JSX.Element => {
  const { t } = useTranslation();

  const statusIndicatorMapping: Record<ComputeStatusEnumEnum, StatusIndicatorProps.Type> = {
    [ComputeStatusEnumEnum.Success]: 'success',
    [ComputeStatusEnumEnum.Cancelled]: 'stopped',
    [ComputeStatusEnumEnum.Cancelling]: 'pending',
    [ComputeStatusEnumEnum.Queued]: 'pending',
    [ComputeStatusEnumEnum.InProgress]: 'pending',
    [ComputeStatusEnumEnum.Failure]: 'error',
    [ComputeStatusEnumEnum.Timeout]: 'error',
    [ComputeStatusEnumEnum.Warning]: 'warning',
  };

  const statusIndicatorType = batchMetadata?.computeStatus?.status
    ? statusIndicatorMapping[batchMetadata?.computeStatus?.status as ComputeStatusEnumEnum]
    : 'pending';

  return (
    <Container
      header={<Header variant="h2">{t('last_compute_details_header')}</Header>}
      footer={<LastComputeDetailsFooter batchMetadata={batchMetadata} />}
    >
      <ColumnLayout columns={4} variant="text-grid">
        <div>
          <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
            {t('compute_status_header')}
          </Box>
          <StatusIndicator type={statusIndicatorType}>
            {batchMetadata?.computeStatus?.status ?? '-'}
          </StatusIndicator>
        </div>
        <div>
          <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
            {t('compute_trigger_header')}
          </Box>
          {batchMetadata?.computeStatus?.trigger ?? '-'}
        </div>
        <div>
          <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
            {t('triggered_at_header')}
          </Box>
          {batchMetadata?.computeStatus?.triggerDatetime
            ? dateTimeFromIsoToLocal(batchMetadata?.computeStatus?.triggerDatetime)
            : '-'}
        </div>
        <div>
          <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
            {t('completed_at_header')}
          </Box>
          {batchMetadata?.computeStatus?.completeDatetime
            ? dateTimeFromIsoToLocal(batchMetadata?.computeStatus?.completeDatetime)
            : '-'}
        </div>
        <div>
          <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
            {t('triggered_by_header')}
          </Box>
          {batchMetadata?.computeStatus?.triggeredBy ?? '-'}
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default LastComputeDetails;
