import { uniq } from 'lodash';

export const STATIC_FILTER_LIST = [
  {
    site_sk: 21,
    location_code: '0000',
    location_name: '3PL1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: '3PL',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 23,
    location_code: '1463',
    location_name: 'ABE2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable',
    launch_date: '2010-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 24,
    location_code: '1424',
    location_name: 'ABE3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2010-09-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 25,
    location_code: '1829',
    location_name: 'ABE4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-07-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 28,
    location_code: '1848',
    location_name: 'ABE8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2016-08-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 83778,
    location_code: '1W20',
    location_name: 'ABQ1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-09-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98771,
    location_code: '7Z41',
    location_name: 'ABQ2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2025-03-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 31,
    location_code: '2526',
    location_name: 'ACY1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 32,
    location_code: '2529',
    location_name: 'ACY2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2019-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85436,
    location_code: '1I95',
    location_name: 'AFW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2021-09-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 98748,
    location_code: '3G70',
    location_name: 'AFW2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85633,
    location_code: '0000',
    location_name: 'AGL21',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable IXD',
    launch_date: '2021-08-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85515,
    location_code: '1I99',
    location_name: 'AGS1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-09-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85498,
    location_code: '1I37',
    location_name: 'AGS2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy-AR',
    building_type: 'Quick Deploy',
    launch_date: '2020-10-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 91282,
    location_code: '3G64',
    location_name: 'AGS3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: '2022-11-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 67320,
    location_code: '2117',
    location_name: 'AKC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 101390,
    location_code: '7Z05',
    location_name: 'AKH4',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'Sort',
    building_type: 'Merch',
    launch_date: '2023-05-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'Merch',
  },
  {
    site_sk: 98144,
    location_code: '7Z75',
    location_name: 'AKR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2023-05-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 67325,
    location_code: '2134',
    location_name: 'ALB1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-09-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 94737,
    location_code: '3M32',
    location_name: 'ALB5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 94736,
    location_code: '1Y19',
    location_name: 'AMA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Reverse Logistics',
    building_type: 'Reverse Logistics',
    launch_date: '2022-05-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 35,
    location_code: '0000',
    location_name: 'ARE0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 37,
    location_code: '0000',
    location_name: 'AREX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Dropship',
    sub_group_type: 'Dropship',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 39,
    location_code: '0000',
    location_name: 'ARN0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 796,
    location_code: '0000',
    location_name: 'ARR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: '3PL-SIOC',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 40,
    location_code: '0000',
    location_name: 'ARS0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NodeHedge',
  },
  {
    site_sk: 41,
    location_code: '0000',
    location_name: 'ARW0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 42,
    location_code: '0000',
    location_name: 'ARWX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Dropship',
    sub_group_type: 'Dropship',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85672,
    location_code: '1I92',
    location_name: 'ASH1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85621,
    location_code: '1I44',
    location_name: 'ASH2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Special Handling',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 67608,
    location_code: '2121',
    location_name: 'ATL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-10-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 47,
    location_code: '1497',
    location_name: 'ATL7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: '2015-11-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 48,
    location_code: '1477',
    location_name: 'ATL8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2015-10-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 98749,
    location_code: '3M30',
    location_name: 'AUN2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-10-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 67638,
    location_code: '1031',
    location_name: 'AUS2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-10-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85579,
    location_code: '1I61',
    location_name: 'AUS3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-07-24',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 53,
    location_code: '1480',
    location_name: 'AVP1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2008-07-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85675,
    location_code: '0000',
    location_name: 'AVP2',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'NonSort',
    building_type: 'Sortable',
    launch_date: '2023-01-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 55,
    location_code: '2801',
    location_name: 'AVP3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2015-10-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 56,
    location_code: '1496',
    location_name: 'AVP6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 57,
    location_code: '1349',
    location_name: 'AVP8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: '2016-08-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85625,
    location_code: '1Y06',
    location_name: 'AVP9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-09-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 58,
    location_code: '0000',
    location_name: 'AXLH',
    continent: 'NA',
    country: 'US',
    sub_group: 'Dropship',
    sub_group_type: 'Dropship',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 82733,
    location_code: '1W29',
    location_name: 'AZA4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-08-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 64,
    location_code: '1447',
    location_name: 'BDL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy',
    building_type: 'AR Sortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 65,
    location_code: '1447',
    location_name: 'BDL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2015-02-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 66,
    location_code: '2437',
    location_name: 'BDL3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-07-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 83786,
    location_code: '1W26',
    location_name: 'BDL4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-10-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 82743,
    location_code: '1X18',
    location_name: 'BDL6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-07-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 98750,
    location_code: '3G59',
    location_name: 'BDU2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-08-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 68,
    location_code: '1426',
    location_name: 'BFI1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'Robotics-Development-Center',
    launch_date: '2011-06-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 69,
    location_code: '1428',
    location_name: 'BFI3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort-AR',
    building_type: 'AR Nonsortable',
    launch_date: '2014-06-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 70,
    location_code: '1438',
    location_name: 'BFI4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2015-06-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 73,
    location_code: '2430',
    location_name: 'BFI7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2017-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85677,
    location_code: '1I45',
    location_name: 'BFI9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Softlines',
    launch_date: '2021-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 74,
    location_code: '1795',
    location_name: 'BFIX',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 67756,
    location_code: '2118',
    location_name: 'BFL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-09-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85582,
    location_code: '1I46',
    location_name: 'BFL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable',
    launch_date: '2022-10-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 107853,
    location_code: '0000',
    location_name: 'BFN2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Reverse Logistics',
    launch_date: '2022-09-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 107854,
    location_code: '0000',
    location_name: 'BFN3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Reverse Logistics',
    launch_date: '2022-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 76,
    location_code: '2075',
    location_name: 'BHM1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-03-29',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98751,
    location_code: '3G99',
    location_name: 'BLV2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-07-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 81,
    location_code: '1445',
    location_name: 'BNA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2013-02-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 82,
    location_code: '1421',
    location_name: 'BNA3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2012-09-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 92366,
    location_code: '1Y08',
    location_name: 'BNA6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2025-03-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 92841,
    location_code: '3M34',
    location_name: 'BNA7',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 67934,
    location_code: '2074',
    location_name: 'BOI2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-11-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 100755,
    location_code: '7Z33',
    location_name: 'BOI3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2023-04-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 84,
    location_code: '1452',
    location_name: 'BOS1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'FDFC',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 68015,
    location_code: '8026',
    location_name: 'BOS3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-09-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 87,
    location_code: '1555',
    location_name: 'BOS7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 89,
    location_code: '0000',
    location_name: 'BSSX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Dropship',
    sub_group_type: 'Dropship',
    building_type: '3PL-BSS',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 83805,
    location_code: '1V57',
    location_name: 'BTR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2024-03-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 91,
    location_code: '1160',
    location_name: 'BUF9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85511,
    location_code: '0000',
    location_name: 'BUR7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-10-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 92,
    location_code: '1451',
    location_name: 'BWI1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Softlines',
    launch_date: '2007-01-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 93,
    location_code: '1439',
    location_name: 'BWI2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2015-03-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 94,
    location_code: '2425',
    location_name: 'BWI4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-09-24',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 97,
    location_code: '1859',
    location_name: 'BWI7',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98,
    location_code: '8016',
    location_name: 'BWI8',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Uncategorized',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 100,
    location_code: '0000',
    location_name: 'CAE1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Sortable',
    launch_date: '2011-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 101933,
    location_code: '8A89',
    location_name: 'CAK4',
    continent: 'NA',
    country: 'US',
    sub_group: 'Merch',
    sub_group_type: 'Merch',
    building_type: 'Merch',
    launch_date: '2023-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'Merch',
  },
  {
    site_sk: 105,
    location_code: '1418',
    location_name: 'CHA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Sortable',
    launch_date: '2011-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 106,
    location_code: '1425',
    location_name: 'CHA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2011-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 99009,
    location_code: '7Z48',
    location_name: 'CHO1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2023-04-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 109,
    location_code: '2440',
    location_name: 'CLE2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 110,
    location_code: '2535',
    location_name: 'CLE3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-09-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 102120,
    location_code: '5T12',
    location_name: 'CLE7',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'MOD',
    launch_date: '2023-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'MOD',
  },
  {
    site_sk: 113,
    location_code: '1048',
    location_name: 'CLT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2016-10-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 114,
    location_code: '2532',
    location_name: 'CLT3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2018-07-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 115,
    location_code: '2065',
    location_name: 'CLT4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-09-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85655,
    location_code: '1Y72',
    location_name: 'CLT6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-10-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 117,
    location_code: '1827',
    location_name: 'CMH1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2016-09-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 118,
    location_code: '1800',
    location_name: 'CMH2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-08-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 119,
    location_code: '2534',
    location_name: 'CMH3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2019-06-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 120,
    location_code: '2077',
    location_name: 'CMH4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-02-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 121,
    location_code: '8267',
    location_name: 'CMH6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2019-07-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85752,
    location_code: '1I79',
    location_name: 'CMH7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Softlines',
    launch_date: '2022-07-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 102637,
    location_code: '3G19',
    location_name: 'CRG1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Reverse Logistics',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85435,
    location_code: '1I97',
    location_name: 'CSG1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2020-10-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 123,
    location_code: '1254',
    location_name: 'CVG1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2005-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 125,
    location_code: '1255',
    location_name: 'CVG2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2005-11-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 126,
    location_code: '1256',
    location_name: 'CVG3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'IXD',
    launch_date: '2007-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90071,
    location_code: '7Z99',
    location_name: 'DAB2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2025-03-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85069,
    location_code: '1W73',
    location_name: 'DAL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2020-10-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 133,
    location_code: '2068',
    location_name: 'DAL3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-05-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 146,
    location_code: '2449',
    location_name: 'DCA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 82746,
    location_code: '1S32',
    location_name: 'DCA6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-08-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 94132,
    location_code: '2W69',
    location_name: 'DCA7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2024-04-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 91450,
    location_code: '2X39',
    location_name: 'DCW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'DeliveryStation',
    sub_group_type: 'DeliveryStation ',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 153,
    location_code: '0000',
    location_name: 'DCXX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Dropship',
    sub_group_type: 'Dropship',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 84001,
    location_code: '2G06',
    location_name: 'DDX3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 159,
    location_code: '2423',
    location_name: 'DEN2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 160,
    location_code: '2443',
    location_name: 'DEN3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-05-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 68679,
    location_code: '1030',
    location_name: 'DEN4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-07-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 82734,
    location_code: '1W30',
    location_name: 'DEN7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-06-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85723,
    location_code: '1Y66',
    location_name: 'DEN8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2022-01-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 90847,
    location_code: '1Y61',
    location_name: 'DEN9',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2025-02-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 163,
    location_code: '2422',
    location_name: 'DET1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-09-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 164,
    location_code: '2533',
    location_name: 'DET2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2019-04-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 68687,
    location_code: '1N50',
    location_name: 'DET3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-09-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85753,
    location_code: '1I68',
    location_name: 'DET6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-10-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98753,
    location_code: '3G72',
    location_name: 'DET7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 167,
    location_code: '1441',
    location_name: 'DFW6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort-AR',
    building_type: 'AR Nonsortable',
    launch_date: '2013-08-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 168,
    location_code: '1567',
    location_name: 'DFW7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2013-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85656,
    location_code: '1562',
    location_name: 'DFW8',
    continent: 'NA',
    country: 'US',
    sub_group: 'SortCenter',
    sub_group_type: 'SortCenter',
    building_type: 'Reverse Logistics',
    launch_date: '2021-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 68728,
    location_code: '2127',
    location_name: 'DFW9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2016-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 67485,
    location_code: '1L52',
    location_name: 'DGI1',
    continent: 'NA',
    country: 'US',
    sub_group: 'DeliveryStation',
    sub_group_type: 'DeliveryStation',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 84086,
    location_code: '1S95',
    location_name: 'DJZ5',
    continent: 'NA',
    country: 'US',
    sub_group: 'DeliveryStation',
    sub_group_type: 'DeliveryStation',
    building_type: 'Kariba',
    launch_date: '2023-11-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 108110,
    location_code: '0000',
    location_name: 'DLAX',
    continent: 'NA',
    country: '',
    sub_group: 'N',
    sub_group_type: 'DROPSHIP',
    building_type: 'DLA',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85657,
    location_code: '2693',
    location_name: 'DPA7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-12-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 203,
    location_code: '0000',
    location_name: 'DROP',
    continent: 'EU',
    country: 'IT',
    sub_group: 'Dropship',
    sub_group_type: 'Dropship',
    building_type: 'Dropship',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 82747,
    location_code: '1T86',
    location_name: 'DSM5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-12-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 221,
    location_code: '2518',
    location_name: 'DTW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-05-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85620,
    location_code: '1Y87',
    location_name: 'DTW3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-08-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 67458,
    location_code: '1L25',
    location_name: 'DVB6',
    continent: 'NA',
    country: 'US',
    sub_group: 'DeliveryStation',
    sub_group_type: 'DeliveryStation ',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 84420,
    location_code: '2F38',
    location_name: 'DYR2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98211,
    location_code: '7Z74',
    location_name: 'EAU1',
    continent: 'IN',
    country: 'IN',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Nonsortable',
    launch_date: '2022-07-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85584,
    location_code: '1I91',
    location_name: 'ELP1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-11-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 231,
    location_code: '1569',
    location_name: 'EWR4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2014-07-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85658,
    location_code: '8J08',
    location_name: 'EWR7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 235,
    location_code: '1891',
    location_name: 'EWR9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2015-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85682,
    location_code: '1Y96',
    location_name: 'FAR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Reverse Logistics',
    launch_date: '2021-09-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 236,
    location_code: '2441',
    location_name: 'FAT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85586,
    location_code: '1I36',
    location_name: 'FAT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-08-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 89868,
    location_code: '3G26',
    location_name: 'FAT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'SortCenter',
    sub_group_type: 'SortCenter',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85684,
    location_code: '1I55',
    location_name: 'FOE1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-09-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 238,
    location_code: '0000',
    location_name: 'FPA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 239,
    location_code: '0000',
    location_name: 'FPA2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85514,
    location_code: '0000',
    location_name: 'FPA3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85685,
    location_code: '1I70',
    location_name: 'FSD1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-01-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 243,
    location_code: '1890',
    location_name: 'FTW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2015-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 244,
    location_code: '1854',
    location_name: 'FTW2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2016-09-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 245,
    location_code: '1858',
    location_name: 'FTW3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2019-11-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 246,
    location_code: '1W72',
    location_name: 'FTW4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'Quick Deploy',
    launch_date: '2019-11-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 85686,
    location_code: '1I60',
    location_name: 'FTW5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-06-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 247,
    location_code: '2415',
    location_name: 'FTW6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-02-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 249,
    location_code: '1793',
    location_name: 'FTW8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Supplemental',
    launch_date: '2019-09-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 250,
    location_code: '1H01',
    location_name: 'FTW9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2019-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 85725,
    location_code: '1I04',
    location_name: 'FWA4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2021-12-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85687,
    location_code: '2W95',
    location_name: 'FWA6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 251,
    location_code: '2072',
    location_name: 'GEG1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-06-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85636,
    location_code: '1Y15',
    location_name: 'GEG2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-09-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 94953,
    location_code: '3G78',
    location_name: 'GEG5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 253,
    location_code: '2073',
    location_name: 'GRR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-03-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 92753,
    location_code: '3G79',
    location_name: 'GRR5',
    continent: 'NA',
    country: '',
    sub_group: 'Standalone',
    sub_group_type: 'unknown',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 255,
    location_code: '2135',
    location_name: 'GSO1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-07-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 101193,
    location_code: '7Z17',
    location_name: 'GSO3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2024-06-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 256,
    location_code: '1444',
    location_name: 'GSP1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2012-09-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 84464,
    location_code: '1W21',
    location_name: 'GYR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-11-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 84465,
    location_code: '1W22',
    location_name: 'GYR2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2021-10-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85049,
    location_code: '1V73',
    location_name: 'GYR3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2020-12-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85659,
    location_code: '7698',
    location_name: 'HCA6',
    continent: 'NA',
    country: 'US Hubs',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-12-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92794,
    location_code: '5M22',
    location_name: 'HDC3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'SSD DC',
    launch_date: '2022-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 93954,
    location_code: '3H95',
    location_name: 'HEA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 92419,
    location_code: '3H92',
    location_name: 'HEA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'TS-Softlines',
    launch_date: '2022-10-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 107852,
    location_code: '3W91',
    location_name: 'HGA3',
    continent: 'NA',
    country: 'US',
    sub_group: 'AmazonFresh',
    sub_group_type: 'Fresh',
    building_type: 'Supplemental',
    launch_date: '2023-02-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 93938,
    location_code: '5M17',
    location_name: 'HGA6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'SSD DC',
    launch_date: '2022-01-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 85622,
    location_code: '1I12',
    location_name: 'HGR2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-04-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 109793,
    location_code: '8A34',
    location_name: 'HGR5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Kariba',
    launch_date: '2023-11-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 102671,
    location_code: '5T11',
    location_name: 'HIO2',
    continent: 'N',
    country: 'US',
    sub_group: 'MOD',
    sub_group_type: 'MOD',
    building_type: 'MOD',
    launch_date: '2023-02-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'MOD',
  },
  {
    site_sk: 101821,
    location_code: '8A97',
    location_name: 'HIO3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Reverse Logistics',
    launch_date: '2023-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 258,
    location_code: '2212',
    location_name: 'HIO9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 89980,
    location_code: '3H96',
    location_name: 'HMW4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2022-10-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 92043,
    location_code: '5M16',
    location_name: 'HNC3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2023-01-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 107851,
    location_code: '0000',
    location_name: 'HNW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2022-10-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 261,
    location_code: '2408',
    location_name: 'HOU2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2017-07-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 262,
    location_code: '2435',
    location_name: 'HOU3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Reverse Logistics',
    launch_date: '2018-03-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85689,
    location_code: '1V61',
    location_name: 'HOU6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-10-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 82745,
    location_code: '1W28',
    location_name: 'HOU7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-08-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85690,
    location_code: '1Y90',
    location_name: 'HOU8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-08-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85509,
    location_code: '0000',
    location_name: 'HOU9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-10-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 92746,
    location_code: '5M19',
    location_name: 'HSF6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2023-11-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85691,
    location_code: '1Y91',
    location_name: 'HSV1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-10-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 98075,
    location_code: '7Z72',
    location_name: 'HSV2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2023-03-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 94434,
    location_code: '5M18',
    location_name: 'HWA4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Kariba',
    launch_date: '2023-11-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 90657,
    location_code: '3H98',
    location_name: 'HWE2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2023-01-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 101354,
    location_code: '7Z08',
    location_name: 'IAG1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2025-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85502,
    location_code: '1I21',
    location_name: 'IAH1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy',
    building_type: 'Quick Deploy',
    launch_date: '2020-09-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 91910,
    location_code: '2W78',
    location_name: 'IAH3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable',
    launch_date: '2024-05-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 102636,
    location_code: '0000',
    location_name: 'ICQ2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85692,
    location_code: '1I56',
    location_name: 'ICT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-08-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 283,
    location_code: '7612',
    location_name: 'IFL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 284,
    location_code: '7613',
    location_name: 'IFL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85693,
    location_code: '3K03',
    location_name: 'IGQ1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-10-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85694,
    location_code: '1I93',
    location_name: 'IGQ2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-05-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85637,
    location_code: '1I73',
    location_name: 'ILG1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-10-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 102015,
    location_code: '8A98',
    location_name: 'ILM1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2023-09-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 285,
    location_code: '7610',
    location_name: 'IMA1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 286,
    location_code: '7611',
    location_name: 'IMA2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 287,
    location_code: '1413',
    location_name: 'IND1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Sortable',
    launch_date: '2010-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 288,
    location_code: '1414',
    location_name: 'IND2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2008-12-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 289,
    location_code: '1415',
    location_name: 'IND3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Specialty',
    building_type: 'Specialty',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 290,
    location_code: '1429',
    location_name: 'IND4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2011-06-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 291,
    location_code: '1435',
    location_name: 'IND5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2011-08-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 292,
    location_code: '1498',
    location_name: 'IND7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: '2020-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 293,
    location_code: '2098',
    location_name: 'IND8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 294,
    location_code: '2119',
    location_name: 'IND9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2019-09-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 304,
    location_code: '2845',
    location_name: 'IOR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 305,
    location_code: '0000',
    location_name: 'IOR2',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 314,
    location_code: '0000',
    location_name: 'ITX2',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 316,
    location_code: '0000',
    location_name: 'IVSA',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 317,
    location_code: '0000',
    location_name: 'IVSB',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 318,
    location_code: '7736',
    location_name: 'IVSC',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 320,
    location_code: '7761',
    location_name: 'IVSE',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 322,
    location_code: '0000',
    location_name: 'IVSI',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 323,
    location_code: '0000',
    location_name: 'IVSJ',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 324,
    location_code: '0000',
    location_name: 'IVSX',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Small & Light',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 326,
    location_code: '0000',
    location_name: 'IXD0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 82748,
    location_code: '0000',
    location_name: 'IXD5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'IXD',
    launch_date: '2020-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85427,
    location_code: '0000',
    location_name: 'IXD6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'IXD',
    launch_date: '2020-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85596,
    location_code: '1I58',
    location_name: 'JAN1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-07-24',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 328,
    location_code: '2407',
    location_name: 'JAX2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2017-08-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 329,
    location_code: '2436',
    location_name: 'JAX3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 331,
    location_code: '5953',
    location_name: 'JAX6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85696,
    location_code: '1I74',
    location_name: 'JAX7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2021-11-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 338,
    location_code: '2523',
    location_name: 'JFK8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 102123,
    location_code: '3G69',
    location_name: 'JHW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Reverse Logistics',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 84498,
    location_code: '1V59',
    location_name: 'JVL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-08-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 817,
    location_code: '9J05',
    location_name: 'KRB1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Kariba',
    building_type: 'Kariba',
    launch_date: '2019-11-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85437,
    location_code: '1I94',
    location_name: 'KRB2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Kariba',
    building_type: 'Kariba',
    launch_date: '2020-07-29',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85598,
    location_code: '4I19',
    location_name: 'KRB3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Kariba',
    launch_date: '2021-11-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85599,
    location_code: '1I51',
    location_name: 'KRB4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Kariba',
    building_type: 'Kariba',
    launch_date: '2021-04-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98761,
    location_code: '4I34',
    location_name: 'KRB5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2021-10-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98754,
    location_code: '2447',
    location_name: 'KRB6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2021-11-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98097,
    location_code: '7Z73',
    location_name: 'KRB7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2022-09-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 101084,
    location_code: '7Z13',
    location_name: 'KRB9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2023-05-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98755,
    location_code: '3G75',
    location_name: 'LAL4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-10-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 98142,
    location_code: '7Z76',
    location_name: 'LAN2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2024-03-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 70727,
    location_code: '1B66',
    location_name: 'LAS1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2020-07-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 382,
    location_code: '1461',
    location_name: 'LAS2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2008-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 383,
    location_code: '1162',
    location_name: 'LAS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'SortCenter',
    sub_group_type: 'SortCenter',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 384,
    location_code: '2427',
    location_name: 'LAS6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 385,
    location_code: '2062',
    location_name: 'LAS7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-05-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 390,
    location_code: '2078',
    location_name: 'LAX9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2019-06-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 392,
    location_code: '1250',
    location_name: 'LEX1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '1999-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 393,
    location_code: '1251',
    location_name: 'LEX2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2004-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 394,
    location_code: '1479',
    location_name: 'LEX3',
    continent: 'NA',
    country: 'US',
    sub_group: 'MOD',
    sub_group_type: 'MOD',
    building_type: 'MOD',
    launch_date: '2013-02-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85503,
    location_code: '0000',
    location_name: 'LEX6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Reverse Logistics',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85697,
    location_code: '1Y71',
    location_name: 'LFT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-10-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 395,
    location_code: '1828',
    location_name: 'LGA7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy-AR',
    building_type: 'Quick Deploy',
    launch_date: '2016-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 396,
    location_code: '1828',
    location_name: 'LGA8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy-AR',
    building_type: 'Quick Deploy',
    launch_date: '2016-09-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 397,
    location_code: '2417',
    location_name: 'LGA9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2017-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 399,
    location_code: '2416',
    location_name: 'LGB3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-03-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 400,
    location_code: '2418',
    location_name: 'LGB4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-06-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 607,
    location_code: '1851',
    location_name: 'LGB5',
    continent: 'NA',
    country: 'US',
    sub_group: 'SortCenter',
    sub_group_type: 'SortCenter',
    building_type: 'Kariba',
    launch_date: '2016-04-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 401,
    location_code: '2429',
    location_name: 'LGB6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2018-03-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 402,
    location_code: '2438',
    location_name: 'LGB7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-06-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 403,
    location_code: '2434',
    location_name: 'LGB8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2017-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 82735,
    location_code: '1V53',
    location_name: 'LGB9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-08-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 84521,
    location_code: '1V56',
    location_name: 'LIT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-08-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85601,
    location_code: '1I14',
    location_name: 'LIT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-09-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 92465,
    location_code: '2W72',
    location_name: 'LUK2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-07-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85508,
    location_code: '1Y65',
    location_name: 'LUK7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-10-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 98839,
    location_code: '7Z46',
    location_name: 'MCC1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'IXD',
    launch_date: '2023-05-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 90550,
    location_code: '1Y21',
    location_name: 'MCE1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2022-09-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85700,
    location_code: '1Y97',
    location_name: 'MCI2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-10-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98539,
    location_code: '7Z53',
    location_name: 'MCI3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2023-03-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85660,
    location_code: '8051',
    location_name: 'MCI7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-11-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 407,
    location_code: '2446',
    location_name: 'MCO1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 71132,
    location_code: '8023',
    location_name: 'MCO2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-09-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85661,
    location_code: '7741',
    location_name: 'MCO6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-12-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 409,
    location_code: '1449',
    location_name: 'MDT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2014-10-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 410,
    location_code: '2448',
    location_name: 'MDT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2017-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 411,
    location_code: '1803',
    location_name: 'MDT3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 95271,
    location_code: '7Z96',
    location_name: 'MDT4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2023-06-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 101133,
    location_code: '7Z12',
    location_name: 'MDT6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2025-03-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 102016,
    location_code: '8A90',
    location_name: 'MDT7',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Reverse Logistics',
    launch_date: '2022-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 92313,
    location_code: '3G80',
    location_name: 'MDT8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: '2022-11-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 109770,
    location_code: '8A35',
    location_name: 'MDT9',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Kariba',
    launch_date: '2023-11-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 412,
    location_code: '1889',
    location_name: 'MDW2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2015-08-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 413,
    location_code: '0000',
    location_name: 'MDW3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'MOD',
    launch_date: '2019-06-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 414,
    location_code: '1847',
    location_name: 'MDW4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable',
    launch_date: '2016-10-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 416,
    location_code: '2305',
    location_name: 'MDW6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-09-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 417,
    location_code: '2405',
    location_name: 'MDW7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2017-08-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 418,
    location_code: '2428',
    location_name: 'MDW8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2017-07-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 419,
    location_code: '2420',
    location_name: 'MDW9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 421,
    location_code: '2035',
    location_name: 'MEM1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2018-10-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 422,
    location_code: '2120',
    location_name: 'MEM2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Softlines',
    launch_date: '2019-07-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 85501,
    location_code: '1I49',
    location_name: 'MEM3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-11-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 82751,
    location_code: '1T81',
    location_name: 'MEM4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 82752,
    location_code: '1T82',
    location_name: 'MEM6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-08-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85624,
    location_code: '7Z93',
    location_name: 'MEM8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-09-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 429,
    location_code: '2403',
    location_name: 'MGE1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2016-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 431,
    location_code: '2520',
    location_name: 'MGE3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 433,
    location_code: '3P92',
    location_name: 'MGE7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'Quick Deploy',
    launch_date: '2018-08-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 100761,
    location_code: '7Z23',
    location_name: 'MGM2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'IXD',
    launch_date: '2025-05-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 434,
    location_code: '2445',
    location_name: 'MIA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 436,
    location_code: '1V62',
    location_name: 'MIA7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Reverse Logistics',
    launch_date: '2018-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 439,
    location_code: '1852',
    location_name: 'MKC4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-07-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 440,
    location_code: '2406',
    location_name: 'MKC6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2017-07-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 441,
    location_code: '1553',
    location_name: 'MKE1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2015-05-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 71191,
    location_code: '2115',
    location_name: 'MKE2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-10-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 101219,
    location_code: '7Z20',
    location_name: 'MKE3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2025-06-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 93257,
    location_code: '7Z83',
    location_name: 'MLI1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2024-03-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85512,
    location_code: '0000',
    location_name: 'MMU9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-10-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 444,
    location_code: '1159',
    location_name: 'MOB9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85438,
    location_code: '1I98',
    location_name: 'MQJ1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2020-10-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85485,
    location_code: '3A06',
    location_name: 'MQJ2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-07-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 84534,
    location_code: '1T80',
    location_name: 'MQY1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-06-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 110601,
    location_code: '0000',
    location_name: 'MSFC',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'FPA',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 71236,
    location_code: '1123',
    location_name: 'MSN4',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'Specialty',
    building_type: 'Shopbop',
    launch_date: '2018-04-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 445,
    location_code: '1124',
    location_name: 'MSN5',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'Softlines',
    building_type: 'Shopbop',
    launch_date: '2018-08-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 82753,
    location_code: '1S58',
    location_name: 'MSN6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2020-08-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 446,
    location_code: '1495',
    location_name: 'MSP1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2016-07-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85617,
    location_code: '1I13',
    location_name: 'MSP6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-08-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85702,
    location_code: '1W27',
    location_name: 'MTN1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-09-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98756,
    location_code: '3G88',
    location_name: 'MTN3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-10-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85510,
    location_code: '',
    location_name: 'MTN7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-11-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 106827,
    location_code: '3G89',
    location_name: 'MTN8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98264,
    location_code: '7Z54',
    location_name: 'MWH1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2023-05-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85724,
    location_code: '0000',
    location_name: 'N2120',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2021-10-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 458,
    location_code: '0000',
    location_name: 'NARX',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NodeHedge',
  },
  {
    site_sk: 85664,
    location_code: '0000',
    location_name: 'NA_DEV',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Support',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 459,
    location_code: '0000',
    location_name: 'NON0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 71575,
    location_code: '3001',
    location_name: 'OAK1',
    continent: 'NA',
    country: '',
    sub_group: 'N',
    sub_group_type: 'unknown',
    building_type: 'Nonsortable',
    launch_date: '2022-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AGL IXD',
  },
  {
    site_sk: 463,
    location_code: '1440',
    location_name: 'OAK3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort-AR',
    building_type: 'AR Nonsortable',
    launch_date: '2013-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 464,
    location_code: '1568',
    location_name: 'OAK4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2013-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85662,
    location_code: '1824',
    location_name: 'OAK7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2015-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 71578,
    location_code: '1860',
    location_name: 'OAK9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-11-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 475,
    location_code: '2066',
    location_name: 'OKC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-08-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85605,
    location_code: '1I25',
    location_name: 'OKC2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-08-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 476,
    location_code: '1B67',
    location_name: 'OKC3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'MOD',
    launch_date: '2019-08-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 478,
    location_code: '1163',
    location_name: 'OKC9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85750,
    location_code: '7Z92',
    location_name: 'OLM1',
    continent: 'NA',
    country: 'United States',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2021-08-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85632,
    location_code: '1I80',
    location_name: 'OMA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-01-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 93565,
    location_code: '3G91',
    location_name: 'OMA5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 480,
    location_code: '1422',
    location_name: 'ONT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Sortable',
    launch_date: '2012-10-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 483,
    location_code: '1453',
    location_name: 'ONT6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2014-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 484,
    location_code: '1801',
    location_name: 'ONT8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2014-08-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 485,
    location_code: '1448',
    location_name: 'ONT9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2014-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 486,
    location_code: '0000',
    location_name: 'ORD1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 71701,
    location_code: '2097',
    location_name: 'ORD2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-08-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 487,
    location_code: '8268',
    location_name: 'ORD3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85704,
    location_code: '1S57',
    location_name: 'ORD5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-10-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 71715,
    location_code: '1N53',
    location_name: 'ORF2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2022-07-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85607,
    location_code: '1320',
    location_name: 'ORF3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-08-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 101760,
    location_code: '8A91',
    location_name: 'ORF4',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2025-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 91027,
    location_code: '3G93',
    location_name: 'ORF5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 85707,
    location_code: '1I71',
    location_name: 'ORH1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2021-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92778,
    location_code: '7Z97',
    location_name: 'ORH3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2024-07-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85708,
    location_code: '1I90',
    location_name: 'OXR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-01-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 95190,
    location_code: '1I59',
    location_name: 'PAE2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-05-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85618,
    location_code: '1I27',
    location_name: 'PBI2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 91977,
    location_code: '7Z94',
    location_name: 'PBI3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2025-03-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 495,
    location_code: '1343',
    location_name: 'PCA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-07-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 71801,
    location_code: '1348',
    location_name: 'PCA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2020-09-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 496,
    location_code: '2564',
    location_name: 'PCA3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: '2018-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 101085,
    location_code: '8A92',
    location_name: 'PCT2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Kariba',
    launch_date: '2023-03-05',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 71807,
    location_code: '1033',
    location_name: 'PCW1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98757,
    location_code: '3B54',
    location_name: 'PDK2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 498,
    location_code: '2527',
    location_name: 'PDX6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2019-07-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 499,
    location_code: '2528',
    location_name: 'PDX7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2019-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 90540,
    location_code: '3A42',
    location_name: 'PDX8',
    continent: 'NA',
    country: '',
    sub_group: 'N',
    sub_group_type: 'Merch by Amazon',
    building_type: 'AR Sortable',
    launch_date: '2025-03-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 500,
    location_code: '2444',
    location_name: 'PDX9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 501,
    location_code: '2536',
    location_name: 'PFL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable Supplemental',
    launch_date: '2023-09-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98225,
    location_code: '4A29',
    location_name: 'PFL3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Supplemental',
    launch_date: '2021-09-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 71960,
    location_code: '1347',
    location_name: 'PGA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-10-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 502,
    location_code: '1200',
    location_name: 'PHL1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2019-08-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 504,
    location_code: '1203',
    location_name: 'PHL4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2005-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 505,
    location_code: '1204',
    location_name: 'PHL5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2005-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 506,
    location_code: '1206',
    location_name: 'PHL6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2010-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 507,
    location_code: '1437',
    location_name: 'PHL7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Sortable',
    launch_date: '2012-10-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 510,
    location_code: '1352',
    location_name: 'PHX3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2010-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 511,
    location_code: '1306',
    location_name: 'PHX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2008-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 512,
    location_code: '1307',
    location_name: 'PHX6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable',
    launch_date: '2010-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 513,
    location_code: '1433',
    location_name: 'PHX7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2011-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 515,
    location_code: '1798',
    location_name: 'PHX9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Sortable Supplemental',
    launch_date: '2023-09-24',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 516,
    location_code: '2524',
    location_name: 'PIL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'IXD',
    launch_date: '2020-09-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 517,
    location_code: '1794',
    location_name: 'PIN1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Sortable Supplemental',
    launch_date: '2023-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 71993,
    location_code: '2426',
    location_name: 'PIT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-09-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 93359,
    location_code: '3G22',
    location_name: 'PIT4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: '2022-11-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 98187,
    location_code: '4A28',
    location_name: 'PKC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable Supplemental',
    launch_date: '2023-09-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 93138,
    location_code: '7Z86',
    location_name: 'PMD1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 523,
    location_code: '1342',
    location_name: 'POH1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: '2020-09-29',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98158,
    location_code: '4A64',
    location_name: 'POH2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable Supplemental',
    launch_date: '2023-09-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 524,
    location_code: '1345',
    location_name: 'PPA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98199,
    location_code: '4A27',
    location_name: 'PPA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Supplemental',
    launch_date: '2023-09-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 525,
    location_code: '0000',
    location_name: 'PQA1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93076,
    location_code: '2W93',
    location_name: 'PSC2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2024-04-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 526,
    location_code: '2076',
    location_name: 'PSP1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-09-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98978,
    location_code: '7Z47',
    location_name: 'PSP3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'IXD',
    launch_date: '2025-07-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 527,
    location_code: '1341',
    location_name: 'PTX1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Sortable Supplemental',
    launch_date: '2023-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 94949,
    location_code: '3P99',
    location_name: 'PVD2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2024-07-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 528,
    location_code: '1344',
    location_name: 'PWA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Supplemental',
    building_type: 'Supplemental',
    launch_date: '2018-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 529,
    location_code: '0000',
    location_name: 'PXX4',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 530,
    location_code: '0000',
    location_name: 'PXX5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 531,
    location_code: '0000',
    location_name: 'PXX6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 532,
    location_code: '0000',
    location_name: 'PXX7',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 533,
    location_code: '0000',
    location_name: 'PXX8',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 534,
    location_code: '0000',
    location_name: 'PXX9',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90593,
    location_code: '3Q04',
    location_name: 'QXX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Supplemental',
    launch_date: '2023-01-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 91351,
    location_code: '3Q05',
    location_name: 'QXX6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2022-09-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 92512,
    location_code: '3Q08',
    location_name: 'QXX9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2022-09-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 90169,
    location_code: '3Q16',
    location_name: 'QXY8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Supplemental',
    launch_date: '2022-10-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 90060,
    location_code: '3Q17',
    location_name: 'QXY9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Kariba',
    launch_date: '2022-09-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98758,
    location_code: '0000',
    location_name: 'RAC2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-10-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85434,
    location_code: '1I96',
    location_name: 'RDG1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2020-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 85486,
    location_code: '1I84',
    location_name: 'RDG2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Special Handling',
    launch_date: '2020-09-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 548,
    location_code: '2071',
    location_name: 'RDU1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-08-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 93321,
    location_code: '1I15',
    location_name: 'RDU2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable',
    launch_date: '2024-03-24',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 98558,
    location_code: '7Z58',
    location_name: 'RDU4',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2024-06-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 550,
    location_code: '0000',
    location_name: 'REL0',
    continent: 'IN',
    country: 'IN',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 551,
    location_code: '0000',
    location_name: 'RELX',
    continent: 'IN',
    country: 'IN',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 93610,
    location_code: '2W71',
    location_name: 'RFD2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable',
    launch_date: '2025-04-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 101083,
    location_code: '7Z01',
    location_name: 'RFD3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2022-09-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98454,
    location_code: '7Z61',
    location_name: 'RFD4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2022-11-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 107976,
    location_code: '3W75',
    location_name: 'RFD7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: '2022-11-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 552,
    location_code: '1443',
    location_name: 'RIC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2012-09-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 553,
    location_code: '1436',
    location_name: 'RIC2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Sortable',
    launch_date: '2012-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 554,
    location_code: '8239',
    location_name: 'RIC3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2019-08-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 94809,
    location_code: '1Y67',
    location_name: 'RIC4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-09-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 100732,
    location_code: '7Z22',
    location_name: 'RIC6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2025-07-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 556,
    location_code: '1165',
    location_name: 'RIC9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 557,
    location_code: '1300',
    location_name: 'RNO1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 558,
    location_code: '2802',
    location_name: 'RNO3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 559,
    location_code: '1475',
    location_name: 'RNO4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2015-01-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 92388,
    location_code: '2C99',
    location_name: 'ROC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-08-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 92752,
    location_code: '3G96',
    location_name: 'ROC5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 107867,
    location_code: '8A42',
    location_name: 'RYY2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Kariba',
    launch_date: '2024-03-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 91950,
    location_code: '3G98',
    location_name: 'SAC5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 72669,
    location_code: '2124',
    location_name: 'SAN3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-09-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 90443,
    location_code: '2W70',
    location_name: 'SAN6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2024-05-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 563,
    location_code: '1442',
    location_name: 'SAT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort-AR',
    building_type: 'AR Nonsortable',
    launch_date: '2013-09-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 564,
    location_code: '1499',
    location_name: 'SAT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2016-08-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85754,
    location_code: '1I48',
    location_name: 'SAT3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-03-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85709,
    location_code: '1Y88',
    location_name: 'SAT4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-09-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85623,
    location_code: '1Y14',
    location_name: 'SAT6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-09-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 107981,
    location_code: '3W74',
    location_name: 'SAT7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: '2022-11-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 567,
    location_code: '2531',
    location_name: 'SAV3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2019-07-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 94026,
    location_code: '1Y94',
    location_name: 'SAV4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-05-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85710,
    location_code: '1Y89',
    location_name: 'SAV5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2021-09-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 101086,
    location_code: '8A93',
    location_name: 'SAV7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Kariba',
    launch_date: '2023-04-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 73088,
    location_code: '1N16',
    location_name: 'SAX1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-11-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73089,
    location_code: '1N17',
    location_name: 'SAX2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-06-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 95020,
    location_code: '5M09',
    location_name: 'SAX3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-09-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 93845,
    location_code: '5M11',
    location_name: 'SAX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2023-01-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 69752,
    location_code: '6104',
    location_name: 'SAZ1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2019-09-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98420,
    location_code: '5M39',
    location_name: 'SAZ2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 85711,
    location_code: '1T87',
    location_name: 'SBD1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2021-08-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 84589,
    location_code: '1V58',
    location_name: 'SBD2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-08-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85488,
    location_code: '1I77',
    location_name: 'SBD3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2020-08-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 92929,
    location_code: '1Y76',
    location_name: 'SBD4',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2026-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 92287,
    location_code: '1I72',
    location_name: 'SBD6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2024-04-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98930,
    location_code: '7Z51',
    location_name: 'SBN1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2024-11-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 72714,
    location_code: '1N22',
    location_name: 'SCA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2023-08-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 72715,
    location_code: '1N23',
    location_name: 'SCA3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 72716,
    location_code: '1M65',
    location_name: 'SCA4',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-10-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 72717,
    location_code: '1M72',
    location_name: 'SCA5',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-06-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 94635,
    location_code: '5M26',
    location_name: 'SCA7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-11-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 569,
    location_code: '2136',
    location_name: 'SCK1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-05-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 82736,
    location_code: '1V54',
    location_name: 'SCK3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-06-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85712,
    location_code: '1I83',
    location_name: 'SCK4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2021-10-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 93868,
    location_code: '1I20',
    location_name: 'SCK6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-01-29',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 98411,
    location_code: '7Z70',
    location_name: 'SCK7',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'IXD',
    launch_date: '2023-05-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 99028,
    location_code: '7Z43',
    location_name: 'SCK8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable',
    launch_date: '2023-04-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AGL-IXD',
  },
  {
    site_sk: 72751,
    location_code: '1M70',
    location_name: 'SCO1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-10-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 571,
    location_code: '2008',
    location_name: 'SDC1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2020-01-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 572,
    location_code: '1100',
    location_name: 'SDF1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '1999-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 573,
    location_code: '1101',
    location_name: 'SDF2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Softlines',
    launch_date: '2005-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 574,
    location_code: '2606',
    location_name: 'SDF4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2012-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 575,
    location_code: '2607',
    location_name: 'SDF6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2011-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 576,
    location_code: '2608',
    location_name: 'SDF7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 577,
    location_code: '1423',
    location_name: 'SDF8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Softlines',
    building_type: 'Softlines',
    launch_date: '2012-10-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 578,
    location_code: '2613',
    location_name: 'SDF9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2014-04-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 98759,
    location_code: '3G65',
    location_name: 'SDL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 92839,
    location_code: '3G21',
    location_name: 'SDM4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'Reverse Logistics',
    launch_date: '2022-11-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 109831,
    location_code: '8A36',
    location_name: 'SFB2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Kariba',
    launch_date: '2024-05-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 98444,
    location_code: '6J03',
    location_name: 'SFL1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2019-10-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 72905,
    location_code: '1M62',
    location_name: 'SFL2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-05-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 72906,
    location_code: '1M63',
    location_name: 'SFL3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-08-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 90082,
    location_code: '9M31',
    location_name: 'SFL4',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-06-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98098,
    location_code: '5M32',
    location_name: 'SFL6',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'SSD',
    launch_date: '2023-06-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98331,
    location_code: '5M42',
    location_name: 'SFL7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-12-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98255,
    location_code: '5M43',
    location_name: 'SFL8',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'SSD',
    launch_date: '2023-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 592,
    location_code: '8269',
    location_name: 'SFO3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 72955,
    location_code: '1M68',
    location_name: 'SGA1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-12-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 94693,
    location_code: '9M30',
    location_name: 'SGA2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-08-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 90209,
    location_code: '1Y68',
    location_name: 'SHV1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-11-12',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 72997,
    location_code: '8021',
    location_name: 'SIL1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-08-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 72998,
    location_code: '1N21',
    location_name: 'SIL2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-04-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 90042,
    location_code: '9M22',
    location_name: 'SIL3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-06-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98327,
    location_code: '5M46',
    location_name: 'SIL4',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'SSD',
    launch_date: '2022-12-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 91007,
    location_code: '5M05',
    location_name: 'SIN9',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-11-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 596,
    location_code: '1857',
    location_name: 'SJC7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-09-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 99314,
    location_code: '7Z37',
    location_name: 'SJC8',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AR Sortable',
    launch_date: '2023-09-10',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 109653,
    location_code: '0000',
    location_name: 'SLA3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 85426,
    location_code: '2L11',
    location_name: 'SLA5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed-AR',
    building_type: 'MCF',
    launch_date: '2020-01-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'MCF',
  },
  {
    site_sk: 597,
    location_code: '2519',
    location_name: 'SLC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2018-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 73094,
    location_code: '1B65',
    location_name: 'SLC2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-08-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 598,
    location_code: '8270',
    location_name: 'SLC3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2019-08-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 73113,
    location_code: '2013',
    location_name: 'SMA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2023-09-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73114,
    location_code: '1N24',
    location_name: 'SMA2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-01-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73118,
    location_code: '1K22',
    location_name: 'SMD1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2020-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 600,
    location_code: '2409',
    location_name: 'SMF1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2017-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 602,
    location_code: '2525',
    location_name: 'SMF3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2018-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85613,
    location_code: '1I35',
    location_name: 'SMF6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-07-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 85619,
    location_code: '1I26',
    location_name: 'SMF7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2021-07-11',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85751,
    location_code: '7Z89',
    location_name: 'SMF8',
    continent: 'NA',
    country: 'United States',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 604,
    location_code: '1158',
    location_name: 'SMF9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2018-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 84600,
    location_code: '1N91',
    location_name: 'SMI1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-06-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 84601,
    location_code: '1N89',
    location_name: 'SMN1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-07-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 93571,
    location_code: '5M29',
    location_name: 'SMO1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98106,
    location_code: '5M34',
    location_name: 'SMO2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-03-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 605,
    location_code: '1799',
    location_name: 'SNA4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2015-11-04',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 606,
    location_code: '1850',
    location_name: 'SNA6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy-AR',
    building_type: 'Quick Deploy',
    launch_date: '2016-06-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 608,
    location_code: '8027',
    location_name: 'SNA8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'QuickDeploy-AR',
    building_type: 'Quick Deploy',
    launch_date: '2016-04-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 609,
    location_code: '0000',
    location_name: 'SNA9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'Quick Deploy',
    launch_date: '2016-07-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93849,
    location_code: '5M02',
    location_name: 'SNC2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-06-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 90604,
    location_code: '5M01',
    location_name: 'SNC3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-07-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73143,
    location_code: '1M67',
    location_name: 'SNJ1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 91606,
    location_code: '9M12',
    location_name: 'SNJ2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2023-03-29',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 90319,
    location_code: '9M13',
    location_name: 'SNJ3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 91270,
    location_code: '5M08',
    location_name: 'SNV1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-12-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73148,
    location_code: '1N19',
    location_name: 'SNY2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2023-08-23',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 91750,
    location_code: '5M13',
    location_name: 'SNY5',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 610,
    location_code: '0000',
    location_name: 'SOF0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 94325,
    location_code: '9M33',
    location_name: 'SOH1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-09-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 90243,
    location_code: '5M07',
    location_name: 'SOH2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-11-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98168,
    location_code: '5M31',
    location_name: 'SOH3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-02-09',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73152,
    location_code: '7360',
    location_name: 'SOR3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 818,
    location_code: '0000',
    location_name: 'SSD1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 101600,
    location_code: '0000',
    location_name: 'SSDX',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85714,
    location_code: '1I11',
    location_name: 'STL3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 612,
    location_code: '1853',
    location_name: 'STL4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2016-08-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 614,
    location_code: '1862',
    location_name: 'STL6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2016-07-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 615,
    location_code: '0000',
    location_name: 'STL7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'Quick Deploy',
    launch_date: '2016-07-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 616,
    location_code: '2063',
    location_name: 'STL8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-07-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 617,
    location_code: '2010',
    location_name: 'STN1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2020-04-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98760,
    location_code: '3G87',
    location_name: 'STP2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2021-10-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 73199,
    location_code: '1042',
    location_name: 'STX2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2019-10-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73200,
    location_code: '1M61',
    location_name: 'STX3',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-04-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73201,
    location_code: '1M64',
    location_name: 'STX4',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-07-14',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73202,
    location_code: '1M71',
    location_name: 'STX5',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-09-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 91934,
    location_code: '9M32',
    location_name: 'STX6',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-12-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 94224,
    location_code: '5M04',
    location_name: 'STX7',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-09-29',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98492,
    location_code: '5M53',
    location_name: 'STX8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'unknown',
    building_type: 'SSD',
    launch_date: '2022-12-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 98308,
    location_code: '5M54',
    location_name: 'STX9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2023-02-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 93163,
    location_code: '5M03',
    location_name: 'SUT1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2021-12-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73205,
    location_code: '1M69',
    location_name: 'SVA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed-AR',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 619,
    location_code: '1999',
    location_name: 'SWA1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2019-10-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 73214,
    location_code: '2000',
    location_name: 'SWA2',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-09-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 85715,
    location_code: '2082',
    location_name: 'SWF1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-06-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 91046,
    location_code: '1Y75',
    location_name: 'SWF2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2023-04-30',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 91516,
    location_code: '5M06',
    location_name: 'SWI1',
    continent: 'NA',
    country: 'US SSD',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SSD',
    building_type: 'SSD',
    launch_date: '2022-01-19',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 85716,
    location_code: '1K17',
    location_name: 'SYR1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-05-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 99274,
    location_code: '0000',
    location_name: 'T-X07',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Special Handling',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 99275,
    location_code: '0000',
    location_name: 'T-X08',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Special Handling',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 99276,
    location_code: '0000',
    location_name: 'T-X09',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Special Handling',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 99277,
    location_code: '0000',
    location_name: 'T-X10',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AMXL Placeholder',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 82738,
    location_code: '0000',
    location_name: 'T202',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: '2020-06-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 82740,
    location_code: '0000',
    location_name: 'T204',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: '2020-09-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 620,
    location_code: '0000',
    location_name: 'TDXX',
    continent: 'IN',
    country: 'IN',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85717,
    location_code: '2805',
    location_name: 'TEB2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable',
    launch_date: '2021-08-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 621,
    location_code: '2419',
    location_name: 'TEB3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 84608,
    location_code: '1W23',
    location_name: 'TEB4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2021-08-08',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 622,
    location_code: '2424',
    location_name: 'TEB6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2017-10-22',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 625,
    location_code: '2079',
    location_name: 'TEB9',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2020-12-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85495,
    location_code: '1I30',
    location_name: 'TEN1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2020-09-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 85496,
    location_code: '1I31',
    location_name: 'TEN2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'IXD',
    launch_date: '2020-09-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93525,
    location_code: '7Z85',
    location_name: 'TLH2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-06-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85719,
    location_code: '1I82',
    location_name: 'TMB8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Nonsortable',
    launch_date: '2024-02-25',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 629,
    location_code: '0000',
    location_name: 'TNSX',
    continent: 'IN',
    country: 'IN',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 630,
    location_code: '1420',
    location_name: 'TPA1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2014-09-17',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 631,
    location_code: '1446',
    location_name: 'TPA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort-AR',
    building_type: 'AR Nonsortable',
    launch_date: '2014-08-20',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 84614,
    location_code: '1T83',
    location_name: 'TPA3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2020-09-13',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'NS',
  },
  {
    site_sk: 84615,
    location_code: '1V60',
    location_name: 'TPA4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2021-11-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 82739,
    location_code: '1W31',
    location_name: 'TPA6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-08-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 633,
    location_code: '0000',
    location_name: 'TRA0',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 85428,
    location_code: '0000',
    location_name: 'TSL1',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: '2020-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85429,
    location_code: '0000',
    location_name: 'TSL2',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: '2020-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85430,
    location_code: '0000',
    location_name: 'TSL3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: '2020-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85431,
    location_code: '0000',
    location_name: 'TSL4',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: '2020-10-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 635,
    location_code: '0000',
    location_name: 'TSSX',
    continent: 'IN',
    country: 'IN',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: 'Hedge',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'TS-Softlines',
  },
  {
    site_sk: 85487,
    location_code: '1I81',
    location_name: 'TTN2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'Special Handling',
    launch_date: '2020-09-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85721,
    location_code: '0000',
    location_name: 'TTN3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Mixed',
    building_type: 'IXD',
    launch_date: '2021-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91762,
    location_code: '1Y04',
    location_name: 'TTN6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'NonSort',
    building_type: 'Nonsortable',
    launch_date: '2022-07-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 636,
    location_code: '1050',
    location_name: 'TUL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort',
    building_type: 'Sortable',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 637,
    location_code: '2069',
    location_name: 'TUL2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2020-08-02',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 638,
    location_code: '2522',
    location_name: 'TUS1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'RL',
    building_type: 'Reverse Logistics',
    launch_date: '2017-11-26',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'RL',
  },
  {
    site_sk: 639,
    location_code: '2064',
    location_name: 'TUS2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2019-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 102121,
    location_code: '5T13',
    location_name: 'TUS3',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'MOD',
    launch_date: '2024-04-07',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'MOD',
  },
  {
    site_sk: 85626,
    location_code: '0000',
    location_name: 'TX04',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85627,
    location_code: '0000',
    location_name: 'TX05',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85628,
    location_code: '0000',
    location_name: 'TX06',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Temp Location',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85755,
    location_code: '1Y86',
    location_name: 'TYS1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2023-06-18',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 91111,
    location_code: '3G82',
    location_name: 'TYS5',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Supplemental',
    launch_date: '2023-04-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SDC',
  },
  {
    site_sk: 653,
    location_code: '1954',
    location_name: 'UFL4',
    continent: 'NA',
    country: 'US PrimeNow',
    sub_group: 'PrimeNow',
    sub_group_type: 'PrimeNow',
    building_type: 'SSD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'SSD',
  },
  {
    site_sk: 85665,
    location_code: '0000',
    location_name: 'US Affil',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Support',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85666,
    location_code: '0000',
    location_name: 'US Data',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Support',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 672,
    location_code: '0000',
    location_name: 'US1X',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Support',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73705,
    location_code: '4338',
    location_name: 'VESG',
    continent: 'EU',
    country: 'DE',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73728,
    location_code: '1212',
    location_name: 'VEUA',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85667,
    location_code: '0000',
    location_name: 'VFLEX-US',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85722,
    location_code: '1Y92',
    location_name: 'VGT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'Sort-AR',
    building_type: 'AR Sortable',
    launch_date: '2022-03-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AR',
  },
  {
    site_sk: 85639,
    location_code: '1Y59',
    location_name: 'VGT2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2023-04-16',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'IXD',
  },
  {
    site_sk: 85668,
    location_code: '0000',
    location_name: 'VIR_US',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Support',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73872,
    location_code: '1260',
    location_name: 'VUAA',
    continent: 'N',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'unknown',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 693,
    location_code: '1C29',
    location_name: 'VUAB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92100,
    location_code: '3A10',
    location_name: 'VUAC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91746,
    location_code: '3S47',
    location_name: 'VUAE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94398,
    location_code: '3S51',
    location_name: 'VUAI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92614,
    location_code: '3S55',
    location_name: 'VUAN',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95223,
    location_code: '3S56',
    location_name: 'VUAO',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93857,
    location_code: '3S57',
    location_name: 'VUAP',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91685,
    location_code: '3S58',
    location_name: 'VUAQ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91779,
    location_code: '3S59',
    location_name: 'VUAR',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95357,
    location_code: '3S60',
    location_name: 'VUAU',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98977,
    location_code: '3S62',
    location_name: 'VUAW',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98818,
    location_code: '3S64',
    location_name: 'VUAX',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 694,
    location_code: '1215',
    location_name: 'VUBA',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 695,
    location_code: '1271',
    location_name: 'VUBB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73874,
    location_code: '1282',
    location_name: 'VUBC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 696,
    location_code: '1351',
    location_name: 'VUBD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 697,
    location_code: '1370',
    location_name: 'VUBF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 698,
    location_code: '7292',
    location_name: 'VUBH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 699,
    location_code: '7001',
    location_name: 'VUBI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 700,
    location_code: '7000',
    location_name: 'VUBJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98766,
    location_code: '3S70',
    location_name: 'VUBM',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73877,
    location_code: '1M81',
    location_name: 'VUCA',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73878,
    location_code: '1M80',
    location_name: 'VUCB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73880,
    location_code: '1283',
    location_name: 'VUCD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73882,
    location_code: '1M82',
    location_name: 'VUCF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94520,
    location_code: '3A07',
    location_name: 'VUCG',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91217,
    location_code: '3S41',
    location_name: 'VUCH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91338,
    location_code: '3S44',
    location_name: 'VUCL',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 99305,
    location_code: '3S93',
    location_name: 'VUCP',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92603,
    location_code: '3A09',
    location_name: 'VUCT',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 701,
    location_code: '1431',
    location_name: 'VUGA',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 702,
    location_code: '1246',
    location_name: 'VUGC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 703,
    location_code: '1277',
    location_name: 'VUGD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 704,
    location_code: '1287',
    location_name: 'VUGE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 705,
    location_code: '1356',
    location_name: 'VUGF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 706,
    location_code: '1397',
    location_name: 'VUGJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 707,
    location_code: '7352',
    location_name: 'VUGK',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 708,
    location_code: '7353',
    location_name: 'VUGL',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 709,
    location_code: '7370',
    location_name: 'VUGM',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 710,
    location_code: '8289',
    location_name: 'VUGO',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73898,
    location_code: '1297',
    location_name: 'VUGP',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73900,
    location_code: '1M75',
    location_name: 'VUGR',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94291,
    location_code: '3G33',
    location_name: 'VUGT',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95240,
    location_code: '3G34',
    location_name: 'VUGU',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93963,
    location_code: '3F05',
    location_name: 'VUGY',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94941,
    location_code: '3F04',
    location_name: 'VUGZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91449,
    location_code: '2U35',
    location_name: 'VUH1',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91429,
    location_code: '2U36',
    location_name: 'VUH2',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91933,
    location_code: '2U38',
    location_name: 'VUH4',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94575,
    location_code: '2U39',
    location_name: 'VUH5',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91556,
    location_code: '2U41',
    location_name: 'VUH7',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93714,
    location_code: '2U42',
    location_name: 'VUH8',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95069,
    location_code: '2U43',
    location_name: 'VUH9',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 711,
    location_code: '1308',
    location_name: 'VUHB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 712,
    location_code: '1366',
    location_name: 'VUHD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 713,
    location_code: '7297',
    location_name: 'VUHE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 714,
    location_code: '7298',
    location_name: 'VUHF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90744,
    location_code: '2U44',
    location_name: 'VUI1',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93842,
    location_code: '2U45',
    location_name: 'VUI2',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95168,
    location_code: '2U46',
    location_name: 'VUI3',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92246,
    location_code: '2U47',
    location_name: 'VUI4',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95037,
    location_code: '2U48',
    location_name: 'VUI5',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95094,
    location_code: '2U49',
    location_name: 'VUI6',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94006,
    location_code: '2U50',
    location_name: 'VUI7',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92426,
    location_code: '2U51',
    location_name: 'VUI8',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90904,
    location_code: '3S40',
    location_name: 'VUJ2',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 93718,
    location_code: '3G53',
    location_name: 'VUJC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92105,
    location_code: '3S61',
    location_name: 'VUJE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 89899,
    location_code: '3S63',
    location_name: 'VUJH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91980,
    location_code: '3S67',
    location_name: 'VUJI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95340,
    location_code: '3S42',
    location_name: 'VUJK',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98165,
    location_code: '3S73',
    location_name: 'VUJL',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91122,
    location_code: '3S69',
    location_name: 'VUJU',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 715,
    location_code: '1290',
    location_name: 'VUKD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 716,
    location_code: '1291',
    location_name: 'VUKE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 717,
    location_code: '1292',
    location_name: 'VUKF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 718,
    location_code: '1318',
    location_name: 'VUKG',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 719,
    location_code: '1319',
    location_name: 'VUKH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 720,
    location_code: '7295',
    location_name: 'VUKI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 721,
    location_code: '7296',
    location_name: 'VUKJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 98210,
    location_code: '3S76',
    location_name: 'VULL',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 722,
    location_code: '1354',
    location_name: 'VUMC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 723,
    location_code: '7293',
    location_name: 'VUMD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 94822,
    location_code: '3V99',
    location_name: 'VUMF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 724,
    location_code: '1217',
    location_name: 'VUOA',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73913,
    location_code: '1298',
    location_name: 'VUOF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 725,
    location_code: '1358',
    location_name: 'VUOH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73914,
    location_code: '1299',
    location_name: 'VUOI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 726,
    location_code: '1359',
    location_name: 'VUOJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73915,
    location_code: '1401',
    location_name: 'VUOK',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 727,
    location_code: '1368',
    location_name: 'VUOL',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 92527,
    location_code: '3A17',
    location_name: 'VUP5',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Hazmat',
    launch_date: '2021-01-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 728,
    location_code: '1432',
    location_name: 'VUPA',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73918,
    location_code: '1228',
    location_name: 'VUPC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73919,
    location_code: '1229',
    location_name: 'VUPD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73922,
    location_code: '1284',
    location_name: 'VUPG',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73923,
    location_code: '1285',
    location_name: 'VUPH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 729,
    location_code: '1309',
    location_name: 'VUPI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 730,
    location_code: '1316',
    location_name: 'VUPJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 731,
    location_code: '1317',
    location_name: 'VUPK',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73924,
    location_code: '1357',
    location_name: 'VUPL',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 732,
    location_code: '1360',
    location_name: 'VUPM',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 733,
    location_code: '1361',
    location_name: 'VUPN',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 734,
    location_code: '1362',
    location_name: 'VUPO',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 735,
    location_code: '1364',
    location_name: 'VUPP',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 736,
    location_code: '1365',
    location_name: 'VUPQ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 737,
    location_code: '1393',
    location_name: 'VUPR',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 738,
    location_code: '1391',
    location_name: 'VUPS',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 739,
    location_code: '1396',
    location_name: 'VUPT',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 740,
    location_code: '7290',
    location_name: 'VUPU',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 741,
    location_code: '7002',
    location_name: 'VUPV',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 742,
    location_code: '7354',
    location_name: 'VUPX',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 743,
    location_code: '7355',
    location_name: 'VUPY',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 744,
    location_code: '7356',
    location_name: 'VUPZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 745,
    location_code: '7386',
    location_name: 'VUQE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73930,
    location_code: '7387',
    location_name: 'VUQF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 746,
    location_code: '7388',
    location_name: 'VUQG',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 747,
    location_code: '7389',
    location_name: 'VUQH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 748,
    location_code: '7400',
    location_name: 'VUQI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 749,
    location_code: '7338',
    location_name: 'VUQJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 750,
    location_code: '7337',
    location_name: 'VUQK',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73932,
    location_code: '1C13',
    location_name: 'VUQM',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73934,
    location_code: '1J74',
    location_name: 'VUQO',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73935,
    location_code: '1M74',
    location_name: 'VUQP',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 95230,
    location_code: '3V02',
    location_name: 'VUQQ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73936,
    location_code: '1M77',
    location_name: 'VUQR',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73937,
    location_code: '1M78',
    location_name: 'VUQS',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90623,
    location_code: '3V03',
    location_name: 'VUQX',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90281,
    location_code: '3V04',
    location_name: 'VUQZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90570,
    location_code: '3V01',
    location_name: 'VURY',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 751,
    location_code: '1208',
    location_name: 'VUSB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 752,
    location_code: '1363',
    location_name: 'VUTD',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 753,
    location_code: '7291',
    location_name: 'VUTE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73945,
    location_code: '1M73',
    location_name: 'VUTF',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73946,
    location_code: '1M76',
    location_name: 'VUTG',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73947,
    location_code: '6901',
    location_name: 'VUTH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 99586,
    location_code: '3S90',
    location_name: 'VUTI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 754,
    location_code: '7374',
    location_name: 'VUVB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 755,
    location_code: '1390',
    location_name: 'VUWA',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 756,
    location_code: '7351',
    location_name: 'VUWB',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 757,
    location_code: '7371',
    location_name: 'VUWC',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 758,
    location_code: '7373',
    location_name: 'VUWE',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 759,
    location_code: '7502',
    location_name: 'VUWH',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 73953,
    location_code: '1J75',
    location_name: 'VUWI',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 91226,
    location_code: '3D99',
    location_name: 'VUWR',
    continent: 'NA',
    country: 'US',
    sub_group: 'VendorFlex',
    sub_group_type: 'VendorFlex',
    building_type: 'Vendor Flex',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 767,
    location_code: '1411',
    location_name: 'XBC1',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85425,
    location_code: '0000',
    location_name: 'XBWI',
    continent: 'NA',
    country: 'US',
    sub_group: 'N',
    sub_group_type: 'unknown',
    building_type: 'Nonsortable IXD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 771,
    location_code: '2680',
    location_name: 'XCHA',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-BSS',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85492,
    location_code: '1497',
    location_name: 'XEW2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'IXD',
    launch_date: '2020-05-03',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'Supplemental',
  },
  {
    site_sk: 85381,
    location_code: '8050',
    location_name: 'XIX1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2019-06-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85419,
    location_code: '1166',
    location_name: 'XIX3',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2019-09-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85420,
    location_code: '1819',
    location_name: 'XIX4',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2019-10-27',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85421,
    location_code: '1566',
    location_name: 'XIX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2019-10-06',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 773,
    location_code: '2564',
    location_name: 'XIX6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2019-09-21',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85422,
    location_code: '1851',
    location_name: 'XIX7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2019-10-15',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 85424,
    location_code: '0000',
    location_name: 'XIX8',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'Nonsortable IXD',
    launch_date: '2020-04-28',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 774,
    location_code: '1194',
    location_name: 'XLAA',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-Mattress',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 775,
    location_code: '1398',
    location_name: 'XLAB',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-Mattress',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 777,
    location_code: '2050',
    location_name: 'XLAD',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-Mattress',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 778,
    location_code: '2051',
    location_name: 'XLAE',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-Mattress',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 780,
    location_code: '2053',
    location_name: 'XLAG',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-Mattress',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 783,
    location_code: '2056',
    location_name: 'XLAJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-Mattress',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 90658,
    location_code: '1Z68',
    location_name: 'XLX1',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'AMXL - IXD',
    launch_date: '2021-05-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 98076,
    location_code: '2J97',
    location_name: 'XLX2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'AMXL - IXD',
    launch_date: '2021-05-31',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 101088,
    location_code: '6J01',
    location_name: 'XLX6',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'AMXL- IXD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 101089,
    location_code: '0000',
    location_name: 'XLX7',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'IXD',
    building_type: 'AMXL- IXD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 104180,
    location_code: '0000',
    location_name: 'XLX8',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AMXL- IXD',
    launch_date: '2023-07-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 108622,
    location_code: '0000',
    location_name: 'XLX9',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'AMXL- IXD',
    launch_date: '2023-08-01',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 85423,
    location_code: '0000',
    location_name: 'XOAK',
    continent: 'N',
    country: 'N',
    sub_group: 'N',
    sub_group_type: 'N',
    building_type: 'Nonsortable IXD',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 786,
    location_code: '1272',
    location_name: 'XUSB',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 787,
    location_code: '1273',
    location_name: 'XUSC',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 788,
    location_code: '1274',
    location_name: 'XUSD',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 789,
    location_code: '1275',
    location_name: 'XUSE',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 790,
    location_code: '1276',
    location_name: 'XUSF',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 792,
    location_code: '1294',
    location_name: 'XUSH',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 793,
    location_code: '6200',
    location_name: 'XUSJ',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-SIOC',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 794,
    location_code: '6201',
    location_name: 'XUSK',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-SIOC',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 795,
    location_code: '6202',
    location_name: 'XUSM',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-SIOC',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 797,
    location_code: '1897',
    location_name: 'XUSO',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 798,
    location_code: '2059',
    location_name: 'XUSP',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 799,
    location_code: '2084',
    location_name: 'XUSR',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'FFPA',
  },
  {
    site_sk: 801,
    location_code: '2088',
    location_name: 'XUSU',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 802,
    location_code: '5700',
    location_name: 'XUSV',
    continent: 'NA',
    country: 'US',
    sub_group: 'ThirdPartyLogistics',
    sub_group_type: '3PL',
    building_type: '3PL-HB',
    launch_date: 'N',
    modified_at: '2023-04-04',
    modified_by: 'Imported from MySQL',
    ffpa_region: 'AMXL',
  },
  {
    site_sk: 90250,
    location_code: '3Q10',
    location_name: 'QXY2',
    continent: 'NA',
    country: 'US',
    sub_group: 'FulfillmentCenter',
    sub_group_type: 'SpecialHandling',
    building_type: 'AMXL- IXD',
    region: 'AMXL',
    launch_date: '2023-06-04',
    modified_at: '2022-07-24',
    modified_by: 'Import from MySQL',
  },
];

export const atsStaticLocationList = [
  {
    site_sk: 0,
    location_code: '???1',
    location_name: '3PAM',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2021-01-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '??11',
    location_name: '3PSC',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '??13',
    location_name: '3PUS',
    continent: 'NA',
    country: 'US',
    sub_group: 'USPS',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2021-11-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G62',
    location_name: 'ABQ5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 16,
    location_code: 1559,
    location_name: 'ACY5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Manual',
    building_type: '',
    region: 'Northeast',
    launch_date: '2014-11-14',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B75',
    location_name: 'ACY8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-10-21',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G63',
    location_name: 'ACY9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2021-10-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G70',
    location_name: 'AFW5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2021-09-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G64',
    location_name: 'AGS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Southeast',
    launch_date: '2021-10-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '9S81',
    location_name: 'ARB2',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2020-10-08',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 8,
    location_code: 1575,
    location_name: 'ATL6',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'Southeast',
    launch_date: '2014-07-14',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3F89',
    location_name: 'ATLX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B60',
    location_name: 'AUS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'South',
    launch_date: '2020-09-30',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 1164,
    location_name: 'AZA5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2019-11-11',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G65',
    location_name: 'AZA9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 22,
    location_code: 1581,
    location_name: 'BDL5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Northeast',
    launch_date: '2015-11-09',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3F92',
    location_name: 'BDLX',
    continent: 'NA',
    country: 'US',
    sub_group: '',
    sub_group_type: '',
    building_type: '',
    region: '3P',
    launch_date: '2021-11-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G59',
    location_name: 'BDU5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-08-25',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 7,
    location_code: 1565,
    location_name: 'BFI5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'West',
    launch_date: '2014-06-23',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B51',
    location_name: 'BFI8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2020-06-17',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 12,
    location_code: 1816,
    location_name: 'BNA5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2014-09-29',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M34',
    location_name: 'BNA7',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'South',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M09',
    location_name: 'BNA8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'HUB',
    building_type: '',
    region: 'Southeast',
    launch_date: '2021-10-31',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G08',
    location_name: 'BNA9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2020-11-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G67',
    location_name: 'BOI5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B53',
    location_name: 'BOS4',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-10-19',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 19,
    location_code: 1577,
    location_name: 'BOS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'Northeast',
    launch_date: '2014-11-24',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3F91',
    location_name: 'BOSX',
    continent: 'NA',
    country: 'US',
    sub_group: '',
    sub_group_type: '',
    building_type: '',
    region: '3P',
    launch_date: '2021-11-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G20',
    location_name: 'BTR9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2021-09-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 32,
    location_code: 1160,
    location_name: 'BUF5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Midwest',
    launch_date: '2017-09-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 11,
    location_code: 1560,
    location_name: 'BWI5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'Northeast',
    launch_date: '2014-10-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B72',
    location_name: 'CDW5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-05-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 27,
    location_code: 1051,
    location_name: 'CLE5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Midwest',
    launch_date: '2016-11-14',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G68',
    location_name: 'CLE9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'New Sites',
    launch_date: '2022-01-26',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 18,
    location_code: 1806,
    location_name: 'CLT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2014-11-19',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G09',
    location_name: 'CLT9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2020-09-21',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G25',
    location_name: 'CMH5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Midwest',
    launch_date: '2021-10-31',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '??16',
    location_name: 'CMHA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-11-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G10',
    location_name: 'CNO5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2020-08-12',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M42',
    location_name: 'CNO8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'West',
    launch_date: '2022-10-12',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G69',
    location_name: 'COS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-09-08',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 1,
    location_code: 1258,
    location_name: 'CVG5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Auto',
    building_type: '',
    region: 'Midwest',
    launch_date: '2012-08-12',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 36,
    location_code: 1177,
    location_name: 'CVG9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'Midwest',
    launch_date: '2017-10-12',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 41,
    location_code: 1166,
    location_name: 'DAL9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2018-10-10',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 24,
    location_code: 1085,
    location_name: 'DEN5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2016-06-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 4,
    location_code: 1562,
    location_name: 'DFW8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Manual',
    building_type: '',
    region: 'South',
    launch_date: '2014-03-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B57',
    location_name: 'DSM9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-08-18',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 21,
    location_code: 1818,
    location_name: 'DTW5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'Midwest',
    launch_date: '2015-10-26',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M66',
    location_name: 'DTW6',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'South',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B74',
    location_name: 'DTW8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Midwest',
    launch_date: '2021-10-28',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G72',
    location_name: 'DTW9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Midwest',
    launch_date: '2021-09-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z90',
    location_name: 'EIKZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G94',
    location_name: 'EUG5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'West',
    launch_date: '2021-10-26',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 2,
    location_code: 1558,
    location_name: 'EWR5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Auto',
    building_type: '',
    region: 'Northeast',
    launch_date: '2014-08-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 29,
    location_code: 1043,
    location_name: 'EWR8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Auto',
    building_type: '',
    region: 'Northeast',
    launch_date: '2017-08-23',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G26',
    location_name: 'FAT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'West',
    launch_date: '2021-10-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G11',
    location_name: 'FLL5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2020-10-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G79',
    location_name: 'GRR5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'New Sites',
    launch_date: '2022-02-16',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G71',
    location_name: 'HFD5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Northeast',
    launch_date: '2021-08-18',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 5,
    location_code: 1563,
    location_name: 'HOU1',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2014-04-23',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3A03',
    location_name: 'HOU5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'South',
    launch_date: '2020-10-09',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G81',
    location_name: 'IAH5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2021-11-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '??17',
    location_name: 'IAHA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M39',
    location_name: 'IGQ9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'South',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 33,
    location_code: 1161,
    location_name: 'JAX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2017-10-04',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G19',
    location_name: 'JAX9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2021-10-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z95',
    location_name: 'JAXX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '1V59',
    location_name: 'JVL6',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Midwest',
    launch_date: '2020-10-08',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 5970,
    location_name: 'KAFW',
    continent: 'NA',
    country: 'US',
    sub_group: 'Air',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '0000-00-00',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 5981,
    location_name: 'KBWI',
    continent: 'NA',
    country: 'US',
    sub_group: 'Air',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Air',
    launch_date: '2020-04-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '1H13',
    location_name: 'KLAL1',
    continent: 'NA',
    country: 'US',
    sub_group: 'Air',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2020-06-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 5998,
    location_name: 'KRFD',
    continent: 'NA',
    country: 'US',
    sub_group: 'Air',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Central',
    launch_date: '2020-04-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 5960,
    location_name: 'KRIV',
    continent: 'NA',
    country: 'US',
    sub_group: 'Air',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Air',
    launch_date: '0000-00-00',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 8039,
    location_name: 'KSBD',
    continent: 'NA',
    country: 'US',
    sub_group: 'Air',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Air',
    launch_date: '2020-09-28',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 28,
    location_code: 1162,
    location_name: 'LAS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Mountain',
    launch_date: '2017-08-23',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 37,
    location_code: 1156,
    location_name: 'LAX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'West',
    launch_date: '2017-10-18',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 8200,
    location_name: 'LAXA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2020-08-18',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M62',
    location_name: 'LAXX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-14',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G84',
    location_name: 'LBB5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'South',
    launch_date: '2021-09-02',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G30',
    location_name: 'LDJ5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-11-04',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B50',
    location_name: 'LGA5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2021-02-10',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 1851,
    location_name: 'LGB5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'West',
    launch_date: '2019-10-16',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M48',
    location_name: 'LOU5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'South',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z91',
    location_name: 'LPRZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M56',
    location_name: 'LUKA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2021-11-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 13,
    location_code: 1814,
    location_name: 'MCI5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Manual',
    building_type: '',
    region: 'Mountain',
    launch_date: '2014-10-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M38',
    location_name: 'MCI9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'Mountain',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '9S79',
    location_name: 'MCIA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '0000-00-00',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 10,
    location_code: 1564,
    location_name: 'MCO5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'South',
    launch_date: '2014-09-08',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 1825,
    location_name: 'MCO9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2019-11-06',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G80',
    location_name: 'MDT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 38,
    location_code: 1157,
    location_name: 'MDW5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: 'Midwest',
    launch_date: '2017-10-25',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3F95',
    location_name: 'MDWZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 42,
    location_code: 1819,
    location_name: 'MEM5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2018-10-24',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 44,
    location_code: 1169,
    location_name: 'MGE5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2018-10-31',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B54',
    location_name: 'MGE8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2020-08-26',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G27',
    location_name: 'MGE9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Southeast',
    launch_date: '2021-10-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3F99',
    location_name: 'MGEZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G23',
    location_name: 'MIA2',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2022-02-23',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 15,
    location_code: 1813,
    location_name: 'MIA5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2014-10-31',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M68',
    location_name: 'MIAY',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-16',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3F98',
    location_name: 'MIAZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 14,
    location_code: 1566,
    location_name: 'MKE5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Auto',
    building_type: '',
    region: 'Midwest',
    launch_date: '2014-10-22',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G86',
    location_name: 'MKE6',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Midwest',
    launch_date: '2021-10-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 39,
    location_code: 1159,
    location_name: 'MOB5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2017-11-10',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G18',
    location_name: 'MQJ5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Midwest',
    launch_date: '2021-10-28',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 'XYZ3',
    location_name: 'MQJ9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'HUB',
    building_type: '',
    region: 'New Sites',
    launch_date: '2022-08-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M78',
    location_name: 'MQJB',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-11-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 20,
    location_code: 1817,
    location_name: 'MSP5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Mountain',
    launch_date: '2015-08-10',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G87',
    location_name: 'MSP7',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M51',
    location_name: 'MSP8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'Mountain',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 43,
    location_code: 1805,
    location_name: 'MSP9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2018-10-31',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G12',
    location_name: 'MTN2',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-10-22',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 1823,
    location_name: 'MTN5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Northeast',
    launch_date: '2019-04-24',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G88',
    location_name: 'MTN6',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2021-10-06',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G89',
    location_name: 'MTN8',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2023-02-22',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B56',
    location_name: 'MTN9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-10-21',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '0000',
    location_name: 'Non-',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'unknown',
    building_type: '',
    region: 'None',
    launch_date: '0000-00-00',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 9,
    location_code: 1576,
    location_name: 'OAK5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Auto',
    building_type: '',
    region: 'West',
    launch_date: '2014-09-03',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 35,
    location_code: 1163,
    location_name: 'OKC5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Mountain',
    launch_date: '2017-10-11',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B59',
    location_name: 'ONT1',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2020-09-30',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 3,
    location_code: 1557,
    location_name: 'ONT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Large Auto',
    building_type: '',
    region: 'West',
    launch_date: '2014-08-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3A02',
    location_name: 'ORD9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Midwest',
    launch_date: '2020-07-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G17',
    location_name: 'ORH5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'New Sites',
    launch_date: '2022-07-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z99',
    location_name: 'ORHZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G13',
    location_name: 'OWD5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-10-26',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G36',
    location_name: 'OWD9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Northeast',
    launch_date: '2020-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 25,
    location_code: 2212,
    location_name: 'PDX5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'West',
    launch_date: '2016-11-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G14',
    location_name: 'PHLB',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z92',
    location_name: 'PHXZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 17,
    location_code: 1815,
    location_name: 'PIT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Midwest',
    launch_date: '2014-11-12',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G22',
    location_name: 'PIT9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Midwest',
    launch_date: '2021-09-08',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z88',
    location_name: 'PITZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 40,
    location_code: 8050,
    location_name: 'PNE5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Northeast',
    launch_date: '2018-09-26',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '??21',
    location_name: 'PTKA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z97',
    location_name: 'PTKZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G15',
    location_name: 'RBD5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'South',
    launch_date: '2022-05-18',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 26,
    location_code: 1087,
    location_name: 'RDU5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2016-11-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G95',
    location_name: 'RDU9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Southeast',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G90',
    location_name: 'RDUX',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M61',
    location_name: 'RDUY',
    continent: 'NA',
    country: 'US',
    sub_group: '',
    sub_group_type: '',
    building_type: '',
    region: '3P',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3F97',
    location_name: 'RDUZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-14',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 31,
    location_code: 1165,
    location_name: 'RIC5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Southeast',
    launch_date: '2017-09-13',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M30',
    location_name: 'RNT9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'West',
    launch_date: '2021-10-20',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G75',
    location_name: 'RSW5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '??23',
    location_name: 'RSWA',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G21',
    location_name: 'SAN5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'West',
    launch_date: '2022-05-04',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 23,
    location_code: 1822,
    location_name: 'SAT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'South',
    launch_date: '2015-11-16',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G97',
    location_name: 'SAT9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'South',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M10',
    location_name: 'SBD5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'HUB',
    building_type: '',
    region: 'West',
    launch_date: '2021-09-29',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G05',
    location_name: 'SCK9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2020-09-02',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3C99',
    location_name: 'SLC9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Mountain',
    launch_date: '2020-10-08',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 34,
    location_code: 1158,
    location_name: 'SMF5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Mix Auto',
    building_type: '',
    region: 'West',
    launch_date: '2017-10-04',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 30,
    location_code: 1086,
    location_name: 'STL5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'Mountain',
    launch_date: '2017-09-06',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G99',
    location_name: 'STL9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-07-21',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B73',
    location_name: 'TCY5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2020-11-02',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G60',
    location_name: 'TCY9',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'West',
    launch_date: '2021-10-06',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: 7329,
    location_name: 'TOR5',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'Auto',
    building_type: '',
    region: '3P',
    launch_date: '2018-12-02',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M03',
    location_name: 'TPA5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'New Sites',
    launch_date: '2022-05-05',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M64',
    location_name: 'TPAY',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '2Z98',
    location_name: 'TPAZ',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: '3P',
    launch_date: '2020-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M04',
    location_name: 'TUL5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M05',
    location_name: 'TUS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-09-01',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G82',
    location_name: 'TYS5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'New Sites',
    launch_date: '2022-05-05',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G83',
    location_name: 'VGT5',
    continent: 'NA',
    country: 'US',
    sub_group: 'Sort Center',
    sub_group_type: 'AR',
    building_type: '',
    region: 'Mountain',
    launch_date: '2021-10-27',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M02',
    location_name: 'YHM5',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2021-08-16',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: '',
    location_code: '3M43',
    location_name: 'YHM6',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: '',
    building_type: '',
    region: 'WEST',
    launch_date: '',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M08',
    location_name: 'YHM9',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2021-11-11',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B76',
    location_name: 'YUL5',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'West',
    launch_date: '2020-10-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3G28',
    location_name: 'YUL9',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2021-10-31',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3M07',
    location_name: 'YVR7',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'OXD',
    building_type: '',
    region: 'West',
    launch_date: '2021-09-15',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '1U12',
    location_name: 'YVR8',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: '3P',
    launch_date: '2019-11-14',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
  {
    site_sk: 0,
    location_code: '3B58',
    location_name: 'YYC5',
    continent: 'NA',
    country: 'CA',
    sub_group: 'Sort Center',
    sub_group_type: 'Manual',
    building_type: '',
    region: 'West',
    launch_date: '2020-09-16',
    modified_at: '2022-04-07',
    modified_by: 'Import from MySQL',
  },
];

export const generateStaticSiteList = () =>
  uniq(STATIC_FILTER_LIST.map((item) => item.location_name)).sort((a, b) => (a > b ? 1 : -1));
