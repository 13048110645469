import { FetchDataRequest, SourceLocator } from '@amzn/fox-den-cost-planning-lambda';
import { flatten } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications, RequestVariables } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { convertCsvToJsonObject, downloadS3Data, parseS3UriToLocator } from 'src/utils/s3';

interface IUsePlanDatasetSettings {
  disabled?: boolean;
  cacheTime?: number;
  staleTime?: number;
}

interface IUsePlanDatasetResultData {
  dataset: Record<string, string>[];
  metadata: {
    locations?: string[];
    partitionS3VersionMap?: Record<string, string>;
    partitionLocator?: SourceLocator;
  };
}

export const usePlanDataset = (
  input: RequestVariables<FetchDataRequest>,
  columns: string[] | null = null,
  settings: IUsePlanDatasetSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.PLAN_DATASET, input.batchId, input.datasetName, input, alias],
    queryFn: async () => {
      const planDatasetInfo = await FoxPlanningApi.fetchData({
        ...input,
        batchVersion: '0',
        requesterIdentity: alias,
      });

      const { locations, partitionS3VersionMap, partitionLocator } = planDatasetInfo;

      const gridDataChunks = await Promise.all(
        (locations || []).map(async (location) => {
          const locator = parseS3UriToLocator(location);
          const dataChunk = await downloadS3Data(locator);
          const parsedDataChunk = convertCsvToJsonObject(dataChunk, columns);

          return parsedDataChunk;
        }),
      );

      const gridData = flatten(gridDataChunks);

      const result: IUsePlanDatasetResultData = {
        dataset: gridData,
        metadata: {
          locations: locations ?? undefined,
          partitionS3VersionMap: partitionS3VersionMap ?? undefined,
          partitionLocator: partitionLocator ?? undefined,
        },
      };

      return result;
    },
    meta: { errorNotificationText: t('api_error_fetch_plan_dataset') },
    enabled: !settings.disabled,
    cacheTime: settings.cacheTime,
    staleTime: settings.staleTime,
  });

  return query;
};
