import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, Select } from '@amzn/awsui-components-react/polaris';
import { cloneDeep, isEqual } from 'lodash';

export interface IFilterInfo {
  name: string;
  label: string;
  options: string[];
  width?: string;
}

export type IFilterList = IFilterInfo[];

export const useFilterComponent = (requiredFilters: IFilterList) => {
  const { t } = useTranslation();
  const [filterState, setFilterState] = useState<Record<string, string>>({});

  useEffect(() => {
    const newFilterState = cloneDeep(filterState);
    for (let i = 0; i < requiredFilters.length; i++) {
      if (!filterState[requiredFilters[i].name]) {
        newFilterState[requiredFilters[i].name] =
          requiredFilters[i].options.find((value) => value !== 'ALL') ?? '';
      }
    }

    if (!isEqual(filterState, newFilterState)) {
      setFilterState(newFilterState);
    }
  }, [filterState, requiredFilters]);

  const FilterComponent = requiredFilters.length ? (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {requiredFilters.map((filter) => {
        const filterOptions = filter.options.map((option) => ({ label: option, value: option }));
        const filterValue = filterState[filter.name];
        const selectedFilterOption =
          filterOptions.find((item) => item.value === filterValue) ?? null;
        return (
          <div style={{ flex: 1, display: 'inline-block', padding: '4px' }} key={filter.label}>
            <FormField label={t(filter.label)} stretch={true}>
              <Select
                options={filterOptions}
                selectedOption={selectedFilterOption}
                onChange={(e) => {
                  setFilterState((priorFilterState) => {
                    const newFilterState = cloneDeep(priorFilterState);
                    newFilterState[filter.name] = e.detail.selectedOption.value ?? '';
                    return newFilterState;
                  });
                }}
                filteringType="auto"
              />
            </FormField>
          </div>
        );
      })}
    </div>
  ) : null;

  const filtersValid = requiredFilters.every((filter) => {
    const filterValue = filterState[filter.name];
    return filter.options.find((option) => option === filterValue);
  });

  const filterMap = useMemo(() => {
    const filterMap: Record<string, string[]> = {};
    for (const [key, value] of Object.entries(filterState)) {
      filterMap[key] = [value];
    }

    return filterMap;
  }, [filterState]);

  return { filterState, FilterComponent, filtersValid, filterMap };
};
