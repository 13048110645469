import {
  Container,
  Header,
  Box,
  ColumnLayout,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PlanDetailsMetadataFields,
  useGeneralPlanDetailsDisplaySettings,
} from 'src/pages/plan-manager-page/plan-details-tab/components/generalPlanDetailsConfig';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';

const NUMBER_OF_COLUMNS = 4;

interface IPlanDetailsComponentItem {
  field: PlanDetailsMetadataFields;
  fieldLabel: string;
  content: JSX.Element;
}

interface IGeneralPlanDetailsProps {
  batchMetadata: IBatchMetadataParsed | undefined;
}

const GeneralPlanDetails = ({ batchMetadata }: IGeneralPlanDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  const { generalPlanDetailsDisplaySettings } = useGeneralPlanDetailsDisplaySettings();

  const displayFields = useMemo(
    () =>
      generalPlanDetailsDisplaySettings
        .filter((planDetailItem) => {
          if (planDetailItem.shouldHide?.(batchMetadata)) {
            return false;
          }
          return true;
        })
        .map((planDetailItem) => ({
          field: planDetailItem.field,
          fieldLabel: planDetailItem.fieldLabel,
          content: planDetailItem.contentDisplay(batchMetadata),
        }))
        .reduce((planDetailColumns, planDetailItem, currentIdx) => {
          if (currentIdx < NUMBER_OF_COLUMNS) {
            planDetailColumns.push([]);
          }
          planDetailColumns[currentIdx % NUMBER_OF_COLUMNS].push(planDetailItem);
          return planDetailColumns;
        }, new Array<IPlanDetailsComponentItem[]>()),
    [batchMetadata, generalPlanDetailsDisplaySettings],
  );

  return (
    <Container header={<Header variant="h2">{t('plan_details_header')}</Header>}>
      <ColumnLayout columns={NUMBER_OF_COLUMNS} variant="text-grid">
        {displayFields.map((planDetailColumn, idx) => (
          <SpaceBetween size="l" key={idx}>
            {planDetailColumn.map((planDetailItem) => (
              <div key={planDetailItem.field}>
                <Box variant="awsui-key-label" margin={{ vertical: 'xxxs' }}>
                  {planDetailItem.fieldLabel}
                </Box>
                {planDetailItem.content}
              </div>
            ))}
          </SpaceBetween>
        ))}
      </ColumnLayout>
    </Container>
  );
};

export default GeneralPlanDetails;
