/* eslint-disable jsx-a11y/anchor-is-valid */
import { ProcessHeaderForExportParams } from 'ag-grid-community';
import { Popover } from 'devextreme-react/popover';
import { List } from 'devextreme-react/list';

import { find, get } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IValidationResult } from 'src/common/ag-grid/editable-datagrid/AgGridValidators';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';

export const BlockingTabEditors = ['AgTreeMultiSelect', 'AgTreeSelect'];

export const getColumns = (batchMetadata: any, datasetName: any) => {
  try {
    const { datasetLocations, datasetSchemaConfig } = batchMetadata;
    // retrieve the schemaName
    const datasetLocation = find(datasetLocations, { datasetName });
    const schemaName = get(datasetLocation, 'schemaName');
    // retrieve columns from dataset schema config
    let schemaConfig = null;
    if (schemaName === 'Compute') {
      schemaConfig = get(datasetSchemaConfig, 'computeDataSchema')
        ? get(datasetSchemaConfig, 'computeDataSchema')
        : find(get(datasetSchemaConfig, 'inputDataSchema'), { schemaName });
    } else {
      schemaConfig = find(get(datasetSchemaConfig, 'inputDataSchema'), { schemaName });
    }

    return schemaConfig?.columnList
      ? schemaConfig.columnList.map((columnObject: any) => columnObject.dimensionName)
      : [];
  } catch (_: any) {
    return [];
  }
};

export const syncValidator = (
  params: any,
  transformedValidateFns: any[],
  onSuccess: any,
  onFail: any,
) => {
  let result = true;
  let message = null;
  transformedValidateFns.forEach((fn) => {
    if (!result) return;
    const validationResult: IValidationResult = fn(params.newValue);
    result = validationResult.result;
    message = validationResult.message;
  });

  if (result) {
    onSuccess();
  } else {
    onFail(message);
  }
  return true;
};

/**
 * This hook makes the useEffect func to run after 0s.
 * This is to make sure other async events go first.
 */
export const useDelayedEffect = (func: any, deps: any) => {
  useEffect(() => {
    setTimeout(func, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const rowNodeDataValidationCheck = (rowNodeData: any) =>
  Object.values(rowNodeData).filter((cellValue: any) =>
    typeof cellValue === 'object' && !Array.isArray(cellValue)
      ? cellValue['lastValidation'] !== true
      : false,
  ).length === 0;

export const sizeToFit = (gridApi: any) => {
  gridApi.sizeColumnsToFit();
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CostPlanningExportParams_Ag = {
  processHeaderCallback: (params: ProcessHeaderForExportParams) =>
    params?.column?.getColDef?.()?.headerName!.replace('*', '').trim(),
};

export enum LastModifiedEnum {
  MODIFIED_AT = 'modifiedAt',
  MODIFIED_BY = 'modifiedBy',
  BUILDING_TYPE = 'Building Type',
  COUNTRY = 'Country',
  REGION = 'Region',
  SUB_GROUP = 'Sub Group',
  SUB_GROUP_TYPE = 'Sub Group Type',
  PROCESS_NAME = 'Process Name',
  LOCATION_VALUE = 'Location Value',
  LOCATION_CODE = 'Location Code',
  LOCATION_NAME = 'Location Name',
}

export const pageLevelSelectAll = (gridApi: any) => {
  const rowCount = gridApi.getDisplayedRowCount();
  const lastGridDataIndex = rowCount - 1;
  const currentPage = gridApi.paginationGetCurrentPage();
  const pageSize = gridApi.paginationGetPageSize();
  const startPageIndex = currentPage * pageSize;
  let endPageIndex = (currentPage + 1) * pageSize - 1;
  if (endPageIndex > lastGridDataIndex) {
    endPageIndex = lastGridDataIndex;
  }
  for (let i = startPageIndex; i <= endPageIndex; i++) {
    const rowNode = gridApi.getDisplayedRowAtIndex(i);
    rowNode.setSelected(true);
  }
};

export const SelectAllMessage = (props: any) => {
  const { rowsSelected, allRowCount, gridApi } = props;
  const [selectAllVisible, setSelectAllVisible] = useState(false);
  const uniqueIdDOM = Math.random().toString(16).slice(5);

  const conditionallyRenderSelectAllText = () => {
    if (rowsSelected.length !== allRowCount) {
      return (
        <small>
          <a
            href="javascript:void(0)"
            onClick={() => {
              gridApi.selectAll();
            }}
            className="grid-info-link awsui-text-info-link"
          >
            select all
          </a>{' '}
          or
        </small>
      );
    }
  };

  if (rowsSelected.length === 0) {
    const pageSelectAllOption = `Select rows on this page`;
    const dataSelectAllOption = `Select all ${allRowCount} rows`;
    const handleClickSelectAll = (e: any) => {
      setSelectAllVisible(false);
      switch (e['itemData']) {
        case pageSelectAllOption:
          pageLevelSelectAll(gridApi);
          break;
        default:
          gridApi.selectAll();
          break;
      }
    };

    return (
      <>
        <small>
          <a
            id={`selectAll${uniqueIdDOM}`}
            href="javascript:void(0)"
            onClick={() => {
              allRowCount !== 0 && setSelectAllVisible(true);
            }}
            className="grid-info-link awsui-text-info-link"
          >
            Click
          </a>{' '}
          to select all rows.
        </small>
        <Popover
          position={{ my: 'right top', at: 'right bottom', of: `#selectAll${uniqueIdDOM}` }}
          visible={selectAllVisible}
          onHiding={() => {
            setSelectAllVisible(false);
          }}
          closeOnOutsideClick={true}
          container="#Container"
          width="10vw"
          minWidth="260"
        >
          <List
            dataSource={[pageSelectAllOption, dataSelectAllOption]}
            onItemClick={handleClickSelectAll}
          />
        </Popover>
      </>
    );
  }
  return (
    <>
      <small>
        <small>
          Selected {rowsSelected.length} of {allRowCount} rows. Click to
        </small>
        {conditionallyRenderSelectAllText()}
        <a
          href="javascript:void(0)"
          onClick={() => {
            gridApi.deselectAll();
          }}
          className="grid-info-link awsui-text-info-link"
        >
          deselect
        </a>{' '}
        all rows.
      </small>
    </>
  );
};

export const addBatchMetadataToIdMap = (
  metadata: IBatchMetadataParsed,
  setBatchMetadataIdMap: Dispatch<SetStateAction<Record<string, IBatchMetadataParsed>>>,
  useCached = true,
) => {
  setBatchMetadataIdMap((batchMetadataIdMap: Record<string, IBatchMetadataParsed>) => {
    if (metadata.batchId && (!batchMetadataIdMap[metadata.batchId] || !useCached)) {
      return {
        ...batchMetadataIdMap,
        [metadata.batchId]: metadata,
      };
    }
    return batchMetadataIdMap;
  });
};
