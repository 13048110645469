export const TRANSLATIONS_DIR_NAMESPACE = 'translations';

// This route is determined by translationOutputDir in configUtils
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

export const DEFAULT_LOCALE = 'en-US';

export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';

export const SLOT_DESCRIPTION_MAX_LEN = 100;
