import {
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryKey,
} from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, PropsWithChildren, useMemo } from 'react';
import { MutationNotifications, QueryNotifications } from 'src/api/api-utils';
import { useNotificationContext } from 'src/hooks/useNotificationContext';

export const QUERY_STALE_TIME = 5 * 60 * 1000;
export const QUERY_GC_TIME = 15 * 60 * 1000;

interface QueryWithNotifications extends Query<unknown, unknown, unknown, QueryKey> {
  meta: Partial<QueryNotifications> | undefined;
}

interface MutationWithNotifications extends Mutation<unknown, unknown, unknown, unknown> {
  meta: Partial<MutationNotifications> | undefined;
}

const mutationNotificationCleanupMethods = new Map();

export const QueryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { addNotification, addNotificationForApiError } = useNotificationContext();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: QUERY_STALE_TIME,
            cacheTime: QUERY_GC_TIME,
            refetchOnWindowFocus: false,
            retryOnMount: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error, query: QueryWithNotifications) => {
            if (query.meta?.errorNotificationText) {
              addNotificationForApiError({ content: query.meta.errorNotificationText, error });
            }
          },
        }),
        mutationCache: new MutationCache({
          onMutate: (variables, mutation: MutationWithNotifications) => {
            if (mutation.meta?.inProgressNotificationText) {
              const cleanup = addNotification({
                type: 'in-progress',
                content: mutation.meta.inProgressNotificationText,
              });
              mutationNotificationCleanupMethods.set(mutation.mutationId, cleanup);
            }
          },
          onSuccess: (data, variables, context, mutation: MutationWithNotifications) => {
            if (mutation.meta?.successNotificationText) {
              addNotification({ type: 'success', content: mutation.meta.successNotificationText });
            }
          },
          onError: (error, variables, context, mutation: MutationWithNotifications) => {
            if (mutation.meta?.errorNotificationText) {
              addNotificationForApiError({
                content: mutation.meta.errorNotificationText,
                error,
              });
            }
          },
          onSettled: (data, error, variables, context, mutation: MutationWithNotifications) => {
            mutationNotificationCleanupMethods.get(mutation.mutationId)?.();
            mutationNotificationCleanupMethods.delete(mutation.mutationId);
          },
        }),
      }),
    [addNotification, addNotificationForApiError],
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
