import { TFunction } from 'i18next';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';

interface StructuredErrorInfo {
  message: string;
  downloadLink: string | null;
}

const getStructuredErrorInfo = (errorMessage: string): StructuredErrorInfo => {
  try {
    const { errorMessageBody, errorMessageLocation } = JSON.parse(errorMessage);
    return { message: errorMessageBody ?? null, downloadLink: errorMessageLocation ?? null };
  } catch (_) {
    return { message: errorMessage, downloadLink: null };
  }
};

const getApiErrorMessage = (e: any, t: TFunction<'translation', undefined>): string => {
  const message = e.response?.data?.message || e.message || e.errorMessage || e.toString();
  switch (message) {
    case 'Network Error':
      return t('network_issue_error');
    case 'Endpoint request timed out':
      return t('request_timeout_error');
  }
  return message;
};

export const getApiErrorInfo = (
  error: any,
  t: TFunction<'translation', undefined>,
): StructuredErrorInfo => {
  const errorMessage = getApiErrorMessage(error, t);
  return getStructuredErrorInfo(errorMessage);
};

export const getDatasetErrorInfo = (
  batchMetadata: IBatchMetadataParsed | undefined,
  datasetName: string,
): StructuredErrorInfo => {
  const { datasetLocations } = batchMetadata || {};
  const { datasetNote } = datasetLocations?.find((o) => o.datasetName === datasetName) || {};
  return getStructuredErrorInfo(datasetNote);
};

/**
 * This function will download errors in a csv file
 */
export const downloadFile = (downloadLink: string): void => {
  const link = document.createElement('a');
  link.href = downloadLink;
  link.download = 'error.csv';
  link.click();
};
