import { useMemo } from 'react';
import { BreadcrumbGroup } from '@amzn/awsui-components-react/polaris';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import {
  createSearchParams,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { PathNames } from 'src/routes';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { BusinessFilterType } from 'src/utils/planning/supportedBusinessGroups';

const breadcrumbPathMappings = (
  t: TFunction<'translation', undefined>,
): Record<string, BreadcrumbGroupProps.Item[]> => ({
  [PathNames.LIST_PLANS_PAGE]: [{ text: t('plans'), href: '' }],
  [PathNames.CREATE_PLAN_PAGE]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('create_plan_header'), href: '' },
  ],
  [PathNames.PLAN_MANAGER_DETAILS_TAB]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('plan_manager_header'), href: '' },
  ],
  [PathNames.PLAN_MANAGER_PROCESS_FLOW_TAB]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('plan_manager_header'), href: '' },
  ],
  [PathNames.PLAN_MANAGER_INPUT_TAB]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('plan_manager_header'), href: '' },
  ],
  [PathNames.PLAN_MANAGER_BASELINE_TAB]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('plan_manager_header'), href: '' },
  ],
  [PathNames.PLAN_MANAGER_OUTPUT_TAB]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('plan_manager_header'), href: '' },
  ],
  [PathNames.PLAN_MANAGER_ANALYSIS_TAB]: [
    { text: t('plans'), href: PathNames.LIST_PLANS_PAGE },
    { text: t('plan_manager_header'), href: '' },
  ],
});

/**
 * This components displays series of navigational links in a hierarchical list
 */
export const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const mappings = useMemo(() => breadcrumbPathMappings(t), [t]);

  const breadcrumbRoutes = Object.keys(breadcrumbPathMappings(t)).map((pathname) => ({
    path: pathname,
  }));

  const matchedRoutes = matchRoutes(breadcrumbRoutes, location);

  const pathKey = matchedRoutes?.[0]?.route?.path ?? null;

  const handleNavigation = (event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => {
    event.preventDefault();
    if (event.detail.href === PathNames.LIST_PLANS_PAGE && batchMetadata) {
      navigate({
        pathname: PathNames.LIST_PLANS_PAGE,
        search: createSearchParams({
          [BusinessFilterType.PLAN_TYPE]: batchMetadata.costType ?? '',
          [BusinessFilterType.BUSINESS_GROUP]: batchMetadata.businessGroup ?? '',
          [BusinessFilterType.SUB_GROUP]: batchMetadata.subGroup ?? '',
          [BusinessFilterType.REGION]: batchMetadata.region ?? '',
        }).toString(),
      });
    } else {
      navigate(event.detail.href as string);
    }
  };

  const paths: BreadcrumbGroupProps.Item[] = [
    {
      text: t('title'),
      href: PathNames.LANDING_PAGE,
    },
  ];

  return (
    <BreadcrumbGroup
      items={pathKey ? paths.concat(mappings[pathKey]) : []}
      onFollow={handleNavigation}
      ariaLabel="Breadcrumbs"
    />
  );
};
