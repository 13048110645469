import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { useTranslation } from 'react-i18next';

export const TableNoMatchState = ({ onClearFilter }: { onClearFilter: () => void }) => {
  const { t } = useTranslation();
  return (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>{t('no_matches')}</b>
          <Box variant="p" color="inherit">
            {t('no_matches_details')}
          </Box>
        </div>
        <Button onClick={onClearFilter}>{t('clear_filter_button')}</Button>
      </SpaceBetween>
    </Box>
  );
};

export const TableEmptyState = ({ onClickCreatePlan }: { onClickCreatePlan: () => void }) => {
  const { t } = useTranslation();
  return (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>{t('no_plans_found')}</b>
          <Box variant="p" color="inherit">
            {t('no_plans_found_details')}
          </Box>
        </div>
        <Button variant="primary" onClick={onClickCreatePlan}>
          {t('create_plan_button')}
        </Button>
      </SpaceBetween>
    </Box>
  );
};
