import { Box } from '@amzn/awsui-components-react/polaris';
import { useParams } from 'react-router';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import DatasetUploadStatus from 'src/pages/plan-manager-page/plan-details-tab/components/DatasetUploadStatus';
import GeneralPlanDetails from 'src/pages/plan-manager-page/plan-details-tab/components/GeneralPlanDetails';
import LastComputeDetails from 'src/pages/plan-manager-page/plan-details-tab/components/LastComputeDetails';

const PlanDetailsTab = (): JSX.Element => {
  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId }, { staleTime: 0 });

  const { data: featureFlags } = useFeatureFlags();

  return (
    <div>
      <Box margin={{ top: 'm' }}>
        <GeneralPlanDetails batchMetadata={batchMetadata} />
      </Box>
      <Box margin={{ top: 'm' }}>
        <LastComputeDetails batchMetadata={batchMetadata} />
      </Box>
      {featureFlags?.feLoadDatasetByAsyncUploadAditsApi ? (
        <Box margin={{ top: 'm' }}>
          <DatasetUploadStatus batchMetadata={batchMetadata} />
        </Box>
      ) : null}
    </div>
  );
};

export default PlanDetailsTab;
