import { TreeView } from 'devextreme-react/tree-view';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { useEffect, useRef, useState } from 'react';
import './treeSelect.css';
import { isArray } from 'lodash';

export interface ISingleTreeDataSource {
  id: string;
  name: string;
  parentId?: string;
  expanded?: boolean;
}

export interface ITreeSelectModal {
  selectedValue: any;
  onChange?: any;
  disabled?: any;
  treeDataSource: ISingleTreeDataSource[] | any;
  dropDownOptions?: any;
  setSelectedValue?: any;
  customizedOnTreeBoxOpened?: any;
  searchEnabled?: any;
  isTreeBoxOpenedByDefault?: boolean;
  largeDropdown?: boolean;
}

const SingleTreeSelect = (props: ITreeSelectModal) => {
  const {
    selectedValue,
    onChange,
    disabled,
    treeDataSource,
    dropDownOptions = {},
    customizedOnTreeBoxOpened = null,
    searchEnabled = true,
    isTreeBoxOpenedByDefault = false,
    largeDropdown = false,
  } = props;
  const dropdownBoxRef = useRef(null as any);
  const treeViewRef = useRef(null as any);
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(isTreeBoxOpenedByDefault);

  const [selectedItem, setSelectedItem] = useState(selectedValue);

  const onTreeItemClick = () => {
    setIsTreeBoxOpened(false);
  };

  const onTreeBoxClosed = (_e: any) => {
    onChange && isArray(selectedItem) && onChange(selectedItem.length > 0 ? selectedItem[0] : '');
  };

  const onTreeBoxOpened = (e: any) => {
    if (e.name === 'opened') {
      setIsTreeBoxOpened(e.value);
      customizedOnTreeBoxOpened?.(e);
    }
  };

  const syncTreeViewSelection = (e: any) => {
    setSelectedItem(e.value);

    if (!treeViewRef.current) return;
    if (!e.value) {
      treeViewRef.current.instance.unselectAll();
    } else {
      treeViewRef.current.instance.selectItem(e.value);
    }
  };

  const treeViewOnContentReady = (e: any) => {
    e.component.selectItem(selectedItem);
    if (!selectedItem) {
      e.component.unselectAll();
    }
  };

  const treeViewItemSelectionChanged = (e: any) => {
    setSelectedItem(e.component.getSelectedNodeKeys());
  };

  useEffect(() => {
    setSelectedItem(selectedValue);
  }, [selectedValue]);

  const treeViewRender = () => (
    <TreeView
      ref={treeViewRef}
      dataSource={treeDataSource}
      dataStructure="plain"
      keyExpr="id"
      parentIdExpr="parentId"
      selectionMode="single"
      displayExpr="name"
      selectByClick={true}
      searchEnabled={searchEnabled}
      onContentReady={treeViewOnContentReady}
      onItemClick={onTreeItemClick}
      onItemSelectionChanged={treeViewItemSelectionChanged}
    />
  );

  return (
    <DropDownBox
      value={selectedItem}
      opened={isTreeBoxOpened}
      valueExpr="id"
      displayExpr="name"
      showClearButton={false}
      dataSource={treeDataSource}
      ref={dropdownBoxRef}
      disabled={disabled}
      onValueChanged={syncTreeViewSelection}
      onOptionChanged={onTreeBoxOpened}
      onClosed={onTreeBoxClosed}
      contentRender={treeViewRender}
      height="100%"
      dropDownOptions={{
        minWidth: largeDropdown ? '400px' : '250px',
        resizeEnabled: true,
        ...dropDownOptions,
      }} //This defines the minWidth for popup specifically
    />
  );
};

export default SingleTreeSelect;
