import { DateTime } from 'luxon';
import { QUERY_STALE_TIME } from 'src/providers/QueryProvider';

export const dateTimeFromIsoToLocal = (isoTimestamp: string) =>
  DateTime.fromISO(isoTimestamp).toFormat('yyyy-MM-dd h:mm a ZZZZ');

export const dateTimeFromEpochToLocal = (milliseconds: number) =>
  DateTime.fromMillis(milliseconds).toFormat('yyyy-MM-dd h:mm a ZZZZ');

export const currentModifiedAt = () => DateTime.now().toUTC().toFormat('yyyy-MM-dd h:mm:ss.SSS');

export const getStaleLastUpdatedAt = () => Date.now() - QUERY_STALE_TIME;

export const currentDateTime = () => DateTime.now().toFormat('yyyy-MM-dd h:mm a ZZZZ');

export const getCurrentYear = () => DateTime.now().toUTC().year;

export const getFirstSundayReportingYear = (date: string) => {
  const sundayWeekday = 7;
  const dateTime = DateTime.fromSQL(date);

  const daysToSubtract =
    dateTime.weekday >= sundayWeekday
      ? dateTime.weekday - sundayWeekday
      : 7 - sundayWeekday + dateTime.weekday;

  return dateTime.minus({ day: daysToSubtract });
};

/**
 *
 * @param dateTime
 * @param weekday (1 is Monday and 7 is Sunday)
 * @returns Same day if weekday is the same as datetime, otherwise the next day which is the weekday
 */
export const getNextWeekday = (dateTime: DateTime, weekday: number) => {
  if (!dateTime.isValid) return dateTime;

  const daysToAdd = (7 - dateTime.weekday + weekday) % 7;
  return dateTime.plus({ day: daysToAdd });
};

/**
 *
 * @param dateTime
 * @param weekday (1 is Monday and 7 is Sunday)
 * @returns Same day if weekday is the same as datetime, otherwise the first prior instance of the weekday
 */
export const getPrevWeekday = (dateTime: DateTime, weekday: number) => {
  if (!dateTime.isValid) return dateTime;

  const daysToSubtract =
    dateTime.weekday >= weekday ? dateTime.weekday - weekday : 7 - weekday + dateTime.weekday;

  return dateTime.minus({ day: daysToSubtract });
};

export const getInclusiveReportingTimeRange = (startDate: string, months: number) => {
  const startDateTime = DateTime.fromSQL(startDate);

  const firstSunday = getPrevWeekday(startDateTime, 7).toFormat('yyyy-MM-dd');

  const endDateTime = DateTime.fromSQL(startDate).plus({ months }).minus({ day: 1 });

  const lastSaturday = getNextWeekday(endDateTime, 6).toFormat('yyyy-MM-dd');

  return { startDate: firstSunday, endDate: lastSaturday };
};
