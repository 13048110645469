import { Button } from '@amzn/awsui-components-react/polaris';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { createContext, FC, PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiErrorInfo } from 'src/utils/errorMessage';

export interface ErrorModalInfo<T = any> {
  message: string;
  table?: { items: T[]; columnDefinitions: TableProps.ColumnDefinition<T>[] };
  downloadLink?: string | null;
}

type ClearNotification = () => void;

type AddNotification = (notification: {
  type: FlashbarProps.MessageDefinition['type'];
  content: string;
  action?: FlashbarProps.MessageDefinition['action'];
}) => ClearNotification;

type AddNotificationWithErrorModal<T = any> = (props: {
  content: string;
  errorModal: ErrorModalInfo<T>;
}) => void;

type AddNotificationForApiError = (props: { content: string; error: any }) => void;

export class NotificationContextType<T = any> {
  notifications: FlashbarProps.MessageDefinition[] = [];
  globalErrorModal: ErrorModalInfo<T> | null = null;
  addNotification: AddNotification = () => () => {};
  addNotificationWithErrorModal: AddNotificationWithErrorModal<T> = () => {};
  addNotificationForApiError: AddNotificationForApiError = () => {};
  clearGlobalErrorModal: () => void = () => {};
}

/**
 * React context for Notifications
 */
export const NotificationContext = createContext(new NotificationContextType());

/**
 * Wrapper to allow notifications in application
 */
export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [globalErrorModal, setGlobalErrorModal] = useState<ErrorModalInfo<any> | null>(null);

  const clearGlobalErrorModal = () => setGlobalErrorModal(null);

  const addNotification: AddNotification = useCallback((notification) => {
    const id = Math.random().toString(36).substring(2, 15);
    const clearNotification = () => setNotifications((ns) => ns.filter((n) => n.id !== id));

    setNotifications((notifications) => {
      const newNotification: FlashbarProps.MessageDefinition = {
        onDismiss: clearNotification,
        dismissible: notification.type !== 'in-progress',
        loading: notification.type === 'in-progress',
        id,
        ...notification,
      };
      return [newNotification, ...notifications];
    });

    return clearNotification;
  }, []);

  const addNotificationWithErrorModal: AddNotificationWithErrorModal = useCallback(
    ({ content, errorModal }) => {
      addNotification({
        type: 'error',
        content,
        action: <Button onClick={() => setGlobalErrorModal({ ...errorModal })}>{t('view')}</Button>,
      });
    },
    [t, addNotification],
  );

  const addNotificationForApiError: AddNotificationForApiError = useCallback(
    ({ content, error }) => {
      const errorInfo = getApiErrorInfo(error, t);

      if (errorInfo.message || errorInfo.downloadLink) {
        addNotificationWithErrorModal({
          content: content + ` ${t('click_view_for_more_details')}`,
          errorModal: errorInfo,
        });
      } else {
        addNotification({ type: 'error', content });
      }
    },
    [addNotification, addNotificationWithErrorModal, t],
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        globalErrorModal,
        addNotification,
        addNotificationWithErrorModal,
        addNotificationForApiError,
        clearGlobalErrorModal,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
