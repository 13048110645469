import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  }

  //Todo add Cloudwatch metric logging for prod
  return new KatalMetricsDriverConsoleLogJson();
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();

  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('PLANetFrontend')
    .withServiceName('PLANet')
    .build();

  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
