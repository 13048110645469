class AgCustomTooltip {
  private eGui: HTMLDivElement | undefined;

  init(params: any) {
    const eGui = (this.eGui = document.createElement('div'));
    const { field, validationFailedValue, validationFailedMsg } = params.value;

    eGui.classList.add('custom-tooltip');
    eGui.innerHTML = `
          <div class="validation-msg"> 
        <b> ${field} - invalid value detected </b>
        <br>
<!--          "<span class="invalid-value"> ${validationFailedValue} </span>", -->
        ${validationFailedMsg}
      </div> 
      `;
  }

  getGui() {
    return this.eGui;
  }
}

export default AgCustomTooltip;
