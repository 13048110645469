import Foxdencostplanninglambda from '@amzn/fox-den-cost-planning-lambda';
import { Metadata, Operation } from '@amzn/fox-den-cost-planning-lambda/structures';
import { Api } from './Api';

const API = new Api('FoxCostPlanningLambdaEndpoint');

const operationExecutor = (
  metadata: Metadata,
  operation: Operation,
  request?: any,
): Promise<any> => {
  if (operation.http.method === 'POST') {
    return API.post(operation.http.requestUri, request);
  }
  if (operation.http.method === 'PUT') {
    return API.put(operation.http.requestUri, request);
  }

  throw new Error(`Unsupported operation ${operation.http.method}`);
};

export const FoxPlanningApi = new Foxdencostplanninglambda(operationExecutor);
