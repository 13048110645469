import { Box, Button, FormField, Icon, TextContent } from '@amzn/awsui-components-react/polaris';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RequiredLabel } from 'src/common/RequiredLabel';
import { FileChangeEvent } from 'src/common/EventType';
import { dateTimeFromEpochToLocal } from 'src/utils/time';

/**
 * Props for FileInput component
 */
interface FileInputProps {
  disabled?: boolean;
  errorText?: string;
  file?: File | null;
  files?: File[];
  form: { label: string; constraint: string; accept: string; description: string };
  loading?: boolean;
  multiple?: boolean;
  onChange(file: File | File[] | null): void;
}

/**
 * This component will render the File Input
 */
export const FileInput = ({
  disabled,
  errorText,
  form,
  file,
  files,
  multiple = false,
  onChange,
  loading = false,
}: FileInputProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * This function will update selected file
   */
  const handleFileChange = ({ target }: FileChangeEvent) => {
    onChange(target.files?.length ? (multiple ? Array.from(target.files) : target.files[0]) : null);
  };

  /**
   * This function will reset the file input
   */
  const handleResetFileInput = () => {
    fileInputRef.current?.click();
  };

  /**
   * This function will remove selected file.
   * @param index Index to be deleted
   */
  const handleFileDelete = (index: number) => () => {
    if (!multiple || !files) {
      return;
    }
    const fileList = Array.from(files);
    fileList.splice(index, 1);
    onChange(fileList);
  };

  return (
    <>
      <FormField
        label={<RequiredLabel text={form.label} />}
        description={form.description}
        constraintText={form.constraint}
        errorText={errorText}
      >
        <Button
          disabled={disabled}
          formAction="none"
          loading={loading}
          onClick={handleResetFileInput}
        >
          <Icon name="upload" />
          {'  '}
          {t('choose_file')}
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          multiple={multiple}
          accept={form.accept}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </FormField>
      {file?.name && !multiple && (
        <Box margin={{ top: 's' }}>
          <TextContent>
            <p>
              <strong>{t('selected_file')}</strong>
              {file.name}
            </p>
            <p>
              <strong>{t('file_size')}</strong>
              {file.size}
            </p>
            <p>
              <strong>{t('last_modified')}</strong>
              {dateTimeFromEpochToLocal(file.lastModified)}
            </p>
          </TextContent>
        </Box>
      )}
      {multiple &&
        files &&
        files.map((file, index) => (
          <div key={`file_${index}`}>
            <Button
              variant="link"
              iconName="close"
              iconAlign="right"
              onClick={handleFileDelete(index)}
            >
              {file.name}
            </Button>
          </div>
        ))}
    </>
  );
};
